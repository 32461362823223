import React from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import styles from '../styles';
import { dimensions as widgetDimensions } from '../../widget';
import { Widget } from '../..';

const ChartError = () => {
    const classes = styles();
    return (
        <Widget {...widgetDimensions.huge}>
            <div className={classes.errorMessage}>
                <FormattedMessage id="components.charts.nodata"></FormattedMessage>
                <div className={classes.clear} />
            </div>
        </Widget>
    );
};

ChartError.propTypes = {
    classes: propTypes.object.isRequired,
};

export default ChartError;
