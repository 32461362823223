import heatpumpImage from './images/waermepumpe.jpg';

export default {
    baseData: {
        location: [
            {
                title: 'asset.tab.baseData.street',
                property: 'street',
            },
            {
                title: 'asset.tab.baseData.postalCodeCity',
                property: 'postalCodeCity',
            },
            {
                title: 'asset.tab.baseData.installationPlace',
                property: 'installationPlace',
            },
            {
                title: 'asset.tab.baseData.longitude',
                property: 'longitude',
            },
            {
                title: 'asset.tab.baseData.latitude',
                property: 'latitude',
            },
        ],
        facilityInfo: [
            {
                property: 'monitoringSystem',
                title: 'asset.tab.baseData.monitoring',
            },
            {
                property: 'installationDate',
                title: 'asset.tab.baseData.installationDate',
            },
            {
                property: 'cop',
                title: 'asset.tab.overview.heatPump.cop',
            },
            {
                property: 'unitType',
                title: 'asset.tab.overview.heatPump.unitType',
            },
        ],
    },
    overview: {
        media: {
            url: heatpumpImage,
            title: 'Wärmepumpe',
        },
        info: [
            {
                property: 'deviceType',
                title: 'asset.tab.overview.type',
                valueId: 'asset.tab.overview.heatPump.type',
            },
            {
                property: 'maxPowerKW',
                title: 'asset.tab.overview.heatPump.maxPowerKW',
                unit: 'kW',
            },
            {
                property: 'heatingPowerMaxKW',
                title: 'asset.tab.overview.heatPump.heatingPowerMaxKw',
                unit: 'kW',
            },
            {
                property: 'manufacturer',
                title: 'asset.tab.overview.manufacturer',
            },
            {
                property: 'manufacturerDescriptionType',
                title: 'asset.tab.overview.heatPump.model',
            },
        ],
    },
    performance: [{
        chartSettings: {
            yaxis: [
                {
                    type: 'numeric',
                    title: {
                        text: 'kW',
                    },
                    decimalsInFloat: 0,
                    forceNiceScale: true,
                },
                {
                    type: 'numeric',
                    decimalsInFloat: 0,
                    forceNiceScale: true,
                    opposite: true,
                    title: {
                        text: 'kW',
                    },
                },
            ],
        },
        plots: [
            {
                id: 'heatPumpPower',
                title: 'asset.tab.performanceData.consumed_power.title',
                color: '#79E6F1',
                type: 'line',
            },
            {
                id: 'heatPumpThermal',
                title: 'asset.tab.performanceData.thermal.title',
                color: '#F25C78',
                type: 'line',
            },
        ],
    },]
};
