import React from 'react';
import propTypes from 'prop-types';
import { WhitelabelTable } from '../../../../components';

const AssetTypesList = ({ assets }) => {
    const types = [
        {
            name: 'PV',
        },
        {
            name: 'CHARGING_POINT',
        },
        {
            name: 'HEAT_PUMP',
        },
        {
            name: 'BHKW',
        },
        {
            name: 'SENSOR',
        },
        {
            name: 'WIND_TURBINE',
        },
    ];

    const filterAssetsByType = type => {
        return assets.filter(asset => asset.type === type);
    };

    const getFilteredAssetList = () => {
        const filteredAssetList = [];
        types.forEach(type => {
            filterAssetsByType(type.name).forEach(asset => {
                filteredAssetList.push(asset);
            });
        });
        return filteredAssetList;
    };

    return (
        <WhitelabelTable data={getFilteredAssetList()} type="assetsOverview" />
    );
};

AssetTypesList.propTypes = {
    assets: propTypes.array,
};

export default AssetTypesList;
