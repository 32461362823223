export default {
    baseData: {
        location: [
            {
                title: 'asset.tab.baseData.street',
                property: 'street',
            },
            {
                title: 'asset.tab.baseData.postalCodeCity',
                property: 'postalCodeCity',
            },
            {
                title: 'asset.tab.baseData.installationPlace',
                property: 'installationPlace',
            },
            {
                title: 'asset.tab.baseData.longitude',
                property: 'longitude',
            },
            {
                title: 'asset.tab.baseData.latitude',
                property: 'latitude',
            },
        ],
        facilityInfo: [],
    },
    overview: {
        info: [
            {
                property: 'deviceType',
                title: 'asset.tab.overview.type',
            },
        ],
    },
    performance: []
};
