import React from 'react';
import { Breadcrumbs } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import useContext from '../../hooks/useContext';
import useStyles from './styles';

const BreadcrumbsComponent = ({ history, pages }) => {
    const classes = useStyles();
    const context = useContext()[0];
    const pathnames = history.location.pathname.split('/').filter(item => item);

    /**
     * return page-title depending on pathname
     * if no route and no context asset exist, return empty string
     * if the route's page-name equals the pathname, return the route title
     * if context asset exists, for instance if the client is on an asset-detail-page, return name of the asset
     * as last option, return pathname
     * @param {String} pathname
     * @returns {String}
     */
    const getTitleFromPath = pathname => {
        const route = pages.find(page => page.path.substring(1) === pathname);
        if (!route && !context.asset) return '';
        if (route && route.title) return <FormattedMessage id={route.title} />;
        if (context.asset && context.asset.name) return context.asset.name;
        return pathname;
    };

    return (
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '16px' }}>
            {pathnames.map((pathname, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                return last ? (
                    <Link
                        className={`${classes.link} ${classes.active}`}
                        key={to}
                        to={to}
                    >
                        {getTitleFromPath(pathname)}
                    </Link>
                ) : (
                    <Link className={classes.link} to={to} key={to}>
                        {getTitleFromPath(pathname)}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

BreadcrumbsComponent.propTypes = {
    history: propTypes.object.isRequired,
    pages: propTypes.array.isRequired,
};

export default withRouter(BreadcrumbsComponent);
