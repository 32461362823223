import pvConfig from './pv';
import bhkwConfig from './bhkw';
import hpConfig from './heatPump';
import chpConfig from './chargingPoint';
import sensorConfig from './sensor';
import windTurbineConfig from "./windTurbine";
import defaultConfig from './default';

export default [
    {
        type: 'PV',
        data: pvConfig,
    },
    {
        type: 'CHARGING_POINT',
        data: chpConfig,
    },
    {
        type: 'HEAT_PUMP',
        data: hpConfig,
    },
    {
        type: 'BHKW',
        data: bhkwConfig,
    },
    {
        type: 'SENSOR',
        data: sensorConfig,
    },
    {
        type: 'WIND_TURBINE',
        data: windTurbineConfig,
    },
    {
        type: 'DEFAULT',
        data: defaultConfig,
    },
];
