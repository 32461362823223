import React from 'react';
import { FormattedMessage } from 'react-intl';

import { dimensions as widgetDimensions } from '../../widget';
import { Widget } from '../..';
import useStyles from '../styles';

const TableError = () => {
    const classes = useStyles();
    return (
        <Widget {...widgetDimensions.huge}>
            <div className={classes.errorMessage}>
                <FormattedMessage id="components.table.nodata"></FormattedMessage>
                <div className={classes.clear} />
            </div>
        </Widget>
    );
};

export default TableError;
