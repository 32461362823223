export default theme => ({
    logotype: {
        color: theme.palette.headerText,
        marginLeft: theme.spacing(2.5),
        marginRight: theme.spacing(2.5),
        fontWeight: 500,
        fontSize: 18,
        whiteSpace: 'nowrap',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    appBar: {
        background: `linear-gradient(to right, ${theme.palette.headerBackground}, ${theme.palette.headerBackgroundStop})`
            || theme.palette.primary.main,
        width: '100%',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    mobileMenuButton: {
        marginRight: theme.spacing(2),
        color: theme.palette.headerText,
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 0,
        width: 56,
        [theme.breakpoints.up('md')]: {
            width: 166,
        },
    },
    logoImage: {
        height: 55,
        maxWidth: '100%',
        maxHeight: '100%',
        width: 'auto',
    },
    grow: {
        flexGrow: 1,
    },
    headerMenuButton: {
        marginLeft: theme.spacing(2),
        padding: theme.spacing(2),
    },
    headerMenuButtonCollapse: {
        marginRight: theme.spacing(2),
    },
    headerIcon: {
        fontSize: 28,
        color: 'rgba(255, 255, 255, 0.35)',
    },
    headerIconCollapse: {
        color: theme.palette.headerText,
    },
    logoutType: {
        color: theme.palette.headerText,
        marginLeft: 'auto',
    },
    textType: {
        paddingLeft: 5,
    },
});
