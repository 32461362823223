import propTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Widget, { dimensions } from '../widget';
import Map from './Map';

const WhitelabelMap = ({
    data,
    size,
    title,
    isIsochrones,
    isTrafficTiles,
    isochronesLat,
    isochronesLong,
    zoom,
}) => {
    const widgetSize = dimensions[size];
    return (
        <Widget
            title={<FormattedMessage id={title} />}
            {...widgetSize}
            titleVariant="h5"
            centeredHeader
        >
            <Map
                data={data}
                height={500}
                disablePopup
                isIsochrones={isIsochrones}
                isTrafficTiles={isTrafficTiles}
                isochronesLat={isochronesLat}
                isochronesLong={isochronesLong}
                zoom={zoom}
            />
        </Widget>
    );
};

WhitelabelMap.propTypes = {
    title: propTypes.string.isRequired,
    size: propTypes.string,
    data: propTypes.array.isRequired,
    isIsochrones: propTypes.bool,
    isochronesLat: propTypes.number,
    isochronesLong: propTypes.number,
    zoom: propTypes.number,
    isTrafficTiles: propTypes.bool,
};

export default WhitelabelMap;
