import React from 'react';
import propTypes from 'prop-types';

import ScaleBox from '../ScaleBox';

const ScaleBoxError = ({ title, unit, scaleBoxType }) => {
    return <ScaleBox title={title} value={0} unit={unit} type={scaleBoxType} />;
};

ScaleBoxError.propTypes = {
    title: propTypes.string.isRequired,
    data: propTypes.object,
    unit: propTypes.string,
    scaleBoxType: propTypes.string,
};

export default ScaleBoxError;
