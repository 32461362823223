export default [
    {
        translationKey: "sensors.airQualityBox.aqi.veryGood",
        color: "#50FFD0",
        value: 1,
    },
    {
        translationKey: "sensors.airQualityBox.aqi.good",
        color: "#A7F890",
        value: 2,
    },
    {
        translationKey: "sensors.airQualityBox.aqi.satisfying",
        color: "#FCEC6A",
        value: 3,
    },
    {
        translationKey: "sensors.airQualityBox.aqi.deficient",
        color: "#F2A772",
        value: 4,
    },
    {
        translationKey: "sensors.airQualityBox.aqi.bad",
        color: "#F0655B",
        value: 5,
    },
    {
        translationKey: "sensors.airQualityBox.aqi.veryBad",
        color: "#EC4452",
        value: 6,
    }
];
