import React from 'react';
import ApexChart from 'react-apexcharts';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { merge as _merge } from 'lodash';

import getBarChartSettings from './settings';
import styles from '../../styles';

const Bar = ({
    plots,
    settings,
    unit,
    withBrushChart,
    brushChartSettings,
    height,
}) => {
    const classes = styles();
    const intl = useIntl();
    const chartSettings = _merge(settings, getBarChartSettings(unit, plots));

    const getSeries = () => {
        return plots.map(plot => ({
            color: plot.color,
            name: intl.formatMessage({ id: plot.title }),
            type: plot.type,
            data: formatPlotData(plot.data),
        }));
    };

    const formatPlotData = plotData => {
        if (!plotData || !Array.isArray(plotData)) return [];
        return plotData.map(data => ({
            x: new Date(data.timestamp),
            y: data.value,
        }));
    };

    return (
        <>
            <ApexChart
                series={getSeries()}
                options={chartSettings}
                height={height}
                type="bar"
            />
            {withBrushChart && (
                <div className={classes.brushOuter}>
                    <ApexChart
                        series={getSeries()}
                        options={brushChartSettings}
                        height="130"
                        type="bar"
                    />
                </div>
            )}
        </>
    );
};

Bar.propTypes = {
    plots: propTypes.array,
    settings: propTypes.object,
    brushChartSettings: propTypes.object,
    unit: propTypes.string,
    height: propTypes.string,
    withBrushChart: propTypes.bool,
};
export default Bar;
