import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

export default [
    {
        type: 'consumer',
        headlines: [
            {
                value: <FormattedMessage id="consumer.table.name" />,
                key: 'name',
            },
            {
                value: <FormattedMessage id="components.table.type" />,
                key: 'type',
            },
            {
                value: (
                    <FormattedMessage id="consumer.table.consumptionToday" />
                ),
                key: 'kwhToday',
            },
            {
                value: (
                    <FormattedMessage id="consumer.table.consumptionOverall" />
                ),
                key: 'kwhOverall',
            },
            {
                value: (
                    <>
                        <FormattedMessage id="consumer.table.consumptionForecast" />{' '}
                        {moment().format('DD.MM.YYYY')}
                    </>
                ),
                key: 'kwhTodayForecast',
            },
            {
                value: (
                    <FormattedMessage id="components.table.nominalCapacity" />
                ),
                key: 'nominalCapacity',
            },
            {
                value: <FormattedMessage id="components.table.status" />,
                key: 'status',
            },
            {
                value: <FormattedMessage id="components.table.remarks" />,
                key: 'remarks',
            },
        ],
    },
    {
        type: 'producer',
        headlines: [
            {
                value: <FormattedMessage id="producer.table.name" />,
                key: 'name',
            },
            {
                value: <FormattedMessage id="components.table.type" />,
                key: 'type',
            },
            {
                value: <FormattedMessage id="producer.table.productionToday" />,
                key: 'kwhToday',
            },
            {
                value: (
                    <FormattedMessage id="producer.table.productionOverall" />
                ),
                key: 'kwhOverall',
            },
            {
                value: (
                    <>
                        <FormattedMessage id="producer.table.productionForecast" />{' '}
                        {moment().format('DD.MM.YYYY')}
                    </>
                ),
                key: 'kwhTodayForecast',
            },
            {
                value: (
                    <FormattedMessage id="components.table.nominalCapacity" />
                ),
                key: 'nominalCapacity',
            },
            {
                value: <FormattedMessage id="components.table.status" />,
                key: 'status',
            },
            {
                value: <FormattedMessage id="components.table.remarks" />,
                key: 'remarks',
            },
        ],
    },
    {
        type: 'HC',
        headlines: [
            {
                value: <FormattedMessage id="cooling.table.name" />,
                key: 'name',
            },
            {
                value: <FormattedMessage id="cooling.table.type" />,
                key: 'type',
            },
            {
                value: <FormattedMessage id="cooling.table.category" />,
                key: 'category',
            },
            {
                value: <FormattedMessage id="components.table.status" />,
                key: 'status',
            },
        ],
    },
    {
        type: 'asset',
        headlines: [
            {
                value: <FormattedMessage id="asset.tab.overview.pv.date" />,
                key: 'timestamp',
            },
            {
                value: <FormattedMessage id="asset.tab.overview.pv.level" />,
                key: 'severity',
            },
            {
                value: <FormattedMessage id="asset.tab.overview.pv.message" />,
                key: 'message',
            },
            {
                value: <FormattedMessage id="asset.tab.overview.pv.approved" />,
                key: 'acknowledged',
            },
        ],
    },
    {
        type: 'chargingStation',
        headlines: [
            {
                value: (
                    <FormattedMessage id="asset.tab.overview.pv.startTime" />
                ),
                key: 'timestamp',
            },
            {
                value: <FormattedMessage id="asset.tab.overview.pv.duration" />,
                key: 'duration',
            },
            {
                value: (
                    <FormattedMessage id="asset.tab.overview.pv.consumedEnergy" />
                ),
                key: 'consumedEnergy',
            },
            {
                value: <FormattedMessage id="asset.tab.overview.pv.price" />,
                key: 'price',
            },
        ],
    },
    {
        type: 'assetsOverview',
        headlines: [
            {
                value: <FormattedMessage id="assets.overview.name" />,
                key: 'name',
            },
            {
                value: <FormattedMessage id="asset.tab.overview.type" />,
                key: 'type',
            },
            {
                value: <FormattedMessage id="components.table.status" />,
                key: 'status',
            },
        ],
    },
];
