import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './polyfills';
import de from './translations/de';
import en from './translations/en';
import getActiveLanguage from './helper/getActiveLanguage/getActiveLanguage';

const locale = getActiveLanguage();
const translations = locale === 'de' ? de : en;

// basename for BrowserRouter
const basename = window.location.pathname;

if (!window.localStorage.getItem('basename')) {
    // is used to check, if react is hosted on a subdomain
    window.localStorage.setItem('basename', basename);
}

ReactDOM.render(
    <IntlProvider locale={locale} messages={translations}>
        <App />
    </IntlProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
