import moment from 'moment';
/**
 * get the last entry of the chargeLoadPlan object as it contains the last charging timestamp
 * @returns {string} time of the last charging timestamp
 */
export default chargingPlan => {
    if (!chargingPlan || !Array.isArray(chargingPlan)) {
        return '';
    }

    const chargingPlanLength = chargingPlan.length;
    const lastEntryTime = chargingPlan[chargingPlanLength - 1].timestamp;

    return moment(new Date(lastEntryTime)).format('HH:mm');
};
