import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';

import bffService from '../../services/bff';
import useContext from '../../hooks/useContext';
import AssetTypeTabs from '../../components/assetTypeTabs/AssetTypeTabs';
import InfoBox from '../../components/infoBox/InfoBox';

const Asset = ({ match }) => {
    const [context, setContext] = useContext();
    const {
        params: { assetId },
    } = match;
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAsset = async () => {
            const { response, error: serviceError } = await bffService.getAsset(
                assetId,
            );
            if (serviceError) {
                setError(serviceError);
            } else {
                setContext(prevContext => ({
                    ...prevContext,
                    asset: response,
                }));
            }
        };

        fetchAsset();
    }, [assetId, setContext]);

    useEffect(() => {
        if (context.asset && !context.asset.messages && !error) {
            const fetchMessages = async () => {
                const {
                    response: messages,
                    error: messagesError,
                } = await bffService.getMessages(assetId);
                if (messagesError) {
                    setError(messagesError);
                } else {
                    setContext(prevContext => ({
                        ...prevContext,
                        asset: { ...prevContext.asset, messages },
                    }));
                    setError(null);
                }
            };
            fetchMessages();
            const interval = setInterval(() => {
                fetchMessages();
            }, 30000);
            return () => {
                clearInterval(interval);
            };
        }
        return () => {};
    }, [assetId, context.asset, error, setContext]);

    useEffect(() => {
        if (context.asset && !context.asset.liveData && !error) {
            const fetchLiveData = async () => {
                const {
                    response: liveData,
                    error: liveDataError,
                } = await bffService.getLiveData(
                    assetId,
                    context.asset.deviceType,
                );
                if (liveDataError) {
                    setError(liveDataError);
                } else {
                    setContext(prevContext => ({
                        ...prevContext,
                        asset: { ...prevContext.asset, liveData },
                    }));
                    setError(null);
                }
            };
            fetchLiveData();
            const interval = setInterval(() => {
                fetchLiveData();
            }, 30000);
            return () => {
                clearInterval(interval);
            };
        }
        return () => {};
    }, [assetId, context.asset, error, setContext]);

    return (
        <>
            {error && <Redirect to="/assets" />}
            {context.asset && (
                <>
                    <Box mb={2}>
                        <Grid container spacing={3}>
                            {context.asset.liveData &&
                                context.asset.liveData.map((data, index) => (
                                    <InfoBox
                                        title={data.id}
                                        data={data.value}
                                        unit={data.unit}
                                        type={data.type}
                                        key={index}
                                        severity={data.severity}
                                        timestamp={data.timestamp}
                                    />
                                ))}
                        </Grid>
                    </Box>
                    <AssetTypeTabs assetType={context.asset.deviceType} />
                </>
            )}
        </>
    );
};

Asset.propTypes = {
    match: propTypes.object,
};

export default Asset;
