/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import propTypes from 'prop-types';
import {
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Paper,
    Typography,
    Button,
} from '@material-ui/core';
import {
    BatteryChargingFull,
    Timer,
    EvStation,
    Euro,
} from '@material-ui/icons';
import { useIntl, FormattedMessage } from 'react-intl';

import ChargingProgress from '../chargingProgress/ChargingProgress';
import useStyles from './styles';

const ChargeProcess = ({ currentChargingProcess, onStopButtonClick }) => {
    const classes = useStyles();
    const intl = useIntl();

    const getTotalPrice = () => {
        const { chargingPlan } = currentChargingProcess;

        return chargingPlan.totalPrice;
    };

    return (
        <Fragment>
            <Typography className={classes.mainHeadline} variant="h1">
                <FormattedMessage id="ueApp.mainPage.chargeProcess.currentChargeProcess" />
            </Typography>
            <ChargingProgress currentChargingProcess={currentChargingProcess} />
            <Paper className={classes.paper}>
                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <EvStation />
                        </ListItemAvatar>
                        <ListItemText
                            primary={intl.formatMessage({
                                id:
                                    'ueApp.mainPage.chargeProcess.chargingStation',
                            })}
                            secondary={currentChargingProcess.chargingStation}
                        />
                    </ListItem>
                </List>
            </Paper>
            <Paper className={classes.paper}>
                <Typography variant="h3">
                    <FormattedMessage id="ueApp.mainPage.chargeProcess.conditions" />
                </Typography>
                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <BatteryChargingFull />
                        </ListItemAvatar>
                        <ListItemText
                            primary={intl.formatMessage({
                                id:
                                    'ueApp.mainPage.chargeProcess.desiredChargeLevel',
                            })}
                            secondary={`${currentChargingProcess.desiredChargeLevel} %`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Euro />
                        </ListItemAvatar>
                        <ListItemText
                            primary={intl.formatMessage({
                                id: 'ueApp.mainPage.chargeProcess.price',
                            })}
                            secondary={`${getTotalPrice()} €`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Timer />
                        </ListItemAvatar>
                        <ListItemText
                            primary={intl.formatMessage({
                                id: 'ueApp.mainPage.chargeProcess.deadline',
                            })}
                            secondary={`${currentChargingProcess.chargingPlan.eta} Uhr`}
                        />
                    </ListItem>
                </List>
            </Paper>
            <Button
                onClick={onStopButtonClick}
                color="primary"
                variant="contained"
            >
                {intl.formatMessage({
                    id: 'ueApp.mainPage.chargeProcess.stop',
                })}
            </Button>
        </Fragment>
    );
};

ChargeProcess.propTypes = {
    currentChargingProcess: propTypes.object.isRequired,
    onStopButtonClick: propTypes.func,
};

export default ChargeProcess;
