import React from 'react';
import propTypes from 'prop-types';

import ScaleBox from './ScaleBox';

const WhitelabelScaleBox = ({ title, data, unit, scaleBoxType, size, hasScale }) => {
    let value;
    // if data and scaleBoxType are given we need to access an object by scaleBoxType to get the value
    // if hasScale is false, we need no scaleBoxType and therefore the given data is no object
    if (data && scaleBoxType)
    {
        value = data[scaleBoxType];
    } else if (data && !hasScale) {
        value = data.value.toFixed(0);
    }

    return (
        <ScaleBox
            title={title}
            value={value}
            unit={unit}
            scaleBoxType={scaleBoxType}
            size={size}
            hasScale={hasScale}
        />
    );
};

WhitelabelScaleBox.propTypes = {
    title: propTypes.string.isRequired,
    data: propTypes.object,
    unit: propTypes.string,
    scaleBoxType: propTypes.string,
    size: propTypes.string,
    hasScale: propTypes.bool,
};

export default WhitelabelScaleBox;
