import React from 'react';
import propTypes from 'prop-types';
import { Card, Grid } from '@material-ui/core';
import ConnectivityBox from './ConnectivityBox';
import UpcomingEventBox from './UpcomingEventBox';
import DefaultBox from './DefaultBox';

const InfoBox = ({ data, unit, title, type, timestamp, severity, valueId }) => {
    return (
        <Grid item>
            <Card>
                {type === 'default' && (
                    <DefaultBox
                        value={data}
                        unit={unit}
                        title={title}
                        timestamp={timestamp}
                        valueId={valueId}
                    />
                )}
                {type === 'connectivity' && (
                    <ConnectivityBox
                        status={data}
                        title={title}
                        timestamp={timestamp}
                    />
                )}
                {type === 'upcoming_event' && (
                    <UpcomingEventBox
                        date={data}
                        eventName={title}
                        severity={severity}
                    />
                )}
            </Card>
        </Grid>
    );
};

InfoBox.propTypes = {
    valueId: propTypes.string,
    data: propTypes.string,
    unit: propTypes.string,
    title: propTypes.string,
    type: propTypes.string,
    timestamp: propTypes.string,
    severity: propTypes.string,
};

export default InfoBox;
