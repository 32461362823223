import React from 'react';
import propTypes from 'prop-types';

import KpiBox from './KpiBox';

const WhitelabelKpiBox = ({ title, data, unit, showDate, trafficLight }) => {
    return (
        <KpiBox
            title={title}
            value={data && data.value}
            unit={unit}
            showDate={showDate}
            trafficLight={trafficLight}
        />
    );
};

WhitelabelKpiBox.propTypes = {
    title: propTypes.string.isRequired,
    data: propTypes.object,
    unit: propTypes.string.isRequired,
    showDate: propTypes.bool,
    trafficLight: propTypes.object
};

export default WhitelabelKpiBox;
