import React from 'react';
import propTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { dimensions as widgetDimensions } from '../widget';
import Widget from '../widget/Widget';

const KpiBox = ({
    translationKey, // TODO deprecated; still in use by old pages
    title,
    value,
    unit,
    size = widgetDimensions.small,
    showDate,
}) => {
    const getValue = () => {
        switch (typeof value) {
            case 'string':
                return value === '' ? (
                    <span>
                        <FormattedMessage id="ueApp.kpibox.novalue" />
                    </span>
                ) : (
                    <span>{value}</span>
                );
            case 'number':
                return (
                    <NumberFormat
                        value={value}
                        displayType="text"
                        decimalSeparator=","
                        thousandSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                );
            default:
                return (
                    <span>
                        <FormattedMessage id="ueApp.kpibox.novalue" />
                    </span>
                );
        }
    };

    return (
        <Widget
            title={<FormattedMessage id={title || translationKey} />}
            {...size}
        >
            {showDate && (
                <Typography variant="h6">
                    {moment(moment.now()).format('DD.MM.YYYY')}
                </Typography>
            )}
            <Typography variant="h3">{getValue()}</Typography>
            <Typography>{unit}</Typography>
        </Widget>
    );
};

KpiBox.propTypes = {
    translationKey: propTypes.string, // TODO deprecated; still in use by old pages
    title: propTypes.string,
    value: propTypes.oneOfType([propTypes.number, propTypes.string]),
    unit: propTypes.string,
    size: propTypes.object,
    showDate: propTypes.bool,
};

export default KpiBox;
