import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { CardHeader } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import {
    OfflineBolt,
    TrendingUp,
    Place,
    Home,
    LocationCity,
    Today,
    Dvr,
    Style,
    StoreMallDirectory,
    Eco,
    DataUsage,
    Schedule,
    MergeType,
    Power,
    HomeWork,
    FilterNone,
    ViewModule,
    ZoomOutMap,
    ViewCarousel,
    EvStation,
    TrendingFlat,
    SettingsInputSvideo,
    Fingerprint,
    NetworkCell,
    Height,
    MyLocation,
    CallMade,
    Stars,
    CallSplit,
    Whatshot,
    Speed,
    FourK,
    SettingsCell
} from '@material-ui/icons';
import DefaultIcon from '@material-ui/icons/CropDin';
import { useIntl } from 'react-intl';

const DefaultBox = ({ value, unit, title, timestamp, valueId }) => {
    // TODO: refactoring needed
    const intl = useIntl();
    const availableIcons = [
        {
            messageId: 'assets.widgets.current_production.title',
            icon: OfflineBolt,
            name: 'OfflineBolt',
            backgroundColor: '#1976d2',
        },
        {
            messageId: 'assets.widgets.produced_energy.title',
            icon: TrendingUp,
            name: 'TrendingUp',
            backgroundColor: '#1976d2',
        },
        {
            messageId: 'assets.widgets.currentChargingLoad.title',
            icon: OfflineBolt,
            name: 'OfflineBolt',
            backgroundColor: '#1976d2',
        },
        {
            messageId: 'assets.widgets.consumedEnergy.title',
            icon: EvStation,
            name: 'EvStation',
            backgroundColor: '#1976d2',
        },
        {
            messageId: 'assets.widgets.currentEnergyConsumption.title',
            icon: OfflineBolt,
            name: 'OfflineBolt',
            backgroundColor: '#1976d2',
        },
        {
            messageId: 'assets.widgets.currentThermalEnergyProduction.title',
            icon: Whatshot,
            name: 'Whatshot',
            backgroundColor: '#1976d2',
        },
        {
            messageId: 'asset.tab.baseData.pv.utilization',
            icon: DataUsage,
            name: 'DataUsage',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.pv.annualGain',
            icon: Schedule,
            name: 'Schedule',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.pv.reducedEmissionCO2',
            icon: Eco,
            name: 'Eco',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.pv.powerInverterManufacturer',
            icon: StoreMallDirectory,
            name: 'StoreMallDirectory',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.pv.powerInverterType',
            icon: Style,
            name: 'Style',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.monitoring',
            icon: Dvr,
            name: 'Dvr',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.installationDate',
            icon: Today,
            name: 'Today',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.street',
            icon: Place,
            name: 'Place',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.postalCodeCity',
            icon: LocationCity,
            name: 'LocationCity',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.installationPlace',
            icon: Home,
            name: 'Home',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.type',
            icon: MergeType,
            name: 'MergeType',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.pv.powerMaxKw',
            icon: Power,
            name: 'Power',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.manufacturer',
            icon: HomeWork,
            name: 'HomeWork',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.pv.unitModel',
            icon: ViewCarousel,
            name: 'ViewCarousel',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.pv.solarCellType',
            icon: FilterNone,
            name: 'FilterNone',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.pv.unitPowerMaxW',
            icon: Power,
            name: 'Power',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.pv.unitsCount',
            icon: ViewModule,
            name: 'ViewModule',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.pv.systemTotalArea',
            icon: ZoomOutMap,
            name: 'ZoomOutMap',
            color: '#D3D3D3',
        },
        {
            messageId:
                'asset.tab.overview.chargingPoint.manufacturerDescriptionType',
            icon: MergeType,
            name: 'MergeType',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.chargingPoint.manufacturer',
            icon: HomeWork,
            name: 'HomeWork',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.chargingPoint.currentType',
            icon: TrendingFlat,
            name: 'TrendingFlat',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.chargingPoint.powerMaxKw',
            icon: Power,
            name: 'Power',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.chargingPoint.unitsCount',
            icon: ViewModule,
            name: 'ViewModule',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.chargingPoint.plugType',
            icon: SettingsInputSvideo,
            name: 'SettingsInputSvideo',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.chargingPoint.unitPowerMaxW',
            icon: Power,
            name: 'Power',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.chargingPoint.installationDate',
            icon: Today,
            name: 'Today',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.chargingPoint.evseID',
            icon: Fingerprint,
            name: 'Fingerprint',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.chargingPoint.accessibility',
            icon: EvStation,
            name: 'EvStation',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.pv.networkSupply',
            icon: NetworkCell,
            name: 'NetworkCell',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.longitude',
            icon: MyLocation,
            name: 'MyLocation',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.latitude',
            icon: MyLocation,
            name: 'MyLocation',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.declination',
            icon: Height,
            name: 'Height',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.azimuth',
            icon: CallMade,
            name: 'CallMade',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.heatPump.cop',
            icon: Stars,
            name: 'Stars',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.heatPump.unitType',
            icon: CallSplit,
            name: 'CallSplit',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.heatPump.maxPowerKW',
            icon: Power,
            name: 'Power',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.heatPump.heatingPowerMaxKw',
            icon: Whatshot,
            name: 'Whatshot',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.heatPump.model',
            icon: ViewModule,
            name: 'ViewModule',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.chp.efficiency',
            icon: DataUsage,
            name: 'DataUsage',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.chp.powerMaxKw',
            icon: Power,
            name: 'Power',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.chp.heatingPowerMaxKw',
            icon: Whatshot,
            name: 'Whatshot',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.chp.model',
            icon: ViewModule,
            name: 'ViewModule',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.monitoring',
            icon: Dvr,
            name: 'Dvr',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.installationPlace',
            icon: Home,
            name: 'Home',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.baseData.installationDate',
            icon: Today,
            name: 'Today',
            color: '#D3D3D3',
        },
        {
            messageId: 'assets.widgets.last_measurement.title',
            icon: Speed,
            name: 'Speed',
            backgroundColor: '#1976d2',
        },
        {
            messageId: 'asset.tab.overview.sensor.unitModel',
            icon: ViewCarousel,
            name: 'ViewCarousel',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.windTurbine.dataResolution',
            icon: FourK,
            name: 'FourK',
            color: '#D3D3D3',
        },
        {
            messageId: 'asset.tab.overview.windTurbine.energyCellLevel',
            icon: SettingsCell,
            name: 'SettingsCell',
            color: '#D3D3D3',
        },
    ];

    const getIconForMessageId = messageId => {
        const icon = availableIcons.find(
            availableIcon => availableIcon.messageId === messageId,
        );
        if (!icon) {
            return { icon: DefaultIcon, name: 'DefaultIcon', color: '#D3D3D3' };
        }
        return icon;
    };

    const icon = getIconForMessageId(title);

    const getValue = () => {
        if (valueId) {
            return intl.formatMessage({
                id: valueId,
            });
        }
        if (title === 'assets.widgets.last_measurement.title' && value) {
            return getTimestampFromValue(value);
        }
        return value
            ? `${value} ${unit}`
            : intl.formatMessage({
                id: 'ueApp.kpibox.novalue',
            });
    };

    const getTimestampFromValue = rawTimestamp => {
        return moment(
            rawTimestamp,
            ['YYYY-MM-DDTHH:mm:ss.SSSZ', 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ'],
            true,
        ).isValid()
            ? moment(rawTimestamp).format('DD.MM.YYYY HH:mm:ss')
            : '';
    };

    const subHeader = (
        <React.Fragment>
            {intl.formatMessage({ id: title })}
            <br />
            <span style={{ fontSize: '12px' }}>
                {getTimestampFromValue(timestamp)}
            </span>
        </React.Fragment>
    );

    return (
        <CardHeader
            avatar={
                <Avatar
                    variant="rounded"
                    style={{
                        backgroundColor: icon.backgroundColor
                            ? icon.backgroundColor
                            : '#FFFFFF',
                        color: icon.color && icon.color,
                    }}
                >
                    {React.createElement(
                        icon.icon,
                        { 'aria-label': icon.name },
                        '',
                    )}
                </Avatar>
            }
            title={getValue()}
            subheader={subHeader}
        />
    );
};

DefaultBox.propTypes = {
    valueId: propTypes.string,
    value: propTypes.any,
    unit: propTypes.string,
    title: propTypes.string,
    timestamp: propTypes.string,
};

export default DefaultBox;
