import React from 'react';
import propTypes from 'prop-types';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';

import { dimensions as widgetDimensions } from '../widget';
import Widget from '../widget/Widget';
import Chart from '../chart/Chart';

const ChargingPlansWithCharts = ({ plans, index, onSavePlan }) => {
    const intl = useIntl();
    const theme = useTheme();

    const latestTime = seriesCharging => {
        return seriesCharging.data.reduce(
            (latestTimestamp, current) =>
                current.timestamp > latestTimestamp
                    ? current.timestamp
                    : latestTimestamp,
            0,
        );
    };

    const getPlots = plan => {
        const seriesCharging = {
            id: 'seriesCharging',
            title: intl.formatMessage({
                id: 'simulation.chargingplan.chart.chargingcapacity.title',
            }),
            color: theme.palette.primary.main,
            data: plan.chargingPlan,
            disabled: false,
            isMinutely: true,
        };

        const seriesPv = {
            id: 'seriesPv',
            title: intl.formatMessage({
                id: 'simulation.chargingplan.chart.pv.title',
            }),
            color: theme.palette.secondary.main,
            data: plan.latestProductionForecast.filter(
                item => item.timestamp <= latestTime(seriesCharging),
            ),
            disabled: false,
        };

        const seriesHouseConnection = {
            id: 'seriesHouseConnection',
            title: intl.formatMessage({
                id: 'simulation.chargingplan.chart.seriesHouseConnection.title',
            }),
            color: theme.palette.houseConnection,
            data: plan.latestHouseConnectionForecast.filter(
                item => item.timestamp <= latestTime(seriesCharging),
            ),
            disabled: false,
        };
        return [seriesCharging, seriesPv, seriesHouseConnection];
    };

    const buttonHandler = () => {
        onSavePlan(
            plans.chargingPlan,
            plans.internalPrice,
            plans.externalPrice,
            plans.totalPrice,
        );
    };

    return (
        <Box mb={4} key={index}>
            <Grid container spacing={3} alignItems="stretch" component="div">
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={2}>
                        <Typography variant="h3">
                            {
                                <FormattedMessage id="simulation.chargingplan.title" />
                            }{' '}
                            {index + 1}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            data-testid={
                                `test-chooseChargingPlan${  index + 1}`
                            }
                            onClick={buttonHandler}
                            color="primary"
                            variant="contained"
                        >
                            {intl.formatMessage({
                                id: 'simulation.chargingplan.button.title',
                            })}
                        </Button>
                    </Grid>
                </Grid>
                <Chart plots={getPlots(plans)} />
                <Widget
                    title={
                        <FormattedMessage id="simulation.chargingplan.widgets.internalprice.title" />
                    }
                    dimensions={widgetDimensions.small}
                >
                    <Typography>
                        {plans.internalPrice.toFixed(2)} Euro{' '}
                    </Typography>
                </Widget>
                <Widget
                    title={
                        <FormattedMessage id="simulation.chargingplan.widgets.externalprice.title" />
                    }
                    dimensions={widgetDimensions.small}
                >
                    <Typography>
                        {plans.externalPrice.toFixed(2)} Euro{' '}
                    </Typography>
                </Widget>
                <Widget
                    title={
                        <FormattedMessage id="simulation.chargingplan.widgets.totalprice.title" />
                    }
                    dimensions={widgetDimensions.small}
                >
                    <Typography>{plans.totalPrice.toFixed(2)} Euro </Typography>
                </Widget>
            </Grid>
        </Box>
    );
};

ChargingPlansWithCharts.propTypes = {
    plans: propTypes.object.isRequired,
    index: propTypes.number.isRequired,
    onSavePlan: propTypes.func.isRequired,
};

export default ChargingPlansWithCharts;
