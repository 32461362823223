import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    root: {
        background: 'white',
        borderRadius: 3,
        border: 0,
        color: theme.palette.black,
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        cursor: 'pointer',
    },
    bestPrice: {
        background: theme.palette.primary.main,
        color: theme.palette.white,
    },
    fastestCharging: {
        background: '#FFD700',
    },
}));

export default styles;
