import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import propTypes from 'prop-types';

import moment from "moment";
import Chart from './Chart';
import bffService from '../../services/bff';

function WhitelabelChart({ plots: plotsConfig, data: plotData, ...rest }) {
    const [plots, setPlots] = useState(plotData);
    const location = useLocation();

    const handleDateChange = async (selectedFilter, dateParam) => {
        const resolution = selectedFilter === 'TODAY' ? "QUARTERLY" : "HOURLY";
        const date = moment(dateParam);
        let endDateParam;
        let startDateParam;

        switch (selectedFilter) {
            case "DAY":
                startDateParam = date.clone().startOf('day'); // start of day at 0 am
                endDateParam = date.clone().endOf('day'); // end of day at 23:59 pm
                break;
            case "WEEK":
                startDateParam = date.clone().startOf('isoWeek'); // start of week
                endDateParam = date.clone().endOf('isoWeek'); // end of week
                break;
            case "MONTH":
                startDateParam = date; // start of day at 0 am
                endDateParam = date.clone().endOf('month').endOf('day'); // end of month and day
                break;
            default:
                startDateParam = date.clone().subtract(12, 'hours') // now minus 12 hours
                endDateParam = date; // now
        }
        const pageName = location.pathname.substring(1);

        // the sensors need a different endpoint to get their measurements
        // functionality is currently only for environmental page
        // TODO: fix endpoints in backend so it can work for the other pages too
        if (pageName === "environmental") {
            let promise;
            const assetId = "54e7ec36-4cbb-45f7-9efa-ffd2b945b5f6";
            if (selectedFilter === 'TODAY') {
                promise = await bffService.getEnvironmentalChart(
                    assetId,
                );
            } else {
                promise = await bffService.getMeasurementsForMembers(
                    assetId,
                    startDateParam.toISOString(), endDateParam.toISOString(),
                    resolution
                );
            }
            if (!promise.error) {
                setPlots(promise.response)
            }
        }
    };

    /**
     * map plotData to plotConfig
     * if somehow no data is found for a specific plot id, it will be filtered out
     */
    const getMergedPlots = () =>
        plotsConfig
            .map(plot => {
                if (!plots[plot.id]) return false;

                return {
                    ...plot,
                    data: plots[plot.id].plot || plots[plot.id],
                    disabled: !plot.highlighted,
                };
            })
            .filter(plot => plot);

    return (
        getMergedPlots().length >= 1 && (
            <Chart
                plots={getMergedPlots()}
                onDateFilterChanged={handleDateChange}
                {...rest}
            />
        )
    );
}

WhitelabelChart.propTypes = {
    plots: propTypes.array.isRequired,
    data: propTypes.object,
};

export default WhitelabelChart;
