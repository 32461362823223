import React from 'react';
import { TileLayer } from 'react-leaflet';
import { REACT_APP_UE_BACKEND} from '../../../../resources/env'

const TrafficTiles = () => {
    // eslint-disable-next-line prefer-template
    const url = `${REACT_APP_UE_BACKEND}/api/qm-portal-backend/traffic/tiles?z={z}&y={y}&x={x}&apikey=` + process.env.REACT_APP_UE_API_KEY;
    return (
        <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url={url}
        />
    );
};

export default TrafficTiles;
