import scaleBoxTypes from '../../components/scaleBox/scaleBoxTypes';

const limitValues = {
    [scaleBoxTypes.OZONE]: {
        veryGoodMax: 33,
        goodMax: 65,
        satisfyingMax: 120,
        sufficientMax: 180,
        badMax: 240,
    },
    [scaleBoxTypes.PARTICULATE_MATTER]: {
        veryGoodMax: 10,
        goodMax: 20,
        satisfyingMax: 35,
        sufficientMax: 50,
        badMax: 100,
    },
    [scaleBoxTypes.NITROGEN_DIOXIDE]: {
        veryGoodMax: 25,
        goodMax: 50,
        satisfyingMax: 100,
        sufficientMax: 200,
        badMax: 500,
    },
    [scaleBoxTypes.CARBON_MONOXIDE]: {
        veryGoodMax: 1,
        goodMax: 2,
        satisfyingMax: 4,
        sufficientMax: 10,
        badMax: 30,
    },
};

const between = (val, min, max) => {
    return val >= min && val <= max;
};

/**
 * Calculates the ratio of a value to a specific range of values
 * @param val Value for which the ratio needs to be calculated
 * @param min Lowest value from the range
 * @param max Highest value from the range
 * @param stepCount Defines how many steps the range should have in between
 * @param grade Grade from which the range is
 * @returns {number} Ratio value in the specified value range
 */
const getRatio = (val, min, max, stepCount, grade) => {
    const span = max - min; // calculating the span between two intervals
    const step = span / stepCount; // calculating the step width in that specific interval
    let stepValue = min; // first step value is min, afterwards it adds up with every step taken
    let stepTaken; // between 0 and 10
    const gradeRatio = (grade - 1) * 10; // - 1 and * 10 because the values e.g. for grade 1 need to be between 0 and 10
    for (let i = 0; i <= stepCount; i++) {
        stepTaken = i;
        if (val <= stepValue) {
            break; // at this point the ratio is found
        }
        stepValue += step;
    }
    return gradeRatio + stepTaken;
}

export default (value, type) => {
    const sensorType = scaleBoxTypes[type];

    if (between(value, 0, limitValues[sensorType].veryGoodMax)) {
        return getRatio(value, 0, limitValues[sensorType].veryGoodMax, 10, 1);
    }
    if (
        between(
            value,
            limitValues[sensorType].veryGoodMax,
            limitValues[sensorType].goodMax,
        )
    ) {
        return getRatio(value, limitValues[sensorType].veryGoodMax, limitValues[sensorType].goodMax, 10, 2);
    }
    if (
        between(
            value,
            limitValues[sensorType].goodMax,
            limitValues[sensorType].satisfyingMax,
        )
    ) {
        return getRatio(value, limitValues[sensorType].goodMax, limitValues[sensorType].satisfyingMax, 10, 3);
    }
    if (
        between(
            value,
            limitValues[sensorType].satisfyingMax,
            limitValues[sensorType].sufficientMax,
        )
    ) {
        return getRatio(value, limitValues[sensorType].satisfyingMax, limitValues[sensorType].sufficientMax, 10, 4);
    }
    if (
        between(
            value,
            limitValues[sensorType].sufficientMax,
            limitValues[sensorType].badMax,
        )
    ) {
        return getRatio(value, limitValues[sensorType].sufficientMax, limitValues[sensorType].badMax, 10, 5);
    }
    return getRatio(value, limitValues[sensorType].badMax, limitValues[sensorType].badMax + 100, 10, 6);
    // plus 100 because the last grade has an open value range but still needs to be limited
};
