export default {
    baseData: {
        location: [
            {
                title: 'asset.tab.baseData.street',
                property: 'street',
            },
            {
                title: 'asset.tab.baseData.postalCodeCity',
                property: 'postalCodeCity',
            },
            {
                title: 'asset.tab.baseData.installationPlace',
                property: 'installationPlace',
            },
            {
                title: 'asset.tab.baseData.longitude',
                property: 'longitude',
            },
            {
                title: 'asset.tab.baseData.latitude',
                property: 'latitude',
            },
        ],
        facilityInfo: [
            {
                title: 'asset.tab.baseData.chargingPoint.evseID',
                property: 'evseId',
            },
            {
                title: 'asset.tab.baseData.installationDate',
                property: 'installationDate',
            },
        ],
    },
    overview: {
        media: {
            url:
                'https://car-images.bauersecure.com/pagefiles/79994/099_electric_uk_charger.jpg',
            title: 'Charging Point',
        },
        info: [
            {
                property: 'deviceType',
                title: 'asset.tab.overview.type',
                valueId: 'assetManagement.type.chargingPoint',
            },
            {
                property: 'manufacturer',
                title: 'asset.tab.overview.chargingPoint.manufacturer',
            },
            {
                property: 'manufacturerDescriptionType',
                title:
                    'asset.tab.overview.chargingPoint.manufacturerDescriptionType',
            },
            {
                property: 'currentType',
                title: 'asset.tab.overview.chargingPoint.currentType',
            },
            {
                property: 'powerMaxKw',
                title: 'asset.tab.overview.chargingPoint.powerMaxKw',
                unit: 'kW',
            },
            {
                property: 'unitsCount',
                title: 'asset.tab.overview.chargingPoint.unitsCount',
            },
            {
                property: 'plugType',
                title: 'asset.tab.overview.chargingPoint.plugType',
            },
            {
                property: 'unitPowerMaxW',
                title: 'asset.tab.overview.chargingPoint.unitPowerMaxW',
                unit: 'kW',
            },
        ],
    },
    performance: [
        {
            chartType: 'heatmap',
            plotUnit: 'kWh',
            plots: [
                {
                    id: 'chargingPower',
                    color: '#0a57ff',
                },
            ],
        },
    ],
};
