/* eslint-disable no-throw-literal */
import moment from 'moment';
import { bffInstance as instance } from '../../config/axios/instances';
import { getEndpoint } from '../../helper';
import { REACT_APP_UE_BACKEND } from '../../resources/env';

const isErrorResponseCode = response =>
    response.status < 200 || response.status > 299;
const baseUrl = `${REACT_APP_UE_BACKEND}/api/qm-portal-backend`;
let quarterId = '';

/**
 * The service wrapper is designed to either return the response or an error object.
 * Every service method is wrapped in a try catch block, where a catch is only reached,
 * if response status is 500 or a general error occurred.
 * usage: const { error, response } = await service method
 * if (error) {handle error}
 * else {handle response}
 */
const bffService = {
    getComponentData: async dataUrl => {
        const endPoint = getEndpoint(
            `${REACT_APP_UE_BACKEND}${dataUrl}`,
            dataUrl,
        );

        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getComponentDataException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch (err) {
            return {
                error: {
                    name: 'getComponentDataException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getQuarterSettings: async () => {
        const endPoint = getEndpoint(
            `${baseUrl}/quarter/${quarterId}`,
            'quarter/quarter.json',
        );

        try {
            const response = await instance.get(endPoint);

            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getQuarterSettingsException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch {
            return {
                error: {
                    name: 'getQuarterSettingsException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    saveQuarterSettings: async quarterSettings => {
        const endPoint = getEndpoint(`${baseUrl}/quarter/${quarterId}`);

        try {
            const response = await instance.patch(endPoint, quarterSettings);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'saveQuarterSettingsException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response, error };
        } catch (e) {
            return {
                error: {
                    name: 'saveQuarterSettingsException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getContentConfig: async () => {
        const endPoint = getEndpoint(
            `${baseUrl}/content-config`,
            'content/config.json',
        );

        try {
            const response = await instance.get(endPoint);

            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getContentConfigException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            // eslint-disable-next-line prefer-destructuring
            quarterId = response.data.quarterId;
            return { response: response.data, error };
        } catch {
            return {
                error: {
                    name: 'getContentConfigException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getAssets: async () => {
        const endPoint = getEndpoint(
            `${baseUrl}/assets`,
            'pages/assets/assets.json',
        );

        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getAssetsException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch {
            return {
                error: {
                    name: 'getAssetsException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getAsset: async assetId => {
        const endPoint = getEndpoint(
            `${baseUrl}/assets/${assetId}`,
            `pages/assets/${assetId}/baseData.json`,
        );

        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getAssetException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch {
            return {
                error: {
                    name: 'getAssetException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    addAsset: async assetData => {
        const endPoint = getEndpoint(
            `${baseUrl}/assets?quarter-id=${quarterId}`,
        );

        try {
            const response = await instance.post(endPoint, assetData);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'addAssetException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch {
            return {
                error: {
                    name: 'getAssetException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getMessages: async assetId => {
        const endPoint = getEndpoint(
            `${baseUrl}/assets/${assetId}/messages`,
            `pages/assets/${assetId}/messages.json`,
        );

        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getMessagesException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch {
            return {
                error: {
                    name: 'getMessagesException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getLiveData: async (assetId, type) => {
        const endPoint = getEndpoint(
            `${baseUrl}/assets/${assetId}/liveData?type=${type}`,
            `pages/assets/${assetId}/liveData.json`,
        );

        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getLiveDataException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch {
            return {
                error: {
                    name: 'getLiveDataException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getAssetSeriesData: async (assetId, type) => {
        const endPoint = getEndpoint(
            `${baseUrl}/charts/asset/${assetId}?type=${type}`,
            `pages/assets/${assetId}/performanceData.json`,
        );
        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getAssetSeriesData',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch (e) {
            return {
                error: {
                    name: 'getAssetSeriesData',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getPlots: async (type, selectedFilter) => {
        const endPoint = getEndpoint(
            `${baseUrl}/charts/${type}?quarter-id=${quarterId}&timeRange=${selectedFilter}`,
        );
        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getPlots',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch (e) {
            return {
                error: {
                    name: 'getPlots',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getEnvironmentalChart: async assetId => {
        const endPoint = getEndpoint(
            `${baseUrl}/charts/environmental/${assetId}`,
        );
        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getEnvironmentalChart',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch (e) {
            return {
                error: {
                    name: 'getEnvironmentalChart',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getMeasurementsForMembers: async (assetId, from, to, resolution) => {
        const endPoint = getEndpoint(
            `${baseUrl}/measurements/members?asset_id=${assetId}&from=${from}&to=${to}&aggregation=AVG&resolution=${resolution}`,
        );
        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getMeasurementsForMembers',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch (e) {
            return {
                error: {
                    name: 'getMeasurementsForMembers',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getChargingStationDetails: async assetId => {
        const endPoint = getEndpoint(
            `${baseUrl}/assets/${assetId}/chargingSessions`,
            `pages/assets/${assetId}/chargingSessions.json`,
        );

        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getChargingStationDetailsException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch {
            return {
                error: {
                    name: 'getChargingStationDetailsException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getChargingPoints: async () => {
        const endPoint = getEndpoint(
            `${baseUrl}/charging-points?quarter-id=${quarterId}`,
            'pages/simulation/charging-points.json',
        );

        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getChargingPointsException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch {
            return {
                error: {
                    name: 'getChargingPointsException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getChargingPlans: async params => {
        const endPoint = getEndpoint(
            `${baseUrl}/charging-plans?${params}`,
            'pages/simulation/charging-plans.json',
        );
        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getChargingPlansException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }
            // TODO: remove, when https://dev.azure.com/ueadmin/urban%20ENERGY%20MVP/_workitems/edit/1147 is finished
            const data = [];

            response.data.forEach(chargingPlan => {
                const chargeLoadPlan = Object.entries(
                    chargingPlan.chargingPlanTimeSeries,
                ).map(([date, kw]) => ({
                    timestamp: new Date(date).getTime(),
                    value: kw,
                }));

                const latestProductionForecast = Object.entries(
                    chargingPlan.latestProductionForecast,
                ).map(([date, kw]) => ({
                    timestamp: new Date(date).getTime(),
                    value: kw,
                }));

                const latestHouseConnectionForecast = Object.entries(
                    chargingPlan.latestHouseConnectionForecast,
                ).map(([date, kw]) => ({
                    timestamp: new Date(date).getTime(),
                    value: kw,
                }));

                data.push({
                    ...chargingPlan,
                    chargingPlan: chargeLoadPlan,
                    latestProductionForecast,
                    latestHouseConnectionForecast,
                });
            });

            return { response: data, error };
        } catch (e) {
            return {
                error: {
                    name: 'getChargingPlansException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    saveChargingPlan: async (
        chargePointId,
        chargingPlan,
        internalPrice,
        externalPrice,
        totalPrice,
    ) => {
        const parsedChargingPlan = {};
        // TODO: remove, when https://dev.azure.com/ueadmin/urban%20ENERGY%20MVP/_workitems/edit/1147 is finished
        chargingPlan.forEach(item => {
            const key = moment
                .utc(item.timestamp)
                .format('YYYY-MM-DDTHH:mm:ss.000[Z]');
            parsedChargingPlan[key] = item.value;
        });

        try {
            const response = await instance.post(
                `${baseUrl}/charging-points/${chargePointId}/charging-session`,
                {
                    chargePointId,
                    chargingPlan: parsedChargingPlan,
                    internalPrice,
                    externalPrice,
                    totalPrice,
                },
            );
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'saveChargingPlanException',
                    message: `Error for endpoint: ${baseUrl}/charging-points/${chargePointId}/charging-session`,
                    statusCode: response.status,
                };
            }

            return { response: response.status, error };
        } catch (e) {
            return {
                error: {
                    name: 'saveChargingPlanException',
                    message: `Error for endpoint: ${baseUrl}/charging-points/${chargePointId}/charging-session`,
                },
            };
        }
    },
    deleteChargingPlan: async chargePointId => {
        try {
            const response = await instance.delete(
                `${baseUrl}/charging-points/${chargePointId}/charging-session`,
            );
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'deleteChargingPlanException',
                    message: `Error for endpoint: ${baseUrl}/charging-points/${chargePointId}/charging-session`,
                    statusCode: response.status,
                };
            }

            return { response: response.status, error };
        } catch (e) {
            return {
                error: {
                    name: 'deleteChargingPlanException',
                    message: `Error for endpoint: ${baseUrl}/charging-points/${chargePointId}/charging-session`,
                },
            };
        }
    },
    getIsochrones: async (lat, lng, dt) => {
        const endPoint = getEndpoint(
            `${baseUrl}/traffic/polygons?lat=${lat}&lng=${lng}&dt=${dt}`,
            'pages/sensors/map/isochrones.json',
        );
        try {
            const response = await instance.get(endPoint);
            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getIsochrones',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch (e) {
            return {
                error: {
                    name: 'getIsochrones',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
};

export default bffService;
