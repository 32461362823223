import React, { useState, Fragment, useEffect } from 'react';
import {
    Container,
    Modal,
    withStyles,
    Typography,
    Button,
} from '@material-ui/core';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { httpStatus } from '../../helper';
import Controls from './components/controls';
import { ChargingPlans, Snackbar } from '../../components';
import bffService from '../../services/bff';
import ChargeProcess from './components/chargeProcess';

import styles from './styles';

function Customer({ classes }) {
    const intl = useIntl();
    const [chargingPoints, setChargingPoints] = useState([]);
    const [chargingPlansState, setChargingPlansState] = useState([]);
    const [chargingSettings, setChargingSettings] = useState({});
    const [currentChargeProcess, setCurrentChargeProcess] = useState(
        JSON.parse(window.localStorage.getItem('chargingPlan')), // getItem returns null if key is not existent and JSON.parse(null) === null
    );
    const [error, setError] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, error: false });
    const [modal, setModal] = useState(false);
    const [selectedChargingPlan, setSelectedChargingPlan] = useState(null);
    const [chargingPointUnavailable, setChargingPointUnavailable] = useState(
        false,
    );

    useEffect(() => {
        const fetchChargingPoints = async () => {
            const {
                response,
                error: serviceError,
            } = await bffService.getChargingPoints();

            if (serviceError) {
                setError(true);
            } else {
                setChargingPoints(response);
            }
        };

        fetchChargingPoints();
    }, []);

    const removeChargingPlans = () => {
        setChargingPlansState([]);
    };

    const handleClose = () => {
        setError(false);
        setSnackbar({ open: false, error: false });
        setChargingPointUnavailable(false);
    };

    const handleModalClose = () => {
        setModal(false);
        setSelectedChargingPlan(null);
    };

    const handleModalOpen = chargingPlan => {
        setModal(true);
        setSelectedChargingPlan(chargingPlan);
    };

    const onChargePlansLoaded = (
        chargingPlans,
        chargingStation,
        currentChargeLevel,
        desiredChargeLevel,
    ) => {
        setChargingPlansState(chargingPlans);
        setChargingSettings({
            chargingStation,
            currentChargeLevel,
            desiredChargeLevel,
        });
    };

    const saveChargingPlan = async () => {
        const {
            chargingPlan,
            externalPrice,
            internalPrice,
            totalPrice,
        } = selectedChargingPlan;

        const {
            response: responseStatus,
            error: serviceError,
        } = await bffService.saveChargingPlan(
            chargingSettings.chargingStation,
            chargingPlan,
            internalPrice,
            externalPrice,
            totalPrice,
        );

        if (serviceError) {
            setError(true);
            return;
        }

        if (responseStatus === httpStatus.OK) {
            window.localStorage.setItem(
                'chargingPlan',
                JSON.stringify({
                    chargingPlan: selectedChargingPlan,
                    ...chargingSettings,
                }),
            );
            setCurrentChargeProcess({
                chargingPlan: selectedChargingPlan,
                ...chargingSettings,
            });
            // reset charging plans and settings
            setChargingPlansState([]);
            setChargingSettings({});
            setSelectedChargingPlan(null);
            setError(false);
        } else if (responseStatus === httpStatus.BAD_REQUEST) {
            setChargingPointUnavailable(true);
        }
    };

    const stopChargingProcess = async () => {
        const {
            response: responseStatus,
            error: serviceError,
        } = await bffService.deleteChargingPlan(
            currentChargeProcess.chargingStation,
        );

        if (serviceError) {
            setError(true);
            return;
        }

        if (responseStatus === httpStatus.OK) {
            window.localStorage.removeItem('chargingPlan');
            setCurrentChargeProcess(null);
            setSnackbar({ open: true, error: false });
        } else if (responseStatus === httpStatus.BAD_REQUEST) {
            setChargingPointUnavailable(true);
        }
    };

    return (
        <Container maxWidth="sm">
            {!currentChargeProcess ? (
                <Fragment>
                    <Controls
                        chargingStations={chargingPoints}
                        chargingOptions={chargingPoints}
                        onChargePlansLoaded={onChargePlansLoaded}
                        removeChargingPlans={removeChargingPlans}
                    />
                    <div>
                        <ChargingPlans
                            chargingPlans={chargingPlansState}
                            onChargingPlanClick={handleModalOpen}
                        />
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <ChargeProcess
                        currentChargingProcess={currentChargeProcess}
                        onStopButtonClick={stopChargingProcess}
                    />
                </Fragment>
            )}
            {error && (
                <Snackbar
                    open={error}
                    onClose={handleClose}
                    variant="error"
                    messageId="generic.error"
                />
            )}
            {chargingPointUnavailable && (
                <Snackbar
                    open={chargingPointUnavailable}
                    onClose={handleClose}
                    variant="error"
                    messageId="customer.snackbar.chargingpoint.unavailable"
                />
            )}
            {snackbar.open && (
                <Snackbar
                    open={snackbar.open}
                    onClose={handleClose}
                    variant={snackbar.error ? 'error' : 'success'}
                    messageId={`customer.snackbar.${
                        snackbar.error
                            ? 'errorStopChargingPlan'
                            : 'successStopChargingPlan'
                    }`}
                />
            )}
            {selectedChargingPlan && (
                <Modal open={modal} onClose={handleModalClose}>
                    <div className={classes.modal}>
                        <Typography variant="subtitle1">
                            {intl.formatMessage({
                                id: 'ueApp.mainPage.chargeProcess.price',
                            })}
                            :{` ${selectedChargingPlan.totalPrice} €`}
                            <br />
                            {intl.formatMessage({
                                id: 'ueApp.mainPage.chargeProcess.deadline',
                            })}
                            : {`${selectedChargingPlan.eta} Uhr`}
                        </Typography>
                        <Button
                            onClick={saveChargingPlan}
                            color="primary"
                            variant="contained"
                        >
                            {intl.formatMessage({
                                id: 'ueApp.mainPage.button.sendChargingRequest',
                            })}
                        </Button>
                    </div>
                </Modal>
            )}
        </Container>
    );
}

Customer.propTypes = {
    classes: propTypes.object,
    intl: propTypes.object,
};

export default withStyles(styles)(Customer);
