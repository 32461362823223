const styles = {
    modal: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 16,
        textAlign: 'center',
        backgroundColor: '#ffffff',
    },
};

export default styles;
