import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';

import availableAssetTypes from '../../../../../availableAssetTypes';
import PVCoreData from './PVCoreData';
import BhkwCoreData from './BhkwCoreData';
import HeatPumpCoreData from './HeatPumpCoreData';
import ChargingPointCoreData from './ChargingPointCoreData';

const CoreDataStep = ({ onChange, stepState, addAssetState, step }) => {
    const intl = useIntl();
    const assetType = addAssetState[0];
    const handleChange = event => {
        const newState = { ...stepState };
        newState[event.target.getAttribute('id')] = event.target.value;

        onChange(newState, step);
    };

    const getCoreDataStepFromAssetType = () => {
        switch (assetType) {
            case availableAssetTypes.pv:
                return <PVCoreData stepState={stepState} />;
            case availableAssetTypes.bhkw:
                return <BhkwCoreData stepState={stepState} />;
            case availableAssetTypes.heatPump:
                return <HeatPumpCoreData stepState={stepState} />;
            case availableAssetTypes.chargingPoint:
                return <ChargingPointCoreData stepState={stepState} />;
            default:
                return (
                    <Typography>
                        {intl.formatMessage({
                            id: 'assetManagement.addAsset.chooseType',
                        })}
                    </Typography>
                );
        }
    };

    return (
        <form onChange={handleChange}>
            <Grid container spacing={1}>
                {getCoreDataStepFromAssetType()}
            </Grid>
        </form>
    );
};

CoreDataStep.propTypes = {
    addAssetState: propTypes.array,
    step: propTypes.number,
    onChange: propTypes.func,
    stepState: propTypes.object,
};

export default CoreDataStep;
