import React from 'react';
import PropTypes from 'prop-types';

import * as Components from '..';

const availableTypes = {
    KPI_BOX: 'WhitelabelKpiBox',
    SCALE_BOX: 'WhitelabelScaleBox',
    CHART: 'WhitelabelChart',
    TABLE: 'WhitelabelTable',
    MAP: 'WhitelabelMap',
    WEATHER_WIDGET: 'WeatherWidget',
    AIR_QUALITY_BOX: 'WhitelabelAirQualityBox',
};

const UrbanComponent = ({ componentDefinition, index, state, data }) => {
    const Component = Components[componentDefinition.type];
    const ComponentLoading = Components[`${componentDefinition.type}Loading`];
    const ComponentError = Components[`${componentDefinition.type}Error`];

    const getComponentMarkup = stateParam => {
        switch (stateParam) {
            case 'loading':
                return <ComponentLoading />;
            case 'error':
                return <ComponentError {...componentDefinition.attributes} />;
            default:
                return (
                    <Component
                        key={index}
                        {...componentDefinition.attributes}
                        data={data}
                    />
                );
        }
    };

    return getComponentMarkup(state);
};

UrbanComponent.propTypes = {
    state: PropTypes.string,
    index: PropTypes.number,
    componentDefinition: PropTypes.shape({
        type: PropTypes.oneOf(Object.values(availableTypes)),
        attributes: PropTypes.object,
    }),
};

export default UrbanComponent;
