import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

const ConnectionTypeStep = () => {
    const intl = useIntl();

    return (
        <Typography data-testid="test-connectionStepStatus">
            {intl.formatMessage({
                id: 'assetManagement.addAsset.connection',
            })}
        </Typography>
    );
};

export default ConnectionTypeStep;
