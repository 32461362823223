import React from 'react';

import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Widget } from '../..';
import { dimensions as widgetDimensions } from '../../widget';

function KpiBoxLoading() {
    return (
        <Widget title={<Skeleton width={'100%'} />} {...widgetDimensions.small}>
            <Typography>{<Skeleton width={'30%'} />}</Typography>
            <Typography>{<Skeleton width={'30%'} />}</Typography>
        </Widget>
    );
}

export default KpiBoxLoading;
