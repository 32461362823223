export default (
    unit,
    seriesLength,
    wistaBlue,
    annotations,
    isColored,
) => ({
    annotations: {
        position: 'back',
        xaxis: annotations,
        yaxis: [
            {
                y: -0.2,
                strokeDashArray: 0,
                borderColor: wistaBlue,
                fillColor: wistaBlue,
                width: '100%',
                offsetX: -12,
            },
        ],
    },
    dataLabels: {
        enabled: false,
    },
    plotOptions: {
        bar: {
            columnWidth: '80%',
        },
    },
    fill: {
        opacity: 0.9,
    },
    xaxis: {
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        type: 'numeric',
        labels: {
            show: false,
        },
    },
    yaxis: {
        type: 'numeric',
        title: {
            text: unit,
            rotate: 0,
            offsetX: 8,
            offsetY: 52,
            style: {
                fontSize: '14px',
                color: wistaBlue,
                fontWeight: 'bold',
            },
        },
        decimalsInFloat: 0,
        forceNiceScale: true,
        min: 0,
        labels: {
            show: false,
        },
    },
    tooltip: {
        enabled: false,
    },
    colors: [
        isColored
            ? ({ value }) => {
                switch (true) {
                    case value === 1:
                        return '#50FFD0';
                    case value === 2:
                        return '#A7F890';
                    case value === 3:
                        return '#FCEC6A';
                    case value === 4:
                        return '#F2A772';
                    case value === 5:
                        return '#F0655B';
                    case value === 6:
                        return '#EC4452';
                    default:
                        return '#333333';
                }
            }
            : wistaBlue,
    ],
    grid: {
        show: false,
    },
});
