import React from 'react';
import propTypes from 'prop-types';
import { Modal } from '@material-ui/core';

import useStyles from './styles';

const ModalComponent = ({ children, open, onClose }) => {
    const classes = useStyles();
    return (
        <Modal
            data-testid="test-Modal"
            open={open}
            onClose={onClose}
            className={classes.root}
            disableBackdropClick={true}
        >
            <div className={classes.modalContent}>{children}</div>
        </Modal>
    );
};

ModalComponent.propTypes = {
    open: propTypes.bool,
    onClose: propTypes.func,
    children: propTypes.any,
};

export default ModalComponent;
