import React from 'react';
import { AppBar, Toolbar, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Logo from './logo';
import com2mService from '../../../services/com2m/com2mService';
import styles from './styles';
import LogoutButton from './logoutButton/LogoutButton';
import MobileMenuButton from './mobileMenuButton';

function Header({
    classes,
    isMobile,
    toggleSidebarMobile,
    isQuarterManagerPortal,
    logoUrl,
}) {
    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                {isMobile && (
                    <MobileMenuButton
                        onClick={toggleSidebarMobile}
                        className={classes.mobileMenuButton}
                        aria-label="Open or close sidebar"
                        data-testid="mobileMenuButton"
                    />
                )}
                <div className={classes.logo}>
                    <Logo className={classes.logoImage} logoUrl={logoUrl} />
                </div>
                {isQuarterManagerPortal && (
                    <Typography
                        variant="h6"
                        weight="medium"
                        className={clsx(classes.logotype, classes.grow)}
                    >
                        <FormattedMessage id="layout.header.headline" />
                    </Typography>
                )}
                <LogoutButton
                    className={classes.logoutType}
                    onClick={com2mService.logOut}
                    data-testid="logoutButton"
                />
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    classes: propTypes.object,
    isMobile: propTypes.bool,
    isQuarterManagerPortal: propTypes.bool,
    toggleSidebarMobile: propTypes.func,
    logoUrl: propTypes.string,
};

export default withStyles(styles)(Header);
