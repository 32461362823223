import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';

import Skeleton from '@material-ui/lab/Skeleton';
import { dimensions as widgetDimensions } from '../widget';
import Widget from '../widget/Widget';

const WeatherWidget = () => {
    const [loading, setLoading] = useState(true);

    const theme = useTheme();
    const createWeatherWidgetScript = (document, script, id) => {
        let widgetScript;
        const firstScript = document.getElementsByTagName(script)[0];
        if (!document.getElementById(id)) {
            widgetScript = document.createElement(script);
            widgetScript.id = id;
            widgetScript.src = 'https://weatherwidget.io/js/widget.min.js';
            firstScript.parentNode.insertBefore(widgetScript, firstScript);
        }
    };

    useEffect(() => {
        createWeatherWidgetScript(document, 'script', 'weatherwidget-io-js');
        setTimeout(() => setLoading(false), 1000);
        return () => {
            const script = document.getElementById('weatherwidget-io-js');
            script.remove();
        };
    }, []);

    return loading ? (
        <Widget {...widgetDimensions.medium}>
            <Skeleton width={'100%'} />
        </Widget>
    ) : (
        <Widget {...widgetDimensions.medium}>
            <a
                className="weatherwidget-io"
                href={`https://forecast7.com/de/52d5213d40/berlin/`}
                data-label_1="BERLIN"
                data-label_2="WEATHER"
                data-days="3"
                data-theme="original"
                data-basecolor="#ffffff"
                data-accent=""
                data-textcolor={theme.weatherWidget.textcolor}
                data-highcolor={theme.weatherWidget.highcolor}
                data-lowcolor={theme.weatherWidget.lowcolor}
                data-mooncolor={theme.weatherWidget.mooncolor}
                data-cloudcolor={theme.weatherWidget.cloudcolor}
                data-cloudfill={theme.weatherWidget.cloudfill}
                data-raincolor={theme.weatherWidget.raincolor}
                data-snowcolor={theme.weatherWidget.snowcolor}
            >
                Weather
            </a>
        </Widget>
    );
};

export default WeatherWidget;
