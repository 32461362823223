import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';

const PVCoreData = ({ stepState }) => {
    const intl = useIntl();

    return (
        <>
            <Grid item md={6}>
                <TextField
                    id="name"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.name',
                    })}
                    defaultValue={stepState.name}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="latitude"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.latitude',
                    })}
                    defaultValue={stepState.latitude}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="longitude"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.longitude',
                    })}
                    defaultValue={stepState.longitude}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="declination"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.declination',
                    })}
                    defaultValue={stepState.declination}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="azimuth"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.azimuth',
                    })}
                    defaultValue={stepState.azimuth}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="powerMaxKw"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.pv.powerMaxKw',
                    })}
                    defaultValue={stepState.powerMaxKw}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="manufacturer"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.manufacturer',
                    })}
                    defaultValue={stepState.manufacturer}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="unitModel"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.pv.unitModel',
                    })}
                    defaultValue={stepState.unitModel}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="solarCellType"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.pv.solarCellType',
                    })}
                    defaultValue={stepState.solarCellType}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="unitPowerMaxW"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.pv.unitPowerMaxW',
                    })}
                    defaultValue={stepState.unitPowerMaxW}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="unitsCount"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.pv.unitsCount',
                    })}
                    defaultValue={stepState.unitsCount}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="systemTotalArea"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.pv.systemTotalArea',
                    })}
                    defaultValue={stepState.systemTotalArea}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="networkSupply"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.pv.networkSupply',
                    })}
                    defaultValue={stepState.networkSupply}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="utilization"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.pv.utilization',
                    })}
                    defaultValue={stepState.utilization}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="annualGain"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.pv.annualGain',
                    })}
                    defaultValue={stepState.annualGain}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="reducedEmissionCO2"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.pv.reducedEmissionCO2',
                    })}
                    defaultValue={stepState.reducedEmissionCO2}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="powerInverterManufacturer"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.pv.powerInverterManufacturer',
                    })}
                    defaultValue={stepState.powerInverterManufacturer}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="powerInverterType"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.pv.powerInverterType',
                    })}
                    defaultValue={stepState.powerInverterType}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="monitoringSystem"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.monitoring',
                    })}
                    defaultValue={stepState.monitoringSystem}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="installationDate"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.installationDate',
                    })}
                    defaultValue={stepState.installationDate}
                    type="datetime-local"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="street"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.street',
                    })}
                    defaultValue={stepState.street}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="postalCode"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.postalCode',
                    })}
                    defaultValue={stepState.postalCode}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="city"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.city',
                    })}
                    defaultValue={stepState.city}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="installationPlace"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.installationPlace',
                    })}
                    defaultValue={stepState.installationPlace}
                />
            </Grid>
        </>
    );
};

PVCoreData.propTypes = {
    stepState: propTypes.object,
};

export default PVCoreData;
