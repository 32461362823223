import React from 'react';
import ApexChart from 'react-apexcharts';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { merge as _merge } from 'lodash';

import getHeatmapSettings from './settings';

/**
 * takes series, a from-time in hours and a to-time in hours
 * and returns an array with items, where the timestamp matches the timerange
 * @param {array} series
 * structure:
 * [{ timestamp: "2020-08-01T00:00:00Z", "value": 20 },
 * { timestamp: "2020-08-01T01:00:00Z", "value": 20 },
 * { timestamp: "2020-08-01T02:00:00Z", "value": 20 },
 * { timestamp: "2020-08-01T03:00:00Z", "value": 20 },
 * { timestamp: "2020-08-01T04:00:00Z", "value": 20 },
 * { timestamp: "2020-08-01T05:00:00Z", "value": 20 },
 * { timestamp: "2020-08-01T06:00:00Z", "value": 5 },
 * { timestamp: "2020-08-01T07:00:00Z", "value": 10 },
 * { timestamp: "2020-08-01T08:00:00Z", "value": 15 },
 * { timestamp: "2020-08-01T09:00:00Z", "value": 17 },
 * { timestamp: "2020-08-01T10:00:00Z", "value": 22 }]
 * @param {number} from 0
 * @param {number} to 3
 * @returns {array}
 * structure:
 * [{ timestamp: "2020-08-01T00:00:00Z", "value": 20 },
 * { timestamp: "2020-08-01T01:00:00Z", "value": 20 },
 * { timestamp: "2020-08-01T02:00:00Z", "value": 20 },
 * { timestamp: "2020-08-01T03:00:00Z", "value": 20 }]
 */
const getSeriesForGivenTimeRange = (series, from, to) => {
    if (!series || !Array.isArray(series)) return [];

    return series.filter(seriesItem => {
        const currentHour = moment.utc(seriesItem.timestamp).format('H');
        const isHourInTimeRange = from <= currentHour && currentHour <= to;

        return isHourInTimeRange;
    });
};

/**
 * takes series and returns an array with objects, that hold a category (current day) and an accumulated value (power that was charged on that day within the timerange given)
 * execution i.e.:
 * getSeriesWithCategory(getSeriesForGivenTimeRange(series, 0, 3)) -> getSeriesForGivenTimeRange(series, 0, 3) extracts all datapoints from series, where the timerange is matched
 * and getSeriesWithCategory() accumulates the values for that timerange on that specific day, for instance 2020-08-01 (1. August) and 2020-08-02 (2. August)
 * @param {array} series
 * structure:
 * [{ timestamp: "2020-08-01T00:00:00Z", "value": 20 },
 * { timestamp: "2020-08-01T01:00:00Z", "value": 20 },
 * { timestamp: "2020-08-01T02:00:00Z", "value": 20 },
 * { timestamp: "2020-08-01T03:00:00Z", "value": 20 },
 * { timestamp: "2020-08-02T00:00:00Z", "value": 30 },
 * { timestamp: "2020-08-02T01:00:00Z", "value": 20 },
 * { timestamp: "2020-08-02T02:00:00Z", "value": 20 },
 * { timestamp: "2020-08-02T03:00:00Z", "value": 20 }]
 * @returns {array}
 * structure:
 * [{ "category": "1. August", "value": 80 },
 * { "category": "2. August", "value": 90 }]
 */
const getSeriesWithCategory = series =>
    series.reduce((transformedSeries, currentSeries) => {
        const date = moment.utc(currentSeries.timestamp).format('D. MMM');
        const newTransformedSeries = transformedSeries;

        if (
            newTransformedSeries[newTransformedSeries.length - 1] &&
            newTransformedSeries[newTransformedSeries.length - 1].category ===
                date
        ) {
            newTransformedSeries[newTransformedSeries.length - 1].value +=
                currentSeries.value;

            return newTransformedSeries;
        }

        return [
            ...newTransformedSeries,
            {
                category: date,
                value: currentSeries.value,
            },
        ];
    }, []);

const Heatmap = ({ plots, settings, unit, additionalSettings }) => {
    const intl = useIntl();
    const chartSettings = _merge(
        settings,
        getHeatmapSettings(unit),
        additionalSettings,
    );

    const getHeatmapPlot = () => {
        const plot = plots[0];
        return [
            {
                name: '0-3',
                data: getSeriesWithCategory(
                    getSeriesForGivenTimeRange(plot.data, 0, 3),
                ),
                color: plot.color,
            },
            {
                name: '4-7',
                data: getSeriesWithCategory(
                    getSeriesForGivenTimeRange(plot.data, 4, 7),
                ),
                color: plot.color,
            },
            {
                name: '8-11',
                data: getSeriesWithCategory(
                    getSeriesForGivenTimeRange(plot.data, 8, 11),
                ),
                color: plot.color,
            },
            {
                name: '12-15',
                data: getSeriesWithCategory(
                    getSeriesForGivenTimeRange(plot.data, 12, 15),
                ),
                color: plot.color,
            },
            {
                name: '16-19',
                data: getSeriesWithCategory(
                    getSeriesForGivenTimeRange(plot.data, 16, 19),
                ),
                color: plot.color,
            },
            {
                name: '20-23',
                data: getSeriesWithCategory(
                    getSeriesForGivenTimeRange(plot.data, 20, 23),
                ),
                color: plot.color,
            },
        ];
    };

    const getSeries = () => {
        return getHeatmapPlot().map(plot => ({
            color: plot.color,
            name: `${plot.name} ${intl.formatMessage({
                id: 'qmPortal.general.clock',
            })}`,
            data: formatHeatmapData(plot.data),
        }));
    };

    const formatHeatmapData = plotData => {
        if (!plotData || !Array.isArray(plotData)) return [];
        return plotData.map(data => ({
            x: data.category,
            y: data.value,
        }));
    };

    return (
        <ApexChart
            series={getSeries()}
            options={chartSettings}
            height="100%"
            type="heatmap"
        />
    );
};

Heatmap.propTypes = {
    plots: propTypes.array,
    settings: propTypes.object,
    additionalSettings: propTypes.object,
    unit: propTypes.string,
};

export default Heatmap;
