import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    valueBox: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        minHeight: 30,
        color: theme.palette.wistaBlue,
    },
    value: {
        fontWeight: 'bold',
        fontSize: '2.5rem',
    },
}));
