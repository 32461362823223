import React from 'react';
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

import propTypes from 'prop-types';

import availableAssetTypes from '../../../../../availableAssetTypes';

const AssetTypeStep = ({ onChange, stepState, step }) => {
    const intl = useIntl();
    const handleChange = event => {
        onChange(event.target.value, step);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <FormControl>
                    <InputLabel id="assettype-label">
                        {' '}
                        {intl.formatMessage({
                            id: 'assetManagement.addAsset.assetType',
                        })}
                    </InputLabel>
                    <Select
                        data-testid="test-selectAssetType"
                        labelId="assettype-label"
                        value={stepState}
                        onChange={handleChange}
                    >
                        <MenuItem
                            data-testid="test-itemChargingPointType"
                            value={availableAssetTypes.chargingPoint}
                        >
                            {intl.formatMessage({
                                id: 'asset.tab.overview.chargingPoint.type',
                            })}
                        </MenuItem>
                        <MenuItem
                            data-testid="test-itemPVType"
                            value={availableAssetTypes.pv}
                        >
                            {intl.formatMessage({
                                id: 'assetManagement.type.photovoltaik',
                            })}
                        </MenuItem>
                        <MenuItem
                            data-testid="test-itemBHKWType"
                            value={availableAssetTypes.bhkw}
                        >
                            {intl.formatMessage({
                                id: 'assetManagement.type.chp',
                            })}
                        </MenuItem>
                        <MenuItem
                            data-testid="test-itemHeatPumpType"
                            value={availableAssetTypes.heatPump}
                        >
                            {intl.formatMessage({
                                id: 'assetManagement.type.cop',
                            })}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

AssetTypeStep.propTypes = {
    onChange: propTypes.func,
    step: propTypes.number,
    stepState: propTypes.string,
};

export default AssetTypeStep;
