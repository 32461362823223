import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { Grid, Card, CardHeader } from '@material-ui/core';
import { useIntl } from 'react-intl';

import Map from '../../../map/Map';
import DefaultBox from '../../../infoBox/DefaultBox';
import AppContext from '../../../../appContext';

import getAssetDataByProperty from '../../../../helper/getAssetDataByProperty/getAssetDataByProperty';

const TabBaseData = ({ baseData }) => {
    const context = useContext(AppContext)[0];
    const intl = useIntl();
    const facilityInfoData = getAssetDataByProperty(
        baseData.facilityInfo,
        context.asset,
    );
    const locationData = getAssetDataByProperty(
        baseData.location,
        context.asset,
    );

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <Card>
                    <CardHeader
                        title={intl.formatMessage({
                            id: 'asset.tab.baseData.title',
                        })}
                    />
                    {facilityInfoData.map(row => (
                        <DefaultBox
                            key={row.property}
                            value={row.value}
                            unit={row.unit ? row.unit : ''}
                            title={row.title}
                            valueId={row.valueId}
                        />
                    ))}
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card>
                    <CardHeader
                        title={intl.formatMessage({
                            id: 'asset.tab.baseData.location',
                        })}
                    />
                    {locationData.map(row => {
                        return (
                            <DefaultBox
                                valueId={row.valueId}
                                key={row.property}
                                value={row.value}
                                unit={row.unit ? row.unit : ''}
                                title={row.title}
                            />
                        );
                    })}

                    {context.asset.longitude && context.asset.latitude && (
                        <div className="mapouter">
                            <Map
                                height="40vh"
                                data={[
                                    {
                                        assetName: context.asset.name,
                                        latitude: context.asset.latitude,
                                        longitude: context.asset.longitude,
                                    },
                                ]}
                            />
                        </div>
                    )}
                </Card>
            </Grid>
        </Grid>
    );
};

TabBaseData.propTypes = {
    baseData: propTypes.object.isRequired,
};

export default TabBaseData;
