import React from 'react';
import propTypes from 'prop-types';

import tableHeader from './data/tableHeader';
import Table from './Table';

const WhitelabelTable = ({ data, type }) => {
    const getTableHeadlines = () => {
        const headlinesObj = tableHeader.find(header => header.type === type);

        if (!headlinesObj) return [];

        return headlinesObj.headlines;
    };

    const tableData = {
        head: getTableHeadlines(),
        body: data,
    };

    return <Table data={tableData} />;
};

WhitelabelTable.propTypes = {
    data: propTypes.array,
    type: propTypes.string,
};

export default WhitelabelTable;
