import React from 'react';
import { Menu as MenuIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

function MobileMenuButton(props) {
    return (
        <IconButton {...props}>
            <MenuIcon />
        </IconButton>
    );
}

export default MobileMenuButton;
