import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';

import SimulationInputs from './components/Inputs';
import { Snackbar, ChargingPlansWithCharts } from '../../components';
import Loading from '../../components/visuals/loading';

import styles from './styles';
import bffService from '../../services/bff';

const Simulation = () => {
    const [chargingPlans, setChargingPlans] = useState({
        plans: null,
        loading: false,
    });
    const [assetId, setAssetId] = useState('');
    const [stateSnackbar, setStateSnackbar] = React.useState({
        isOpen: false,
        error: false,
        messageId: '',
    });

    const handleClose = () => {
        setStateSnackbar({ ...stateSnackbar, isOpen: false });
    };

    /**
     * Saves selected charging plan by calling the Mission Control-Service
     * @param assetId ID of chargingpoint
     * @param plan Charging plan
     * @param internalPrice Internal price
     * @param externalPrice External price
     * @param totalPrice Total price
     * @returns {Promise<void>}
     */
    const saveChargingPlan = async (
        plan,
        internalPrice,
        externalPrice,
        totalPrice,
    ) => {
        const { error } = await bffService.saveChargingPlan(
            assetId,
            plan,
            internalPrice,
            externalPrice,
            totalPrice,
        );

        if (error) {
            if (error.statusCode === 400) {
                setStateSnackbar({
                    ...stateSnackbar,
                    isOpen: true,
                    error: true,
                    messageId:
                        'simulation.snackbar.error.requestedChargingPointUnavailable',
                });
            } else {
                setStateSnackbar({
                    ...stateSnackbar,
                    isOpen: true,
                    error: true,
                    messageId: 'generic.error',
                });
            }
        } else {
            setStateSnackbar({
                ...stateSnackbar,
                error: false,
                isOpen: true,
                messageId: 'simulation.snackbar.success',
            });
        }
    };

    const getChargingPlans = async params => {
        setChargingPlans({
            ...chargingPlans,
            loading: true,
        });
        const { response: data, error } = await bffService.getChargingPlans(
            params,
        );

        if (error) {
            setStateSnackbar({
                ...stateSnackbar,
                isOpen: true,
                error: true,
                messageId: 'generic.error',
            });
            setChargingPlans({
                plans: null,
                loading: false,
            });
        } else {
            setChargingPlans({
                plans: data,
                loading: false,
            });
        }
    };

    const getAssetIdChargingPointFromInput = assetIdChargingPoint => {
        setAssetId(assetIdChargingPoint);
    };

    return (
        <React.Fragment>
            <SimulationInputs
                onChargingPlansRequested={getChargingPlans}
                getAssetIdChargingPoint={getAssetIdChargingPointFromInput}
            />
            {chargingPlans.plans &&
                chargingPlans.plans.length &&
                chargingPlans.plans.map((plan, index) => (
                    <div key={index}>
                        <ChargingPlansWithCharts
                            plans={plan}
                            key={index}
                            index={index}
                            assetIdChargingPoint={assetId}
                            onSavePlan={saveChargingPlan}
                        />
                    </div>
                ))}
            {chargingPlans.loading && (
                <Grid container justify="center" component="div">
                    <Grid item>
                        <Loading />
                    </Grid>
                </Grid>
            )}
            <Snackbar
                open={stateSnackbar.isOpen}
                onClose={handleClose}
                variant={stateSnackbar.error ? 'error' : 'success'}
                messageId={stateSnackbar.messageId}
            />
        </React.Fragment>
    );
};

Simulation.propTypes = {
    classes: propTypes.object.isRequired,
};

export default withStyles(styles)(Simulation);
