import React from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';
import { green } from '@material-ui/core/colors';

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '95%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    success: { backgroundColor: green[600], variant: 'filled' },
    error: { backgroundColor: theme.palette.error.dark, variant: 'filled' },
    icon: { alignItems: 'center' }
}));

const SnackbarComponent = ({
    messageId,
    onClose,
    open,
    variant,
}) => {
    const classes = useStyles();
    return (
        <Snackbar
            className={classes.root}
            open={open}
            onClose={onClose}
            autoHideDuration={variant === 'success' ? 5000 : 10000}
            role="dialog"
            data-testid="test-snackBar"
        >
            <Alert
                onClose={onClose}
                severity={variant}
                classes={{
                    filledSuccess: classes.success,
                    filledError: classes.error,
                    icon: classes.icon,
                }}
            >
                {Array.isArray(messageId) ?
                    (messageId.map((message, index) => (<><FormattedMessage id={message} key={index}/><br /></>)))
                    : <FormattedMessage id={messageId}/>}
            </Alert>
        </Snackbar>
    );
};

SnackbarComponent.propTypes = {
    messageId: propTypes.oneOfType([propTypes.string, propTypes.array]).isRequired,
    onClose: propTypes.func.isRequired,
    open: propTypes.bool.isRequired,
    variant: propTypes.string.isRequired,
};

export default SnackbarComponent;
