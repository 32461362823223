import React from 'react';
import propTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';

const CancelButton = ({ inModal, onClickCancelButton, handleReset }) => {
    const intl = useIntl();
    return (
        <Box css={{ textAlign: 'right' }}>
            <Button
                data-testid="test-abortAddAsset"
                onClick={() => {
                    if (inModal) onClickCancelButton();
                    handleReset();
                }}
            >
                {intl.formatMessage({
                    id: 'assetManagement.button.cancel',
                })}
            </Button>
        </Box>
    );
};

CancelButton.propTypes = {
    inModal: propTypes.bool,
    onClickCancelButton: propTypes.func,
    handleReset: propTypes.func,
};

export default CancelButton;
