import React from 'react';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import 'react-datepicker/dist/react-datepicker.css';
import TodayIcon from '@material-ui/icons/Today';

import styles from '../styles';

registerLocale('de', de);

const filterOptions = [
    {
        messageId: 'components.charts.filter.option.today',
        value: "TODAY",
    },
    {
        messageId: 'components.charts.filter.option.day',
        value: "DAY",
    },
    {
        messageId: 'components.charts.filter.option.week',
        value: "WEEK",
    },
    {
        messageId: 'components.charts.filter.option.month',
        value: "MONTH",
    },
];

const DateFilter = ({
    onDateFilterChange,
    activeFilterOption,
    onDateSelect,
    date,
}) => {
    const classes = styles();
    const intl = useIntl();

    const onChange = dateParam => {
        onDateSelect(dateParam);
    };

    const getDatePicker = () => {
        let datePicker;
        const datePickerSettings = {
            locale: 'de',
            selected: date || new Date(),
            onChange,
            maxDate: new Date(),
            disabledKeyboardNavigation: true,
        };

        switch (activeFilterOption) {
            case "TODAY":
                datePicker = null;
                break;
            case "DAY":
                datePicker = (
                    <DatePicker
                        {...datePickerSettings}
                        dateFormat="dd. MMM. yyyy"
                    />
                );
                break;
            case "WEEK":
                datePicker = (
                    <DatePicker
                        {...datePickerSettings}
                        showWeekNumbers
                        calendarClassName="calendar-weeks"
                        dateFormat="w yyyy"
                    />
                );
                break;
            case "MONTH":
                datePicker = (
                    <DatePicker
                        {...datePickerSettings}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showTwoColumnMonthYearPicker
                    />
                );
                break;
            default:
                datePicker = null;
        }

        return datePicker;
    };

    return (
        <div>
            <ul className={classes.dateFilter}>
                {filterOptions.map(filterOption => {
                    return (
                        <li
                            key={filterOption.messageId}
                            className={classes.dateFilterItem}
                        >
                            <button
                                className={clsx(
                                    classes.dateFilterButton,
                                    activeFilterOption === filterOption.value &&
                                        classes.dateFilterButtonActive,
                                )}
                                onClick={() =>
                                    onDateFilterChange(filterOption.value)
                                }
                            >
                                {intl.formatMessage({
                                    id: filterOption.messageId,
                                })}
                            </button>
                        </li>
                    );
                })}
                <li className={classes.datePicker}>
                    {getDatePicker() && (
                        <>
                            {getDatePicker()}
                            <TodayIcon className={classes.calendarIcon} />
                        </>
                    )}
                </li>
            </ul>
        </div>
    );
};

DateFilter.propTypes = {
    activeFilterOption: propTypes.string,
    onDateFilterChange: propTypes.func,
    onDateSelect: propTypes.func,
    date: propTypes.object,
};

export default DateFilter;
