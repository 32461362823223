import tinycolor from 'tinycolor2';

const lightenRate = 7.5;
const darkenRate = 15;
const primaryFallback = '#4784A0';
const secondaryFallback = '#ED7680';

const getTheme = configTheme => ({
    spacing: 8,
    typography: {
        h1: {
            fontSize: '3rem',
        },
        h2: {
            fontSize: '2rem',
        },
        h3: {
            fontSize: '1.64rem',
        },
        h4: {
            fontSize: '1.5rem',
        },
        h5: {
            fontSize: '1.285rem',
        },
        h6: {
            fontSize: '1.142rem',
        },
    },
    palette: {
        primary: {
            main: configTheme.primary || primaryFallback,
            light: tinycolor(configTheme.primary || primaryFallback)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(configTheme.primary || primaryFallback)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: configTheme.secondary || secondaryFallback,
            light: tinycolor(configTheme.secondary || secondaryFallback)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(configTheme.secondary || secondaryFallback)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#FFFFFF',
        },
        text: {
            primary: configTheme.text || '#000000',
            secondary: '#6E6E6E',
            hint: '#B9B9B9',
        },
        headerBackground: configTheme.headerBackground || configTheme.main,
        headerBackgroundStop: configTheme.headerBackgroundStop || configTheme.headerBackground,
        headerText: configTheme.headerText || '#000000',
        background: {
            default: '#FFFFFF',
            light: '#FFFFFF',
        },
        error: {
            main: '#ff4b4b',
        },
        chartCrosshair: '#ff5959',
        chartForecastGradient: '#c2c2c2',
        white: '#FFFFFF',
        black: '#000000',
        houseConnection: '#02d483',
        kpiHeaderGradientStart: configTheme.kpiHeaderGradientStart || '#FFFFFF',
        kpiHeaderGradientStop: configTheme.kpiHeaderGradientStop || '#FFFFFF',
        kpiHeaderText: configTheme.kpiHeaderText,
        wistaBlue: '#32A6CB',
        wistaGreen: '#92C65F',
    },
    weatherWidget: {
        accent: '',
        textcolor: '#393939',
        highcolor: '#ED7680',
        lowcolor: '#4785A0',
        mooncolor: '#393939',
        cloudcolor: '#dddddd',
        cloudfill: '#dddddd',
        raincolor: '#4785A0',
        snowcolor: '#dddddd',
    },
    customShadows: {
        widget:
            '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetDark:
            '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetWide:
            '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    },
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: '#4A4A4A1A',
            },
        },
        MuiTreeItem: {
            iconContainer: {
                position: 'absolute',
                right: 4,
                top: 14,
            },
            group: {
                marginLeft: 0,
            },
            content: {
                borderRadius: '3px',
                backgroundColor: '#ffffff !important',
                '&:hover': {
                    backgroundColor: '#ededed !important',
                },
            },
        },
        MuiMenu: {
            paper: {
                boxShadow:
                    '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
            },
        },
        MuiSelect: {
            icon: {
                color: '#B9B9B9',
            },
        },
        MuiListItem: {
            button: {
                '&:hover': {
                    backgroundColor: tinycolor(configTheme.primary)
                        .lighten(30)
                        .toHexString(),
                },
            },
            root: {
                '&$selected': {
                    backgroundColor: '#F3F5FF !important',
                    '&:focus': {
                        backgroundColor: configTheme.secondary,
                    },
                },
            },
        },
        MuiTouchRipple: {
            child: {
                backgroundColor: 'white',
            },
        },
        MuiTableRow: {
            root: {
                height: 56,
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid rgba(224, 224, 224, .5)',
            },
            head: {
                fontSize: '0.95rem',
            },
            body: {
                fontSize: '0.95rem',
            },
        },
        MuiTextField: {
            root: {
                width: '100%',
            },
        },
        MuiFormControl: {
            root: {
                width: '100%',
            },
        },
    },
});

export default getTheme;
