import { useTheme } from '@material-ui/core/styles';
import 'leaflet/dist/leaflet.css';
import propTypes from 'prop-types';
import React from 'react';
import { MapContainer, Polygon, TileLayer, useMap } from 'react-leaflet';
import CustomMarker from './components/CustomMarker/CustomMarker';
import Isochrones from './components/Isochrones/Isochrones';
import TrafficTiles from './components/TrafficTiles/TrafficTiles';

// component is only needed to receive actual map ref and to use leaflet methods
const MapRef = ({ clickedMarker }) => {
    const map = useMap();
    let marker;
    map.eachLayer(layer => {
        if (layer.options && layer.options.id === clickedMarker) marker = layer;
    });

    if (marker && clickedMarker) {
        marker.openPopup();
    }

    return null;
};

const LeafletMap = ({
    data,
    height,
    clickedMarker,
    polygon = [],
    disablePopup = false,
    isIsochrones = false,
    isochronesLat,
    isochronesLong,
    isTrafficTiles = false,
    zoom = 15,
}) => {
    const isQuarterPolygon = polygon.length >= 3;
    const markerData = data;
    const theme = useTheme();

    const getMarker = () => {
        return markerData.map(marker => ({
            name: marker.assetName,
            position: [marker.latitude, marker.longitude],
        }));
    };

    const getCenterPosition = coordinates => {
        const centerLat =
            coordinates.reduce((acc, current) => {
                return acc + current.latitude;
            }, 0) / coordinates.length;

        const centerLong =
            coordinates.reduce((acc, current) => {
                return acc + current.longitude;
            }, 0) / coordinates.length;

        return [centerLat, centerLong];
    };

    // if position of polygon is known, use it to set bounds on the map, otherwise use marker contained by the data property
    const getQuartyPolygonCoordinates = coordinates =>
        coordinates.map(polygonCoordinate => [
            polygonCoordinate.latitude,
            polygonCoordinate.longitude,
        ]);

    // if isochrones use them as center, otherwise use marker positions
    const mapCenter =
        isochronesLat && isochronesLong
            ? [isochronesLat, isochronesLong]
            : getCenterPosition(markerData);

    return (
        <MapContainer
            center={mapCenter}
            zoom={zoom}
            style={{ minHeight: height }}
            preferCanvas
        >
            {markerData && markerData.length && (
                <>
                    <MapRef clickedMarker={clickedMarker} />
                    <CustomMarker
                        data={getMarker()}
                        disablePopup={disablePopup}
                    />
                </>
            )}
            {isQuarterPolygon && (
                <Polygon
                    positions={getQuartyPolygonCoordinates(polygon)}
                    pathOptions={{ color: theme.palette.primary.main }}
                />
            )}
            {isIsochrones && (
                <Isochrones lat={isochronesLat} long={isochronesLong} />
            )}
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {isTrafficTiles && <TrafficTiles />}
        </MapContainer>
    );
};

LeafletMap.propTypes = {
    data: propTypes.any,
    height: propTypes.number,
    isochronesLat: propTypes.number,
    isochronesLong: propTypes.number,
    disablePopup: propTypes.bool,
    clickedMarker: propTypes.string,
    polygon: propTypes.array,
    isIsochrones: propTypes.bool,
    isTrafficTiles: propTypes.bool,
    zoom: propTypes.number,
};

export default LeafletMap;
