import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import { dimensions as widgetDimensions } from '../../widget';
import { Widget } from '../..';
import useStyles from '../styles';

function TableLoading() {
    const classes = useStyles();
    return (
        <Widget {...widgetDimensions.huge}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Skeleton width={'100%'} height={'100%'} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Skeleton width={'100%'} height={'100%'} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </Widget>
    );
}

export default TableLoading;
