import sensorImage from './images/sensor.png';

export default {
    baseData: {
        location: [
            {
                title: 'asset.tab.baseData.street',
                property: 'street',
            },
            {
                title: 'asset.tab.baseData.postalCodeCity',
                property: 'postalCodeCity',
            },
            {
                title: 'asset.tab.baseData.installationPlace',
                property: 'installationPlace',
            },
            {
                title: 'asset.tab.baseData.longitude',
                property: 'longitude',
            },
            {
                title: 'asset.tab.baseData.latitude',
                property: 'latitude',
            },
        ],
        facilityInfo: [
            {
                property: 'installationDate',
                title: 'asset.tab.baseData.installationDate',
            },
        ],
    },
    overview: {
        media: {
            url:
                sensorImage,
            title: 'Sensors system',
        },
        info: [
            {
                property: 'deviceType',
                title: 'asset.tab.overview.type',
                valueId: 'assetManagement.type.sensor',
            },
            {
                property: 'manufacturer',
                title: 'asset.tab.overview.manufacturer',
            },
            {
                property: 'manufacturerDescriptionType',
                title: 'asset.tab.overview.sensor.unitModel',
            },
        ],
    },
    performance: [
        {
            withBrushChart: true,
            additionalSettings: {
                yaxis: [
                    {
                        type: 'numeric',
                        title: {
                            text: 'µg/m3',
                        },
                        decimalsInFloat: 0,
                        forceNiceScale: true,
                        seriesName: 'NO2',
                    },
                    {
                        seriesName: 'NO2',
                        show: false,
                        decimalsInFloat: 0,
                        forceNiceScale: true,
                    },
                    {
                        seriesName: 'NO2',
                        show: false,
                        decimalsInFloat: 0,
                        forceNiceScale: true,
                    },
                    {
                        type: 'numeric',
                        decimalsInFloat: 0,
                        forceNiceScale: true,
                        opposite: true,
                        title: {
                            text: 'mg/m3',
                        },
                        seriesName: 'CO',
                    },
                ],
            },
            plots: [
                {
                    id: 'NOx-Sensor',
                    name: 'NO2',
                    title: 'sensors.widgets.no2.title',
                    color: '#F25C78',
                    type: 'line',
                },
                {
                    id: 'PM10-Sensor',
                    name: 'PM10',
                    title: 'sensors.widgets.pm10.title',
                    color: '#9612E9',
                    type: 'line',
                },
                {
                    id: 'Ozon-Sensor',
                    name: 'Temperature',
                    title: 'sensors.widgets.o3.title',
                    color: '#654321',
                    type: 'line',
                },
                {
                    id: 'CO-Sensor',
                    name: 'CO',
                    title: 'sensors.widgets.co.title',
                    color: '#79E6F1',
                    type: 'line',
                },
            ],
        },
        {
            chartType: 'heatmap',
            plotUnit: 'Zählungen',
            plots: [
                {
                    id: 'Persons',
                    color: '#0a57ff',
                },
            ],
        },
        {
            chartType: 'heatmap',
            plotUnit: 'Zählungen',
            plots: [
                {
                    id: 'PaxCounter',
                    color: '#0a57ff',
                },
            ],
        },
        {
            chartType: 'heatmap',
            plotUnit: 'Zählungen',
            plots: [
                {
                    id: 'vehicles',
                    color: '#0a57ff',
                },
            ],
        },
        {
            withBrushChart: true,
            plots: [
                {
                    id: 'PowerMeter',
                    title: 'Power Meter',
                    color: '#F25C78',
                    type: 'line',
                }
            ],
        }
    ],
};
