export default (series, labels) => ({
    series,
    labels,
    markers: {
        size: 5,
    },
    dataLabels: {
        enabled: true,
        background: {
            enabled: true,
            borderRadius: 2,
        },
    },
    tooltip: {
        enabled: false,
    },
    legend: {
        showForSingleSeries: true,
    },
    onItemClick: {
        toggleDataSeries: false,
    },
    onItemHover: {
        highlightDataSeries: false,
    },
});
