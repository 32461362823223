import React from 'react';
import propTypes from 'prop-types';
import { Typography, Paper, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import useStyles from './styles';
import { getDepartureFromChargingPlan } from '../../helper';

const ChargingPlans = ({ chargingPlans, onChargingPlanClick }) => {
    const classes = useStyles();

    const getCheapestChargingPlan = () => {
        const chargingPlanCopy = [...chargingPlans];
        const sortedChargingPlan = chargingPlanCopy.sort(
            (a, b) => parseFloat(a.totalPrice) - parseFloat(b.totalPrice),
        );

        return sortedChargingPlan[0];
    };

    const getFastestChargingPlan = () => {
        const chargingPlanCopy = [...chargingPlans];
        const sortedChargingPlan = chargingPlanCopy.sort(
            (a, b) =>
                parseFloat(Object.keys(a.chargingPlan).length) -
                parseFloat(Object.keys(b.chargingPlan).length),
        );

        return sortedChargingPlan[0];
    };

    const getSortedChargingPlans = () => {
        if (!chargingPlans || chargingPlans.length === 0) {
            return [];
        }
        const chargingPlansCopy = [...chargingPlans];
        const fastestPlan = getFastestChargingPlan(chargingPlansCopy);
        const cheapestPlan = getCheapestChargingPlan(chargingPlansCopy);
        const newChargingPlans = [
            cheapestPlan,
            fastestPlan,
            ...getFilteredChargingPlans(
                chargingPlansCopy,
                fastestPlan,
                cheapestPlan,
            ),
        ];

        return newChargingPlans;
    };

    const getFilteredChargingPlans = (
        chargingPlansCopy,
        fastestPlan,
        cheapestPlan,
    ) => {
        return chargingPlansCopy.filter(
            item => item !== fastestPlan && item !== cheapestPlan,
        );
    };

    const sortedChargingPlans = getSortedChargingPlans(chargingPlans);
    const fastestPlanClassName = clsx(classes.fastestCharging, classes.root);
    const bestPricePlanClassName = clsx(classes.bestPrice, classes.root);

    const getClassNames = index => {
        let className = '';

        switch (index) {
            case 0:
                className = bestPricePlanClassName;
                break;
            case 1:
                className = fastestPlanClassName;
                break;
            default:
                className = classes.root;
        }

        return className;
    };

    return (
        sortedChargingPlans.length > 0 &&
        sortedChargingPlans.map((plan, index) => (
            <Paper
                role="button"
                key={index}
                className={getClassNames(index)}
                onClick={() =>
                    onChargingPlanClick({
                        ...plan,
                        eta: getDepartureFromChargingPlan(plan.chargingPlan),
                    })
                }
            >
                {index === 0 && (
                    <Typography component="p">
                        <FormattedMessage id="ueApp.mainPage.cheapestPlan.title" />
                    </Typography>
                )}
                {index === 1 && (
                    <Typography component="p">
                        <FormattedMessage id="ueApp.mainPage.fastestPlan.title" />
                    </Typography>
                )}
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={8}>
                        <Typography variant="h6" component="h3">
                            <FormattedMessage
                                id="ueApp.mainPage.chargingPlans.time"
                                values={{
                                    time: getDepartureFromChargingPlan(
                                        plan.chargingPlan,
                                    ),
                                }}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" component="h3">
                            {`${plan.totalPrice.toLocaleString('de-DE')} €`}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        ))
    );
};

ChargingPlans.propTypes = {
    chargingPlans: propTypes.array.isRequired,
    onChargingPlanClick: propTypes.func,
};

export default ChargingPlans;
