import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Grid, Button, Input, TextField, MenuItem } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { dimensions as widgetDimensions } from '../../components/widget';
import { Snackbar, Widget } from '../../components';
import bffService from '../../services/bff';
import useContext from '../../hooks/useContext';
import getActiveLanguage from '../../helper/getActiveLanguage/getActiveLanguage';

const Settings = ({ hasPriceSettings = true }) => {
    const intl = useIntl();
    const context = useContext()[0];
    const [themeInputs, setThemeInputs] = useState([
        {
            key: 'logo',
            value: context.quarterSettings.logo,
            label: 'settings.theme.logo',
        },
        {
            key: 'primary',
            value: context.quarterSettings.primary,
            label: 'settings.theme.primary',
        },
        {
            key: 'secondary',
            value: context.quarterSettings.secondary,
            label: 'settings.theme.secondary',
        },
        {
            key: 'text',
            value: context.quarterSettings.text,
            label: 'settings.theme.text',
        },
        {
            key: 'headerBackground',
            value: context.quarterSettings.headerBackground,
            label: 'settings.theme.headerBackground',
        },
        {
            key: 'headerBackgroundStop',
            value: context.quarterSettings.headerBackgroundStop,
            label: 'settings.theme.headerBackgroundStop',
        },
        {
            key: 'headerText',
            value: context.quarterSettings.headerText,
            label: 'settings.theme.headerText',
        },
    ]);
    const [priceInputs, setPriceInputs] = useState([
        {
            key: 'internalPrice',
            value: context.quarterSettings.internalPrice,
            label: 'settings.price.internal.title',
            unit: 'settings.cent.title',
        },
        {
            key: 'externalPrice',
            value: context.quarterSettings.externalPrice,
            label: 'settings.price.external.title',
            unit: 'settings.cent.title',
        },
        {
            key: 'margin',
            value: context.quarterSettings.margin,
            label: 'settings.marge.title',
            unit: 'settings.marge.unit',
        },
    ]);

    const [snackbar, setSnackbar] = useState({
        open: false,
        error: false,
        messageId: '',
    });

    const handleThemeInput = (event, key) => {
        const themeInputsCopy = [...themeInputs];
        const changedInput = themeInputsCopy.find(
            themeInput => key === themeInput.key,
        );
        changedInput.value = event.target.value;

        setThemeInputs(themeInputsCopy);
    };

    const handlePriceInput = (event, key) => {
        const priceInputsCopy = [...priceInputs];
        const changedInput = priceInputsCopy.find(
            priceInput => key === priceInput.key,
        );
        changedInput.value = event.target.value;

        setPriceInputs(priceInputsCopy);
    };

    const getThemeSettings = () => {
        const themeInputsCopy = [...themeInputs];

        const themeSettings = themeInputsCopy.reduce((accumulator, current) => {
            return { ...accumulator, [current.key]: current.value };
        }, {});

        return themeSettings;
    };

    const onSaveQuarterSettings = new CustomEvent('save-theme');

    const saveThemeSettings = async () => {
        const themeSettings = getThemeSettings();
        const isValidTheme = validateTheme();

        if (!isValidTheme) {
            return;
        }

        const { error } = await bffService.saveQuarterSettings(themeSettings);

        if (error) {
            setSnackbar({
                open: true,
                error: true,
                messageId: 'settings.theme.save_theme.error',
            });
        } else {
            setSnackbar({
                open: true,
                error: false,
                messageId: 'settings.theme.saved_successfully',
            });
            window.dispatchEvent(onSaveQuarterSettings);
        }
    };

    const getPrices = () => {
        const priceInputsCopy = [...priceInputs];

        const prices = priceInputsCopy.reduce((accumulator, current) => {
            return { ...accumulator, [current.key]: current.value };
        }, {});

        return prices;
    };

    const savePrices = async () => {
        const settings = getPrices();

        if (!validateInputs()) {
            return;
        }

        const { error } = await bffService.saveQuarterSettings(settings);

        if (error) {
            setSnackbar({
                open: true,
                error: true,
                messageId: 'settings.price.save_prices.error',
            });
        } else {
            setSnackbar({
                open: true,
                error: false,
                messageId: 'settings.price.saved_successfully',
            });
            window.dispatchEvent(onSaveQuarterSettings);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar({
            ...snackbar,
            open: false,
        });
    };

    // only validates theme if it is not null or empty ("")
    // a color is null if nothing is saved in it
    // a color is empty ("") if the user decides to erase an existent color
    const validateTheme = () => {
        const hexPattern = /^#[A-Fa-f0-9]{6}/;
        const hexInputs = themeInputs.filter(input => input.key !== 'logo');
        const isValidHexInputs = hexInputs.every(hexInput => {
            if (hexInput.value) {
                return hexPattern.test(hexInput.value);
            }
            return true;
        });

        if (!isValidHexInputs) {
            setSnackbar({
                open: true,
                error: true,
                messageId: 'settings.snackbar.error_theme',
            });
        }

        return isValidHexInputs;
    };

    const validateInputs = () => {
        const priceInternal = priceInputs.filter(
            input => input.key === 'internalPrice',
        )[0];
        const priceExternal = priceInputs.filter(
            input => input.key === 'externalPrice',
        )[0];
        const margin = priceInputs.filter(input => input.key === 'margin')[0];

        const priceExternalValue = parseInt(priceExternal.value, 10);
        const priceInternalValue = parseInt(priceInternal.value, 10);
        const marginValue = parseInt(margin.value, 10);

        const errorArray = [];

        if (priceExternalValue <= 0 || priceInternalValue <= 0) {
            errorArray.push('settings.snackbar.error_price');
        }
        if (marginValue < 0) {
            errorArray.push('settings.snackbar.error_margin');
        }
        if (priceExternalValue <= priceInternalValue) {
            errorArray.push('settings.snackbar.invalid_price');
        }

        if (errorArray.length > 0) {
            setSnackbar({
                open: true,
                error: true,
                messageId: errorArray,
            });
            return false;
        }
        return true;
    };

    const handleLanguageSwitch = event => {
        window.localStorage.setItem('selectedLanguage', event.target.value);
        window.location.reload();
    };

    return (
        <React.Fragment>
            <Grid container spacing={3} alignItems="flex-start">
                {hasPriceSettings && <Widget
                    title={
                        <FormattedMessage id="settings.widget_price.title" />
                    }
                    {...widgetDimensions.large}
                    key="price-settings"
                    style={{ maxWidth: 340, marginBottom: 16 }}
                >
                    <Grid container spacing={3}>
                        {priceInputs.map(input => (
                            <Grid item container key={input.key} xs={12}>
                                <Grid item xs={3}>
                                    <FormattedMessage id={input.label} />:
                                </Grid>
                                <Grid item xs={5}>
                                    <Input
                                        inputProps={{
                                            'data-testid': `test-${input.key}`,
                                        }}
                                        style={{ width: '100%' }}
                                        value={input.value}
                                        onChange={event =>
                                            handlePriceInput(event, input.key)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormattedMessage id={input.unit} />
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container item xs={12}>
                            <Grid item xs={3}>
                                <Button
                                    data-testid="test-saveSettings"
                                    style={{ marginTop: 16 }}
                                    onClick={savePrices}
                                    color="primary"
                                    variant="contained"
                                >
                                    {intl.formatMessage({
                                        id: 'settings.widget.button.save.title',
                                    })}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Widget>}
                <Widget
                    title={
                        <FormattedMessage id="settings.widget_theme.title" />
                    }
                    {...widgetDimensions.large}
                    key="theme-settings"
                    style={{ maxWidth: 340 }}
                >
                    <Grid container spacing={3}>
                        {themeInputs.map(input => (
                            <Grid item container key={input.key} xs={12}>
                                <Grid item xs={6}>
                                    <FormattedMessage id={input.label} />:
                                </Grid>
                                <Grid item xs={input.key === 'logo' ? 12 : 6}>
                                    <Input
                                        inputProps={{
                                            'aria-label': 'description',
                                            'data-testid': `test-${input.key}`,
                                        }}
                                        style={{ width: '100%' }}
                                        value={input.value}
                                        onChange={event =>
                                            handleThemeInput(event, input.key)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container item xs={12}>
                            <Grid item xs={3}>
                                <Button
                                    data-testid="test-saveTheme"
                                    style={{ marginTop: 16 }}
                                    onClick={saveThemeSettings}
                                    color="primary"
                                    variant="contained"
                                >
                                    {intl.formatMessage({
                                        id: 'settings.widget.button.save.title',
                                    })}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Widget>
                <Widget
                    title={
                        <FormattedMessage id="settings.widget_language.title" />
                    }
                    {...widgetDimensions.large}
                    style={{ maxWidth: 340 }}
                >
                    <Grid container spacing={3}>
                        <Grid item container xs={12}>
                            <TextField
                                id="language-select"
                                select
                                defaultValue={getActiveLanguage()}
                                onChange={handleLanguageSwitch}
                            >
                                <MenuItem key="de" value="de">
                                    {intl.formatMessage({
                                        id: 'settings.language.german',
                                    })}
                                </MenuItem>
                                <MenuItem key="en" value="en">
                                    {intl.formatMessage({
                                        id: 'settings.language.english',
                                    })}
                                </MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </Widget>
                <Snackbar
                    open={snackbar.open}
                    onClose={handleSnackbarClose}
                    messageId={snackbar.messageId}
                    variant={snackbar.error ? 'error' : 'success'}
                />
            </Grid>
        </React.Fragment>
    );
};

Settings.propTypes = {
    hasPriceSettings: propTypes.bool,
};

export default Settings;
