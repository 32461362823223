import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    link: {
        cursor: 'pointer',
        color: theme.palette.text.primary,
        fontSize: '13px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    active: {
        color: theme.palette.primary.main,
    },
}));

export default styles;
