import React from 'react';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@material-ui/core';

const FinishedProcess = ({ handleReset, onClickCancelButton, inModal }) => {
    const intl = useIntl();
    return (
        <>
            <Typography data-testid="test-addAssetStatus" paragraph>
                {intl.formatMessage({
                    id: 'assetManagement.addAsset.successful',
                })}
            </Typography>

            <Button
                data-testid="test-addAdditionalAsset"
                onClick={handleReset}
                color="primary"
                variant="contained"
            >
                {intl.formatMessage({
                    id: 'assetManagement.button.addAdditionalAsset',
                })}
            </Button>

            {inModal && (
                <Button
                    data-testid="test-abortAddAsset"
                    onClick={onClickCancelButton}
                >
                    {intl.formatMessage({
                        id: 'assetManagement.button.closeModal',
                    })}
                </Button>
            )}
        </>
    );
};

FinishedProcess.propTypes = {
    handleReset: propTypes.func,
    onClickCancelButton: propTypes.func,
    inModal: propTypes.bool,
};

export default FinishedProcess;
