import moment from 'moment';
import { merge as _merge } from 'lodash';

const getGradient = (theme = {}, now = new Date().getTime()) => ({
    position: 'back',
    xaxis: [
        {
            x: now,
            x2: now + 50000,
            fillColor: theme.palette.chartCrosshair,
            borderColor: theme.palette.chartCrosshair,
            strokeDashArray: 0,
            opacity: 1,
            offsetX: -1,
            label: {
                borderWidth: 0,
                orientation: 'horizontal',
                text: moment(now).format('HH:mm'),
                style: {
                    fontWeight: 600,
                    fontSize: '12px',
                    background: theme.palette.chartCrosshair,
                    color: theme.palette.white,
                },
            },
        },
        {
            x: now + 1000000000000, // now + milliseconds
            x2: now,
            fillColor: theme.palette.chartForecastGradient,
            opacity: 0.2,
        },
    ],
});

/**
 * basic chart configuration for apexcharts
 * docs: https://apexcharts.com/docs/installation/
 */
export const getChartSettings = (
    additionalSettings = {},
    chartId = 'chart',
    theme = {},
    withGradient,
    now = new Date().getTime(),
) => {
    const chartSettings = {
        chart: {
            id: chartId,
            animations: {
                enabled: false,
            },
            fontFamily: 'Roboto, Helvetica',
            toolbar: {
                show: false,
                tools: {
                    zoom: false,
                },
            },
        },
        annotations: withGradient ? getGradient(theme, now) : {},
        legend: {
            position: 'bottom',
            markers: {
                width: 14,
                height: 2,
                radius: 0,
            },
            itemMargin: {
                horizontal: 6,
                vertical: 12,
            },
            offsetY: 14,
        },
    };

    return _merge(chartSettings, additionalSettings);
};

export const getBrushSettings = (
    additionalBrushChartSettings = {},
    now = new Date().getTime(),
    unit,
) => {
    const brushSettings = {
        chart: {
            id: 'brushChart',
            animations: {
                enabled: false,
            },
            brush: {
                target: 'chart',
                enabled: true,
            },
            selection: {
                enabled: true,
                xaxis: {
                    /**
                     * set selection of the brush chart
                     * min: now minus one day in milliseconds
                     * max: now
                     */
                    min: now - 24 * 60 * 60 * 1000,
                    max: now,
                },
            },
        },
        legend: {
            show: false,
        },
        yaxis: {
            tickAmount: 2,
            type: 'numeric',
            title: {
                text: unit,
            },
            decimalsInFloat: 0,
            forceNiceScale: true,
            min: 0,
            labels: {
                formatter: value => {
                    return Math.round(value);
                },
            },
        },
        xaxis: {
            type: 'datetime',
            tooltip: {
                enabled: false,
            },
            labels: {
                datetimeUTC: false,
            },
        },
    };

    return _merge(brushSettings, additionalBrushChartSettings);
};
