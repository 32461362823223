import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';

import StepProcess from './components/StepProcess/StepProcess';
import { ModalComponent } from '../../../../components';

const AddAsset = () => {
    const intl = useIntl();
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Box mt={3} css={{ textAlign: 'right' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenModal(true)}
                    data-testid="test-addAsset"
                >
                    {intl.formatMessage({
                        id: 'assetManagement.button.addAsset',
                    })}
                </Button>
            </Box>
            <ModalComponent
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <StepProcess
                    onClickCancelButton={() => setOpenModal(false)}
                    inModal
                />
            </ModalComponent>
        </>
    );
};

export default AddAsset;
