/* eslint-disable import/prefer-default-export */
import axios from 'axios';

import { REACT_APP_MOCKS } from '../../../../resources/env';
import com2mService from '../../../../services/com2m/com2mService';

const MOCKS_CONFIG = {
    baseURL: '/__mocks__',
};

const BFF_CONFIG = {
    headers: {},
    validateStatus: status => status < 500,
};

const bffInstance = axios.create(REACT_APP_MOCKS ? MOCKS_CONFIG : BFF_CONFIG);
let token = '';

if (!REACT_APP_MOCKS) {
    bffInstance.interceptors.request.use(
        async config => {
            const newConfig = { ...config };
            token = token || com2mService.getAccessToken();

            if (token) {
                newConfig.headers.Authorization = `Bearer ${token}`;
            }

            return newConfig;
        },
        error => {
            // Do something with request error
            return Promise.reject(error);
        },
    );
}

export default bffInstance;
