import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Typography,
} from '@material-ui/core';

import bffService from '../../../../../../services/bff';
import useStyles from './styles';
import steps from '../../data/addAssetSteps';

// components
import StepNavigation from '../StepNavigation/StepNavigation';
import CancelButton from '../CancelButton/CancelButton';
import FinishedProcess from '../FinishedProcess/FinishedProcess';

const StepProcess = ({ onClickCancelButton, inModal = false }) => {
    const classes = useStyles();
    const intl = useIntl();
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState(false);
    /**
     * stepValues[0] = asset type
     * stepValues[1] = core data
     * stepValues[2] = connection type
     */
    const [stepValues, setStepValues] = useState(['', {}, '']);

    const handleNext = () => {
        // check if last step
        if (steps.length - 1 === activeStep) {
            addAsset();
        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const onStepValueChange = (state, stepIndex) => {
        setStepValues(prevStepValues => {
            const newStepValues = [...prevStepValues];
            newStepValues[stepIndex] = state;
            if (stepIndex === 0) {
                newStepValues[1].deviceType = state;
            }

            return newStepValues;
        });
    };

    const addAsset = async () => {
        const { error: serviceError } = await bffService.addAsset(
            stepValues[1],
        );

        if (serviceError) {
            setError(true);
        } else {
            setError(false);
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };

    return (
        <>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map(
                    ({ labelTranslationId, ContentComponent }, index) => (
                        <Step key={labelTranslationId}>
                            <StepLabel>
                                {intl.formatMessage({
                                    id: labelTranslationId,
                                })}
                            </StepLabel>
                            <StepContent>
                                {ContentComponent &&
                                    React.cloneElement(<ContentComponent />, {
                                        onChange: onStepValueChange,
                                        stepState: stepValues[index],
                                        addAssetState: stepValues,
                                        step: index,
                                    })}
                                <StepNavigation
                                    handleNext={handleNext}
                                    handleBack={handleBack}
                                    steps={steps}
                                    activeStep={activeStep}
                                />
                            </StepContent>
                        </Step>
                    ),
                )}
            </Stepper>
            <div className={classes.stepBottom}>
                {error && (
                    <Typography
                        data-testid="test-addAssetStatus"
                        paragraph
                        color="error"
                    >
                        {intl.formatMessage({
                            id: 'assetManagement.addAsset.error',
                        })}
                    </Typography>
                )}
                {activeStep === steps.length ? (
                    <FinishedProcess
                        onClickCancelButton={onClickCancelButton}
                        handleReset={handleReset}
                        inModal={inModal}
                    />
                ) : (
                    <CancelButton
                        onClickCancelButton={onClickCancelButton}
                        handleReset={handleReset}
                        inModal={inModal}
                    />
                )}
            </div>
        </>
    );
};

StepProcess.propTypes = {
    inModal: propTypes.bool,
    onClickCancelButton(props, propName) {
        if (
            props.inModal === true &&
            (props[propName] === undefined ||
                typeof props[propName] !== 'function')
        ) {
            return new Error(
                'Please provide a onClickCancelButton function, that closes the modal!',
            );
        }
        return null;
    },
};

export default StepProcess;
