import AssetTypeStep from '../components/Steps/AssetTypeStep/AssetTypeStep';
import CoreDataStep from '../components/Steps/CoreDataStep/CoreDataStep';
import ConnectionTypeStep from '../components/Steps/ConnectionTypeStep/ConnectionTypeStep';

const addAssetSteps = [
    {
        labelTranslationId: 'assetManagement.addAsset.assetType',
        ContentComponent: AssetTypeStep,
    },
    {
        labelTranslationId: 'assetManagement.tab.baseData',
        ContentComponent: CoreDataStep,
    },
    {
        labelTranslationId: 'assetManagement.addAsset.connectionType',
        ContentComponent: ConnectionTypeStep,
    },
    {
        labelTranslationId: 'assetManagement.addAsset.finish',
        ContentComponent: '',
    },
];

export default addAssetSteps;
