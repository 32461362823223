import React from 'react';
import propTypes from 'prop-types';

import AirQualityBox from './AirQualityBox';

const WhitelabelAirQualityBox = ({ title, data, size }) => {
    return (
        <AirQualityBox
            title={title}
            value={data && data.airQualityIndex}
            size={size}
        />
    );
};

WhitelabelAirQualityBox.propTypes = {
    title: propTypes.string.isRequired,
    data: propTypes.object,
    size: propTypes.string,
};

export default WhitelabelAirQualityBox;
