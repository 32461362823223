import React from 'react';
import { useHistory } from 'react-router-dom';

import propTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';

import useStyles from './styles';
import { tableValueParser } from '../../helper';
import Widget, { dimensions as widgetDimensions } from '../widget';

// used from material ui
function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

// used from material ui
function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

// used from material ui
function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

function TableComponent({ data }) {
    const classes = useStyles();
    const history = useHistory();
    const [order, setOrder] = React.useState('asc');
    const firstHeadlineKey = data.head[0].key;
    // objectKeys of the table data are used to sort the table, e.g. name, type...
    const [orderBy, setOrderBy] = React.useState(firstHeadlineKey);

    const handleRequestSort = objectKey => _event => {
        const isDesc = orderBy === objectKey && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(objectKey);
    };

    /**
     * sort the table body, so that every head-cell has its appropriate body-cell value
     * and add an id, that does not get rendered, but is used as redirection
     */
    const tableBody = () => {
        if (!data.body || !data.head) return [];

        return data.body.map(row => {
            const sortedRow = data.head.reduce(
                (rowEntries, currentTableHeadEntry) => {
                    return {
                        ...rowEntries,
                        [currentTableHeadEntry.key]: tableValueParser(
                            currentTableHeadEntry.key,
                        )(row[currentTableHeadEntry.key]),
                    };
                },
                {},
            );

            /**
             * add the id to enable redirecting to the asset detail page by clicking on the table row
             * id won't be rendered as a table cell
             */
            sortedRow.id = row.id;

            return sortedRow;
        });
    };

    const redirectToAssetDetailPage = id => {
        if (!id) return;
        history.push(`/assets/${id}`);
    };

    return (
        <Widget {...widgetDimensions.huge}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            {data.head.map(headCell => (
                                <TableCell
                                    key={headCell.key}
                                    sortDirection={
                                        orderBy === headCell.value
                                            ? order
                                            : false
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.value}
                                        direction={order}
                                        onClick={handleRequestSort(
                                            headCell.key,
                                        )}
                                    >
                                        {headCell.value}
                                        <span
                                            className={classes.visuallyHidden}
                                        >
                                            {order === 'desc'
                                                ? 'sorted descending'
                                                : 'sorted ascending'}
                                        </span>
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(
                            tableBody(),
                            getSorting(order, orderBy),
                        ).map((row, rowIndex) => (
                            <TableRow
                                hover
                                key={rowIndex}
                                tabIndex={-1}
                                onClick={() =>
                                    redirectToAssetDetailPage(row.id)
                                }
                                style={{ cursor: 'pointer' }}
                            >
                                {Object.entries(row).map(([key, value]) => {
                                    if (key === 'id') return null;

                                    return (
                                        <TableCell key={key} component="td">
                                            {value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Widget>
    );
}

TableComponent.propTypes = {
    data: propTypes.object,
};

export default TableComponent;
