import moment from 'moment';

// refactor to iterate over data instead of assetProperties
export default (data, assetProperties) => {
    return data.map(item => {
        const mappedItem = { ...item };

        if (
            mappedItem.property === 'powerMaxKw' &&
            assetProperties.deviceType === 'CHARGING_POINT'
        ) {
            mappedItem.value = `${assetProperties.phaseType}, ${
                assetProperties[mappedItem.property]
            }`;
        } else if (mappedItem.property === 'plugType') {
            mappedItem.value = `${assetProperties.plugCount} ${
                assetProperties[mappedItem.property]
            }`;
        } else if (mappedItem.property === 'installationDate' && assetProperties[mappedItem.property]) {
            mappedItem.value = moment(
                assetProperties[mappedItem.property],
            ).format('DD.MM.YYYY HH:mm');
        } else if (mappedItem.property === 'postalCodeCity') {
            const { postalCode } = assetProperties;
            const { city } = assetProperties;
            let postalCodeAndCity = '';

            if (postalCode && city) {
                postalCodeAndCity = `${postalCode} ${city}`;
            } else if (postalCode && !city) {
                postalCodeAndCity = postalCode;
            } else if (!postalCode && city) {
                postalCodeAndCity = city;
            }
            mappedItem.value = postalCodeAndCity;
        } else {
            mappedItem.value = assetProperties[mappedItem.property];
        }

        return mappedItem;
    });
};
