import React from 'react';
import propTypes from 'prop-types';
import { withRouter, BrowserRouter, Route } from 'react-router-dom';
import qs from 'qs';
import com2mService from '../../services/com2m/com2mService';
import { roles } from '../../services/com2m/serviceConstants';

const Login = ({ loginUrl, onLogin, children }) => {
    const isQuarterManager = com2mService.isQuarterManager();

    const isLoggedIn = () => {
        return com2mService.isLoggedIn();
    };

    const handleSuccess = async accessToken => {
        sessionStorage.setItem('iot.access_token', accessToken);
        const redirectUrl = window.location.pathname; // remove access token from url

        window.location.assign(redirectUrl);
    };

    const handleLogin = () => {
        const accessToken = qs.parse(window.location.hash, {
            ignoreQueryPrefix: true,
        })['#access_token'];

        if (accessToken) {
            handleSuccess(accessToken);
        } else {
            window.location.assign(loginUrl);
        }

        return null;
    };

    /**
     * for now, check if logged in user is quarter manager
     * if so, execute onLogin with role "quarterManager"
     * if not, execute onLogin with role "customer"
     */
    if (isLoggedIn()) {
        if (!isQuarterManager) {
            onLogin(roles.customer);
            return children;
        }

        onLogin(roles.quarterManager);
        return children;
    }

    return (
        <BrowserRouter>
            <Route path="/" render={handleLogin} />
        </BrowserRouter>
    );
};

Login.propTypes = {
    history: propTypes.object.isRequired,
    children: propTypes.node,
    onLogin: propTypes.func,
    loginUrl: propTypes.string,
};

export default withRouter(Login);
