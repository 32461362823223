import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    modalContent: {
        width: '95%',
        maxWidth: 600,
        maxHeight: 600,
        overflowY: 'auto',
    },
}));

export default styles;
