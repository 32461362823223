import React from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { dimensions as widgetDimensions } from '../widget';
import Widget from '../widget/Widget';
import ScaleComponent from "./components/scaleComponent/ScaleComponent";
import aqiDefinitions from "./aqiDefinitions";
import styles from './styles';

const AirQualityBox = ({
    title,
    value = 3,
    size = 'huge',
}) => {
    const classes = styles();
    const widgetSize = widgetDimensions[size];

    return (
        <Widget
            title={<FormattedMessage id={title} />}
            {...widgetSize}
            titleVariant="h5"
            centeredHeader
        >
            <div className={classes.wrapper}>
                <div className={classes.scaleComponent}>
                    {aqiDefinitions.map((aqiGrade, index) => {
                        return (
                            <ScaleComponent isActive={aqiGrade.value === value}
                                color={aqiGrade.color}
                                gradeAsText={aqiGrade.translationKey}
                                key={index}/>
                        )
                    })}
                </div>
            </div>
        </Widget>
    );
};

AirQualityBox.propTypes = {
    title: propTypes.string,
    value: propTypes.oneOfType([propTypes.number, propTypes.string]),
    size: propTypes.string,
};

export default AirQualityBox;
