const BASE_URL = `${window.location.protocol}//${window.location.host}`;

export const REACT_APP_COMPANY_TITLE =
    process.env.REACT_APP_COMPANY_TITLE || 'urban energy';
export const REACT_APP_AUTHORIZE_URL =
    process.env.REACT_APP_AUTHORIZE_URL || `${BASE_URL}/oauth/authorize`;
export const REACT_APP_TOKEN_URL =
    process.env.REACT_APP_TOKEN_URL || `${BASE_URL}/oauth/token`;
export const REACT_APP_LOGOUT_URL =
    process.env.REACT_APP_LOGOUT_URL || `${BASE_URL}/oauth/logout`;
export const REACT_APP_CLIENT_ID =
    process.env.REACT_APP_CLIENT_ID || 'urbanEnergy-qmPortal';
export const REACT_APP_UE_BACKEND =
    process.env.REACT_APP_UE_BACKEND || BASE_URL;
export const REACT_APP_MOCKS = process.env.REACT_APP_MOCKS || false;
