// Asset types
export const assetTypes = {
    quarter: 'Quarter',
    consumer: 'Consumer',
    producer: 'Producer',
    chargingPoint: 'ChargingPoint',
    chargingStation: 'ChargingStation',
    houseConnection: 'HouseConnection',
    pv: 'PV',
};

export const seriesTypes = [
    assetTypes.chargingPoint,
    assetTypes.consumer,
    assetTypes.producer,
    assetTypes.houseConnection,
];

export const dataPointTypes = {
    power: 'power',
    powerFuture: 'powerFuture',
};

export const dataPoints = [
    {
        name: dataPointTypes.power,
        namespace: 'default',
    },
    {
        name: dataPointTypes.powerFuture,
        namespace: 'forecast',
    },
];

export const roles = {
    quarterManager: 'quarterManager',
    customer: 'customer',
};
