/**
 * Checks whether the chart should have multicolored plots or not
 * If the first plot has a futureColor it is assumed, that every other plot has a futureColor too
 * @param plots Plots that should be plotted in the chart
 * @returns {string} "gradient" if the first plot has a futureColor and "solid" if not
 */
const isMultiColorEnabled = plots => {
    return plots.length > 0 && plots[0].futureColor ? 'gradient' : 'solid';
};

export default (unit, plots) => ({
    stroke: {
        width: 2,
    },
    grid: {
        xaxis: {
            lines: {
                show: true,
            },
        },
        yaxis: {
            lines: {
                show: true,
            },
        },
    },
    xaxis: {
        type: 'datetime',
        labels: {
            datetimeUTC: false,
        },
    },
    yaxis: {
        type: 'numeric',
        title: {
            text: unit,
        },
        decimalsInFloat: 0,
        forceNiceScale: true,
        min: 0,
    },
    tooltip: {
        x: {
            format: 'HH:mm',
        },
    },
    fill: {
        type: isMultiColorEnabled(plots),
        gradient: {
            colorStops: plots.map(plot => [
                {
                    offset: 0,
                    color: plot.color,
                    opacity: 1,
                },
                {
                    offset: 50,
                    color: plot.color,
                    opacity: 1,
                },
                {
                    offset: 53,
                    color: plot.futureColor,
                    opacity: 1,
                },
                {
                    offset: 100,
                    color: plot.futureColor,
                    opacity: 1,
                },
            ]),
        },
    },
});
