import React from 'react';
import { withStyles, Typography, Paper, Box, Grid } from '@material-ui/core';
import propTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles';

function Widget({
    classes,
    children,
    title = null,
    titleVariant = 'caption',
    shouldMarkErrorOrValid,
    hasError,
    centeredHeader,
    ...dimensions
}) {
    return (
        <Grid item {...dimensions}>
            <Paper
                className={
                    shouldMarkErrorOrValid
                        ? clsx(
                              classes.paper,
                              hasError ? classes.error : classes.valid,
                          )
                        : classes.paper
                }
                classes={{ root: classes.widgetRoot }}
                aria-label="widgetPaper"
            >
                {title && (
                    <Typography
                        variant={titleVariant}
                        className={`${
                            clsx(
                                classes.widgetHeader,
                                centeredHeader && classes.centeredWidgetHeader,
                            )
                        }`}
                    >
                        {title}
                    </Typography>
                )}
                <Box className={classes.widgetBody} aria-label="widgetBody">
                    {children}
                </Box>
            </Paper>
        </Grid>
    );
}

Widget.propTypes = {
    classes: propTypes.object,
    title: propTypes.any,
    titleVariant: propTypes.string,
    children: propTypes.any,
    shouldMarkErrorOrValid: propTypes.bool,
    hasError: propTypes.bool,
    centeredHeader: propTypes.bool,
};

export default withStyles(styles)(Widget);
