import React from 'react';
import ApexChart from 'react-apexcharts';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { merge as _merge } from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import getAirQualityRatio from "../../../../helper/getAirQualityRatio/getAirQualityRatio";

import getColoredLineChartSettings from './settings';
import styles from "../../styles";

const isMobile = window.innerWidth < 772;

const ColoredLine = ({
    plots,
    settings,
}) => {
    const theme = useTheme();
    const intl = useIntl();
    const classes = styles();

    const DAYS = [
        intl.formatMessage({ id: "sensors.charts.days.monday" }),
        intl.formatMessage({ id: "sensors.charts.days.tuesday" }),
        intl.formatMessage({ id: "sensors.charts.days.wednesday" }),
        intl.formatMessage({ id: "sensors.charts.days.thursday" }),
        intl.formatMessage({ id: "sensors.charts.days.friday" }),
        intl.formatMessage({ id: "sensors.charts.days.saturday" }),
        intl.formatMessage({ id: "sensors.charts.days.sunday" }),
    ];
    const GRADE = [
        intl.formatMessage({ id: "sensors.airQualityBox.aqi.veryGood" }).toUpperCase(),
        intl.formatMessage({ id: "sensors.airQualityBox.aqi.good" }).toUpperCase(),
        intl.formatMessage({ id: "sensors.airQualityBox.aqi.satisfying" }).toUpperCase(),
        intl.formatMessage({ id: "sensors.airQualityBox.aqi.deficient" }).toUpperCase(),
        intl.formatMessage({ id: "sensors.airQualityBox.aqi.bad" }).toUpperCase(),
        intl.formatMessage({ id: "sensors.airQualityBox.aqi.veryBad" }).toUpperCase(),
    ];

    const getDashSizes = () => {
        const dashSizes = [];
        plots.forEach(plot => {
            dashSizes.push(plot.dashSize);
        })
        return dashSizes;
    }

    /**
     * Returns aggregated values for a given data point
     * @param plotData Plotdata needed for the aggregation
     * @param dataPoint Datapoint the aggregation should be made for
     * @param index Index from the given datapoint
     * @param type Type from the plot needed to calculate the ratio after the aggregation
     * @returns {null|{x: number, y: number}} Aggregated and proportionalized datapoint
     */
    const getAggregatedValues = (plotData, dataPoint, index, type) => {
        const hours = new Date(dataPoint.timestamp).getHours();
        const RESOLUTION = 6;

        if (hours % RESOLUTION) {
            return null;
        }

        const dataPointValue =
            (plotData[index - 1]?.value +
                plotData[index - 2]?.value +
                plotData[index - 3]?.value +
                plotData[index - 4]?.value +
                plotData[index - 5]?.value +
                dataPoint.value) /
            RESOLUTION;

        return {
            x: new Date(dataPoint.timestamp).getTime(),
            y: dataPointValue ? getAirQualityRatio(dataPointValue, type) : getAirQualityRatio(dataPoint.value, type),
            // if average could not be calculated (e.g. not enough past values), use current dataPoint.value
        };
    };

    const formatPlotData = (plotData, type) => {
        if (!plotData || !Array.isArray(plotData)) return [];
        return plotData
            .map((data, index) => getAggregatedValues(plotData, data, index, type))
            .filter(dataPoint => dataPoint);
    };

    const chartAnnotations = formatPlotData(plots[0].data, 'OZONE')
        .map(dataPoint => {
            const currentTimestamp = new Date(dataPoint.x);
            const hours = currentTimestamp.getHours();
            const dayIndex = currentTimestamp.getUTCDay();

            if (hours !== 12 && hours !== 0) {
                return null;
            }
            if (isMobile && hours === 12) {
                return null;
            }

            return {
                x: dataPoint.x,
                x2: dataPoint.x + (hours === 12 ? 300000 : 700000),
                fillColor: theme.palette.white,
                borderColor: theme.palette.white,
                strokeDashArray: 0,
                opacity: hours === 12 ? 0.5 : 1,
                label: {
                    offsetY: -15,
                    text: hours === 12 ? `${hours}` : `${DAYS[dayIndex]}`,
                    textAnchor: 'middle',
                    position: 'top',
                    borderWidth: 0,
                    orientation: 'horizontal',
                    style: {
                        fontWeight: 'bold',
                        color: theme.palette.wistaBlue,
                        fontSize: '12px',
                        background: 'transparent',
                    },
                },
            };
        })
        .filter(dataPoint => dataPoint)
        .filter((dataPoint, index) => {
            if (!isMobile) return true;
            return !(index % 2);
        });

    chartAnnotations.pop(); // last annotation is not needed (design)

    const chartSettings = _merge(
        settings,
        getColoredLineChartSettings(
            plots[0]?.data?.length,
            theme.palette.wistaBlue,
            chartAnnotations,
            getDashSizes(),
            GRADE
        ),
    );

    const getSeries = () => {
        return plots.map(plot => ({
            color: plot.color,
            name: intl.formatMessage({ id: plot.title }),
            type: plot.type,
            data: formatPlotData(plot.data, plot.id),
        }));
    };

    return (
        <div  className={`${classes.coloredYAxis} ${classes.legend}`}>
            <ApexChart
                series={getSeries()}
                options={chartSettings}
                height='100%'
                type="line"
            />
        </div>
    );
};

ColoredLine.propTypes = {
    plots: propTypes.array,
    settings: propTypes.object,
};

export default ColoredLine;
