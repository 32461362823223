import React from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from '@material-ui/core';

const checkboxValue = boolean => (
    <Checkbox checked={boolean} aria-label="checkbox" />
);

const numberValue = number => (
    <NumberFormat
        value={number}
        displayType="text"
        decimalSeparator=","
        thousandSeparator="."
        decimalScale={2}
        fixedDecimalScale={true}
    />
);

const convertMinutesToMilliseconds = minutes => {
    return minutes * 60 * 1000;
};

const defaultParser = value => {
    if (!value) return '';

    switch (typeof value) {
        case 'number':
            return numberValue(value);
        case 'boolean':
            return checkboxValue(value);
        default:
            return value;
    }
};

const durationParser = duration => {
    const tempTime = moment.duration(convertMinutesToMilliseconds(duration));
    return `${tempTime.hours()} h ${tempTime.minutes()} Min.`;
};

const timestampParser = timestamp =>
    moment(timestamp).format('DD.MM.YYYY HH:mm');

const typeParser = type => {
    switch (type) {
        case 'PV':
            return <FormattedMessage id="assetManagement.type.photovoltaik" />;
        case 'CHARGING_POINT':
            return <FormattedMessage id="assetManagement.type.chargingPoint" />;
        case 'HEAT_PUMP':
            return <FormattedMessage id="assetManagement.type.cop" />;
        case 'SENSOR':
            return <FormattedMessage id="assetManagement.type.sensor" />;
        case 'BHKW':
            return <FormattedMessage id="assetManagement.type.chp" />;
        case 'BUILDING':
            return <FormattedMessage id="assetManagement.type.building" />;
        case 'HOUSE_CONNECTION':
            return <FormattedMessage id="assetManagement.type.houseConnection" />;
        case 'COMPRESSOR':
            return <FormattedMessage id="assetManagement.type.compressor" />;
        case 'COOLING_STORAGE':
            return <FormattedMessage id="assetManagement.type.coolingStorage" />;
        case 'HEATING_STORAGE':
            return <FormattedMessage id="assetManagement.type.heatingStorage" />;
        case 'BOILER':
            return <FormattedMessage id="assetManagement.type.boiler" />;
        case 'WIND_TURBINE':
            return <FormattedMessage id="assetManagement.type.windTurbine" />;
        default:
            return type;
    }
};

const categoryParser = category => {
    switch (category) {
        case 'COOLING_PRODUCER':
            return <FormattedMessage id="assets.category.producer" />;
        case 'COOLING_CONSUMER':
            return <FormattedMessage id="assets.category.consumer" />;
        case 'COOLING_STORAGE':
            return <FormattedMessage id="assets.category.storage" />;
        case 'HEATING_PRODUCER':
            return <FormattedMessage id="assets.category.producer" />;
        case 'HEATING_CONSUMER':
            return <FormattedMessage id="assets.category.consumer" />;
        case 'HEATING_STORAGE':
            return <FormattedMessage id="assets.category.storage" />;
        default:
            return category;
    }
};

const statusParser = status => {
    if (!status) return '';

    switch (status.status) {
        case 'ONLINE':
            return <FormattedMessage id="assets.status.online" />;
        case 'OFFLINE':
            return <FormattedMessage id="assets.status.offline" />;
        case 'UNKNOWN':
            return <FormattedMessage id="assets.status.unknown" />;
        default:
            return status.status;
    }
};

export default type => {
    const parsers = {
        duration: durationParser,
        timestamp: timestampParser,
        type: typeParser,
        status: statusParser,
        category: categoryParser,
        default: defaultParser,
    };

    return parsers[type] || parsers.default;
};
