import { drawerWidth, drawerClosedWidth } from '../styles';

export default theme => ({
    menuButton: {
        marginLeft: 12,
        marginRight: 12,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: drawerClosedWidth,
        [theme.breakpoints.down('sm')]: {
            width: drawerWidth,
        },
    },
    grow: {
        flexGrow: 1,
    },
    sidebarList: {
        '& .sidebar-list-icon': {
            minWidth: 40,
            maxWidth: 40,
            color: theme.palette.text.primary,
        },
    },
    sidebarListLink: {
        color: 'inherit',
        textDecoration: 'none',
    },
    sidebarListLinkActive: {
        '& .sidebar-list-icon': {
            color: theme.palette.secondary.main,
        },
    },
    mobileBackButton: {
        marginTop: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5),
        display: 'block',
        textAlign: 'left',
        [theme.breakpoints.only('sm')]: {},
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
});
