import { Grid, List, ListItem, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Snackbar } from '../../components';
import Map from '../../components/map/Map';
import Widget, { dimensions } from '../../components/widget';
import useContext from '../../hooks/useContext';
import bffService from '../../services/bff';
import styles from './styles';

const MapPage = () => {
    const context = useContext()[0];
    const [clickedMarker, setClickedMarker] = useState(null);
    const [assets, setAssets] = useState([]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        messageId: '',
    });
    const classes = styles();
    const intl = useIntl();

    useEffect(() => {
        const fetchSettings = async () => {
            const { response, error } = await bffService.getAssets();
            if (error) {
                setSnackbar({
                    open: true,
                    messageId: 'assetManagement.snackbar.error',
                });
            } else {
                setAssets(response);
            }
        };

        fetchSettings();
    }, []);

    const getMarkerData = () => {
        return assets
            .filter(asset => asset.longitude && asset.latitude)
            .map(asset => ({
                assetName: asset.name,
                latitude: asset.latitude,
                longitude: asset.longitude,
            }));
    };

    const getAssetsWithoutPosition = () =>
        assets.filter(asset => !asset.longitude || !asset.latitude);

    const handleSnackbarClose = () => {
        setSnackbar({
            ...snackbar,
            open: false,
        });
    };

    const onAssetClick = assetName => {
        setClickedMarker(assetName);
    };

    const sortAssetsByName = (assetA, assetB) =>
        assetA.assetName.localeCompare(assetB.assetName);

    const getLocationData = () =>
        context.quarterSettings.location &&
        context.quarterSettings.location.coordinates;

    return (
        <>
            {assets.length >= 1 && (
                <Grid container spacing={3} alignItems="flex-start">
                    <Grid item container sm spacing={3} direction="column">
                        <Widget {...dimensions.huge}>
                            <List className={classes.list}>
                                {getMarkerData()
                                    .sort(sortAssetsByName)
                                    .map(asset => (
                                        <ListItem
                                            button
                                            component="a"
                                            key={asset.assetName}
                                            onClick={() =>
                                                onAssetClick(asset.assetName)
                                            }
                                        >
                                            <Typography>
                                                {asset.assetName}
                                            </Typography>
                                        </ListItem>
                                    ))}
                            </List>
                        </Widget>
                        {getAssetsWithoutPosition().length >= 1 && (
                            <Grid item>
                                <Typography variant="h5">
                                    {intl.formatMessage({
                                        id: 'map.asset_list.without_position',
                                    })}
                                </Typography>
                                <List className={classes.list}>
                                    {getAssetsWithoutPosition().map(asset => (
                                        <ListItem
                                            key={asset.name}
                                            onClick={() =>
                                                onAssetClick(asset.name)
                                            }
                                        >
                                            <Typography>
                                                {asset.name}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        )}
                    </Grid>
                    <Widget {...dimensions.large}>
                        <Map
                            data={getMarkerData()}
                            height={600}
                            zoom={15}
                            clickedMarker={clickedMarker}
                            polygon={getLocationData()}
                        />
                    </Widget>
                </Grid>
            )}
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackbarClose}
                messageId={snackbar.messageId}
                variant="error"
            />
        </>
    );
};

export default MapPage;
