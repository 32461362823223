import React, { useContext, useState, useEffect } from 'react';
import {
    Grid,
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    Box,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import propTypes from 'prop-types';

import bffService from '../../../../services/bff';
import WhitelabelTable from '../../../table/WhitelabelTable';
import DefaultBox from '../../../infoBox/DefaultBox';
import AppContext from '../../../../appContext';

import getAssetDataByProperty from '../../../../helper/getAssetDataByProperty/getAssetDataByProperty';

const TabOverview = ({ overview }) => {
    const context = useContext(AppContext)[0];
    const intl = useIntl();
    const [chargingPointDetails, setChargingPointDetails] = useState();
    const overviewData = getAssetDataByProperty(overview.info, context.asset);

    useEffect(() => {
        const fetchAssetSeries = async () => {
            const {
                response,
                error,
            } = await bffService.getChargingStationDetails(context.asset.id);

            if (error) return;
            setChargingPointDetails(response);
        };
        if (context.asset.deviceType === 'CHARGING_POINT') {
            fetchAssetSeries();
        }
    }, [context.asset.id, context.asset.deviceType]);

    const isMessages = () => {
        return (
            context.asset.messages &&
            Array.isArray(context.asset.messages) &&
            context.asset.messages.length > 0
        );
    };

    const isChargingPointDetails = () => {
        return (
            chargingPointDetails &&
            Array.isArray(chargingPointDetails) &&
            chargingPointDetails.length > 0
        );
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <Card>
                    <CardHeader title={context.asset.name} />
                    {overview.media && <CardMedia
                        style={{
                            height: 0,
                            paddingTop: '56.25%',
                        }}
                        image={overview.media.url}
                        title={overview.media.title}
                    />}
                    {overviewData.map(row => (
                        <DefaultBox
                            key={row.title}
                            value={row.value}
                            unit={row.unit ? row.unit : ''}
                            title={row.title}
                            valueId={row.valueId}
                        />
                    ))}
                </Card>
            </Grid>
            <Grid item xs={12} md={8}>
                <Card>
                    <CardHeader
                        title={intl.formatMessage({
                            id: 'asset.tab.overview.pv.events',
                        })}
                    />
                    <CardContent>
                        {isMessages() ? (
                            <WhitelabelTable
                                data={context.asset.messages}
                                type="asset"
                            />
                        ) : (
                            intl.formatMessage({
                                id: 'asset.tab.overview.noMessages',
                            })
                        )}
                    </CardContent>
                </Card>
                {context.asset.deviceType === 'CHARGING_POINT' && (
                    <Box mt={3}>
                        <Card>
                            <CardHeader
                                title={intl.formatMessage({
                                    id:
                                        'asset.tab.overview.chargingPoint.chargingSessions',
                                })}
                            />
                            <CardContent>
                                {isChargingPointDetails() ? (
                                    <WhitelabelTable
                                        data={chargingPointDetails}
                                        type="chargingStation"
                                    />
                                ) : (
                                    intl.formatMessage({
                                        id:
                                            'asset.tab.overview.chargingPoint.noChargingSessions',
                                    })
                                )}
                            </CardContent>
                        </Card>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

TabOverview.propTypes = {
    overview: propTypes.object.isRequired,
};

export default TabOverview;
