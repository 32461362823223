import React from 'react';
import ApexChart from 'react-apexcharts';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { merge as _merge } from 'lodash';

import getRadarChartSettings from './settings';

const Radar = ({ plots, settings }) => {
    const intl = useIntl();

    const getSeries = () => {
        return plots.map(plot => ({
            name: plot.title,
            color: plot.color,
            data: getRadarData(plot.data),
        }));
    };

    const getRadarData = plotData => {
        return plotData.map(data => data.value);
    };

    const getLabels = () => {
        if (!plots[0].data || !Array.isArray(plots[0].data)) return [];
        return plots[0].data.map(plot =>
            intl.formatMessage({ id: plot.label }),
        );
    };

    const chartSettings = _merge(
        settings,
        getRadarChartSettings(getSeries(), getLabels()),
    );

    return (
        <ApexChart
            series={getSeries()}
            options={chartSettings}
            height="100%"
            type="radar"
        />
    );
};

Radar.propTypes = {
    plots: propTypes.array,
    settings: propTypes.object,
    unit: propTypes.string,
};

export default Radar;
