import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Polygon } from 'react-leaflet';
import bffService from '../../../../services/bff';
import CustomMarker from '../CustomMarker/CustomMarker';

const Isochrones = ({ lat, long }) => {
    const [isochrones, setIsochrones] = useState(null);

    const markerData = [
        {
            name: "Adlershof",
            position: [lat, long],
        },
    ];
    useEffect(() => {
        const fetchIsochrones = async () => {
            const now = new Date().toISOString();
            const { response } = await bffService.getIsochrones(lat, long, now);

            if (response) {
                const { positions } = response;
                setIsochrones(positions);
            }
        };

        fetchIsochrones();
    }, [lat, long]);

    return (
        <>
            <CustomMarker data={markerData} disablePopup={false} />
            {isochrones &&
                isochrones.map(isochrone => {
                    const keys = Object.keys(isochrone);
                    const isFifteenMinutes = keys.find(
                        key => key === 'fifteen_minutes',
                    );
                    const isThirtyMinutes = keys.find(
                        key => key === 'thirty_minutes',
                    );
                    const isSixtyMinutes = keys.find(
                        key => key === 'sixty_minutes',
                    );
                    const pathOptions = {
                        color: '#333333',
                        weight: 0.5,
                        fillOpacity: 0.2,
                    };

                    if (isFifteenMinutes) {
                        return (
                            <Polygon
                                positions={isochrone.fifteen_minutes}
                                pathOptions={{
                                    ...pathOptions,
                                    fillColor: '#F46C43',
                                }}
                            />
                        );
                    }

                    if (isThirtyMinutes) {
                        return (
                            <Polygon
                                positions={isochrone.thirty_minutes}
                                pathOptions={{
                                    ...pathOptions,
                                    fillColor: '#66BD63',
                                }}
                            />
                        );
                    }

                    if (isSixtyMinutes) {
                        return (
                            <Polygon
                                positions={isochrone.sixty_minutes}
                                pathOptions={{
                                    ...pathOptions,
                                    fillColor: '#FDE08A',
                                }}
                            />
                        );
                    }

                    return null;
                })}
        </>
    );
};

Isochrones.propTypes = {
    lat: propTypes.number,
    long: propTypes.number,
};

export default Isochrones;
