import React from 'react';
import {
    Box,
    Drawer,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    withStyles,
} from '@material-ui/core';
import * as MaterialIcons from '@material-ui/icons';
import clsx from 'clsx';
import propTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ReactComponent as ProducerIcon } from './assets/ProducerIcon.svg';
import styles from './styles';

function Sidebar({
    classes,
    isSidebarOpened,
    isSidebarOpenedMobile,
    isMobile,
    toggleSidebarMobile,
    toggleSidebar,
    pages,
}) {
    const intl = useIntl();
    const isPermanent = !isMobile;

    // settings
    const settings = pages.find(page => page.path === '/settings');
    const SettingsIcon = settings && MaterialIcons[settings.navIcon];
    const settingsNavLink = settings && (
        <NavLink
            to={settings.path}
            className={classes.sidebarListLink}
            onClick={() => isMobile && toggleSidebarMobile()}
            activeClassName={classes.sidebarListLinkActive}
        >
            <MenuItem>
                <ListItemIcon className={'sidebar-list-icon'}>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText data-testid="test-openSettings">
                    {intl.formatMessage({
                        id: settings.title,
                    })}
                </ListItemText>
            </MenuItem>
        </NavLink>
    );

    return (
        <Drawer
            variant={isPermanent ? 'permanent' : 'temporary'}
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: isSidebarOpened,
                    [classes.drawerClose]: !isSidebarOpened,
                }),
            }}
            open={isSidebarOpenedMobile}
            onClose={toggleSidebarMobile}
        >
            <div className={classes.toolbar} />
            {isMobile && (
                <MenuList
                    className={clsx(
                        classes.sidebarList,
                        classes.mobileBackButton,
                    )}
                >
                    <MenuItem onClick={toggleSidebarMobile}>
                        <ListItemIcon className={'sidebar-list-icon'}>
                            <MaterialIcons.ChevronLeft />
                        </ListItemIcon>
                        <ListItemText />
                    </MenuItem>
                </MenuList>
            )}
            <Box pt={isMobile ? 0 : 10}>
                <MenuList className={classes.sidebarList}>
                    {pages.map(({ path, title, navIcon, pageName }) => {
                        const Icon =
                            pageName === 'Producer'
                                ? ProducerIcon
                                : MaterialIcons[navIcon];

                        if (path !== '/settings') {
                            return (
                                <NavLink
                                    to={path}
                                    key={path}
                                    className={classes.sidebarListLink}
                                    onClick={() =>
                                        isMobile && toggleSidebarMobile()
                                    }
                                    activeClassName={
                                        classes.sidebarListLinkActive
                                    }
                                >
                                    <MenuItem>
                                        <ListItemIcon
                                            className={'sidebar-list-icon'}
                                        >
                                            <Icon />
                                        </ListItemIcon>
                                        <ListItemText
                                            data-testid={`test-open${pageName}`}
                                            aria-hidden={
                                                isSidebarOpened
                                                    ? 'false'
                                                    : 'true'
                                            }
                                        >
                                            {intl.formatMessage({
                                                id: title,
                                            })}
                                        </ListItemText>
                                    </MenuItem>
                                </NavLink>
                            );
                        }
                        return null;
                    })}
                    {isMobile && settingsNavLink}
                </MenuList>
            </Box>

            <div className={classes.grow} />

            {!isMobile && (
                <MenuList className={classes.sidebarList}>
                    {settingsNavLink}
                    <MenuItem onClick={toggleSidebar}>
                        <ListItemText primary="" />
                        <ListItemIcon className={'sidebar-list-icon'}>
                            {isSidebarOpened ? (
                                <MaterialIcons.ChevronLeft />
                            ) : (
                                <MaterialIcons.ChevronRight />
                            )}
                        </ListItemIcon>
                    </MenuItem>
                </MenuList>
            )}
        </Drawer>
    );
}

Sidebar.propTypes = {
    pages: propTypes.array.isRequired,
    classes: propTypes.object,
    isSidebarOpened: propTypes.bool,
    isSidebarOpenedMobile: propTypes.bool,
    isMobile: propTypes.bool,
    toggleSidebar: propTypes.func,
    toggleSidebarMobile: propTypes.func,
};

export default withStyles(styles)(Sidebar);
