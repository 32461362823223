import React from 'react';
import ApexChart from 'react-apexcharts';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { merge as _merge } from 'lodash';

import getDonutChartSettings from './settings';

const Donut = ({ plots, settings }) => {
    const intl = useIntl();

    const getSeries = () => {
        return plots.map(plot => plot.data);
    };

    const getLabels = () => {
        return plots.map(plot => intl.formatMessage({ id: plot.title }));
    };

    const chartSettings = _merge(
        settings,
        getDonutChartSettings(getSeries(), getLabels()),
    );

    return (
        <ApexChart
            series={getSeries()}
            options={chartSettings}
            height="100%"
            type="donut"
        />
    );
};

Donut.propTypes = {
    plots: propTypes.array,
    settings: propTypes.object,
    unit: propTypes.string,
};

export default Donut;
