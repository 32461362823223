import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root: {
        flex: '1 1 0',
        flexDirection: 'column',
        position: "relative",
    },
    valueMarker: {
        position: 'absolute',
        color: theme.palette.wistaBlue,
        top: -6,
        transform: 'translateX(-50%) scale(2.5, 5)',
        left: '50%',
        boxSizing: 'border-box',
        margin: 'auto',
        width: '100%',
    },
    text: {
        color: theme.palette.wistaBlue,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        position: 'relative',
        top: '30px',
        textTransform: 'uppercase',
    },
}));
