import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';

const BhkwCoreData = ({ stepState }) => {
    const intl = useIntl();

    return (
        <>
            <Grid item md={6}>
                <TextField
                    id="name"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.name',
                    })}
                    defaultValue={stepState.name}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="latitude"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.latitude',
                    })}
                    defaultValue={stepState.latitude}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="longitude"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.longitude',
                    })}
                    defaultValue={stepState.longitude}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="powerMaxKw"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chp.powerMaxKw',
                    })}
                    defaultValue={stepState.powerMaxKw}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="heatingPowerMaxKw"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chp.heatingPowerMaxKw',
                    })}
                    defaultValue={stepState.heatingPowerMaxKw}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="manufacturer"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.manufacturer',
                    })}
                    defaultValue={stepState.manufacturer}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="manufacturerDescriptionType"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chp.model',
                    })}
                    defaultValue={stepState.manufacturerDescriptionType}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="efficiency"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.chp.efficiency',
                    })}
                    defaultValue={stepState.efficiency}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="monitoringSystem"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.monitoring',
                    })}
                    defaultValue={stepState.monitoringSystem}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="installationDate"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.installationDate',
                    })}
                    defaultValue={stepState.installationDate}
                    type="datetime-local"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="street"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.street',
                    })}
                    defaultValue={stepState.street}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="postalCode"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.postalCode',
                    })}
                    defaultValue={stepState.postalCode}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="city"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.city',
                    })}
                    defaultValue={stepState.city}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="installationPlace"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.installationPlace',
                    })}
                    defaultValue={stepState.installationPlace}
                />
            </Grid>
        </>
    );
}

BhkwCoreData.propTypes = {
    stepState: propTypes.object,
};

export default BhkwCoreData;
