import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import bffService from '../services/bff';
import UrbanComponent from '../components/urbanComponent/UrbanComponent';

const UrbanComponentContainer = ({ componentDefinition, index }) => {
    const [state, setState] = useState({
        data: componentDefinition?.attributes?.data || null,
        error: null,
        loading: true,
    });
    const dataUrl =
        componentDefinition.attributes &&
        componentDefinition.attributes.dataUrl;

    useEffect(() => {
        let interval;
        const fetchDataFromBFF = async () => {
            const { error, response } = await bffService.getComponentData(
                dataUrl,
            );

            if (error) {
                setState({
                    data: null,
                    error,
                    loading: false,
                });
            } else {
                setState({
                    data: response,
                    error: null,
                    loading: false,
                });
            }
        };

        if (!state.data && dataUrl) {
            fetchDataFromBFF();
            interval = setInterval(() => {
                fetchDataFromBFF();
            }, 30000);
        }

        return () => {
            clearInterval(interval);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getComponentState = () => {
        if (state.data) return 'loaded';
        if (state.error) return 'error';
        if (state.loading && !state.data && dataUrl) return 'loading';
        return 'loaded'; // return loaded if no data needs to be fetched at all
    };

    return (
        <UrbanComponent
            componentDefinition={componentDefinition}
            state={getComponentState()}
            data={state.data}
            key={index}
            index={index}
        />
    );
};

UrbanComponentContainer.propTypes = {
    index: PropTypes.number.isRequired,
    componentDefinition: PropTypes.object.isRequired,
};

export default UrbanComponentContainer;
