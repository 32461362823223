export default unit => ({
    legend: {
        show: false,
    },
    xaxis: {
        type: 'category',
    },
    yaxis: {
        title: {
            text: unit,
        },
    },
    dataLabels: {
        enabled: false,
    },
    tooltip: {
        y: {
            formatter: value => `${Math.round(value)} ${unit}`,
        },
    },
    plotOptions: {
        heatmap: {
            colorScale: {
                ranges: [
                    {
                        from: 0,
                        to: 0,
                        color: '#eaeaea',
                    },
                ],
            },
        },
    },
});
