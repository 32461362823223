import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Tabs, Tab, Box } from '@material-ui/core';
import useStyles from './styles';
import TabOverview from './components/tabOverview/TabOverview';
import TabBaseData from './components/tabBaseData/TabBaseData';
import TabPerformanceData from './components/tabPerformanceData/TabPerformanceData';
import assetTypeConfig from './assetTypeConfig';

const AssetTypeTabs = ({ assetType }) => {
    const classes = useStyles();
    const intl = useIntl();
    const [value, setValue] = useState(0);
    const tabs = [
        'assetManagement.tab.overview',
        'assetManagement.tab.baseData',
        'assetManagement.tab.performanceData',
    ];
    const config = assetTypeConfig.find(({ type }) => type === assetType) ||
        assetTypeConfig.find(({type}) => type === 'DEFAULT');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box mb={2}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={classes.root}
                >
                    {tabs.map((tab, index) => (
                        <Tab
                            label={intl.formatMessage({
                                id: tab,
                            })}
                            className={classes.tab}
                            key={index}
                        />
                    ))}
                </Tabs>
            </Box>
            {value === 0 && <TabOverview overview={config.data.overview} />}
            {value === 1 && <TabBaseData baseData={config.data.baseData} />}
            {value === 2 && (
                <TabPerformanceData performance={config.data.performance} />
            )}
        </>
    );
};

AssetTypeTabs.propTypes = {
    assetType: propTypes.string.isRequired,
};

export default AssetTypeTabs;
