import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import {
    Box,
    Grid,
    Typography,
    Button,
    Input,
    MenuItem,
    TextField,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { dimensions as widgetDimensions } from '../../../components/widget';
import { Widget, Snackbar } from '../../../components';
import bffService from '../../../services/bff';

const formData = {
    batteryCapacityKWh: 'batteryCapacityKWh',
    batteryMaxChargingPowerKW: 'batteryMaxChargingPowerKW',
    assetIdChargingPoint: 'assetIdChargingPoint',
    requestedChargeInPercent: 'requestedChargeInPercent',
    currentChargeInPercent: 'currentChargeInPercent',
    priceForSelfCapacity: 'priceForSelfCapacity',
    priceForExternalEnergy: 'priceForExternalEnergy',
    marginInPercentage: 'marginInPercentage',
};

/**
 *
 * @todo implement Form with Formik and use its validation
 */
const Inputs = props => {
    const intl = useIntl();
    const [chargingPoints, setChargingPoints] = useState([]);
    const [state, setState] = useState({
        inputCategories: {
            customerInfo: {
                batteryCapacityKWh: {
                    id: formData.batteryCapacityKWh,
                    type: 'number',
                    dimensions: widgetDimensions.small,
                    description: 'kWh',
                },
                batteryMaxChargingPowerKW: {
                    id: formData.batteryMaxChargingPowerKW,
                    type: 'number',
                    dimensions: widgetDimensions.small,
                    description: 'kW',
                },
                currentChargeInPercent: {
                    id: formData.currentChargeInPercent,
                    description: '%',
                    type: 'number',
                    dimensions: widgetDimensions.small,
                },
                requestedChargeInPercent: {
                    id: formData.requestedChargeInPercent,
                    description: '%',
                    type: 'number',
                    dimensions: widgetDimensions.small,
                },
            },
            chargingPoint: {
                assetIdChargingPoint: {
                    id: formData.assetIdChargingPoint,
                    type: 'text',
                    dimensions: widgetDimensions.medium,
                },
            },
            quarter: {
                priceForSelfCapacity: {
                    id: formData.priceForSelfCapacity,
                    description: 'Cent / kWh',
                    type: 'number',
                    dimensions: widgetDimensions.small,
                    placeholder: '', // TODO: replace with bff call to settings - price_intern_kWh
                },
                priceForExternalEnergy: {
                    id: formData.priceForExternalEnergy,
                    description: 'Cent / kWh',
                    type: 'number',
                    dimensions: widgetDimensions.small,
                    placeholder: '', // TODO: replace with bff call to settings - price_extern_kWh
                },
                marginInPercentage: {
                    id: formData.marginInPercentage,
                    description: '%',
                    type: 'number',
                    dimensions: widgetDimensions.small,
                    placeholder: '', // TODO: replace with bff call to settings - margin_in_percentage
                },
            },
        },
        batteryCapacityKWhValue: '30',
        batteryMaxChargingPowerKWValue: '22.2',
        assetIdChargingPointValue: '',
        requestedChargeInPercentValue: '80',
        currentChargeInPercentValue: '20',
        priceForSelfCapacityValue: '',
        priceForExternalEnergyValue: '',
        marginInPercentageValue: '',
        snackbar: {
            error: false,
            messageId: 'generic.error',
        },
    });

    useEffect(() => {
        const fetchChargingPoints = async () => {
            const { response, error } = await bffService.getChargingPoints();

            if (error) {
                setState(prevState => ({
                    ...prevState,
                    snackbar: { ...prevState.snackbar, error: true },
                }));
            } else {
                setChargingPoints(response);
            }
        };

        fetchChargingPoints();
    }, []);

    const handleClose = () => {
        setState({
            ...state,
            snackbar: { ...state.snackbar, error: false },
        });
    };

    const onChangeChargingStation = event => {
        setState({
            ...state,
            assetIdChargingPointValue: event.target.value,
        });
    };

    const createParams = () => {
        let params = '';
        Object.keys(formData).forEach(key => {
            const value = state[`${key}Value`];
            if (value !== '') {
                params += `${key}=${value}&`;
            }
        });
        return params.substring(0, params.length - 1);
    };

    const handleChange = event => {
        setState({
            ...state,
            [`${event.target.name}Value`]: event.target.value,
        });
    };

    const generateInputWidget = inputInfo => {
        const formattedMessageId = `simulation.input.widgets.${inputInfo.id}.title`;
        return (
            <Widget
                title={<FormattedMessage id={formattedMessageId} />}
                {...inputInfo.dimensions}
                key={inputInfo.id}
            >
                {inputInfo.id !== 'assetIdChargingPoint' ? (
                    <Input
                        inputProps={{
                            'data-testid': `test-${inputInfo.id}`,
                        }}
                        type={inputInfo.type}
                        name={inputInfo.id}
                        id={inputInfo.id}
                        value={state[`${inputInfo.id}Value`]}
                        onChange={handleChange}
                        placeholder={
                            inputInfo.placeholder
                                ? `${inputInfo.placeholder}`
                                : ''
                        }
                        fullWidth
                    />
                ) : (
                    <TextField
                        data-testid="test-selectChargingPoint"
                        id="select"
                        value={state.assetIdChargingPointValue}
                        onChange={onChangeChargingStation}
                        fullWidth
                        disabled={chargingPoints.length === 0}
                        select
                        label={
                            chargingPoints.length !== 0
                                ? null
                                : intl.formatMessage({
                                    id: 'simulation.noChargingpointAvailable',
                                })
                        }
                    >
                        {chargingPoints.map(chargingPoint => (
                            <MenuItem
                                key={chargingPoint.id}
                                value={chargingPoint.id}
                                data-testid={
                                    `test-chargingPoint${  chargingPoint.name}`
                                }
                            >
                                {chargingPoint.name}
                            </MenuItem>
                        ))}
                    </TextField>
                )}

                {inputInfo.description ? (
                    <Typography>{inputInfo.description}</Typography>
                ) : null}
            </Widget>
        );
    };

    const validateInputs = () => {
        let message;
        let valid = true;
        if (
            parseInt(state.priceForExternalEnergyValue, 10) <=
                parseInt(state.priceForSelfCapacityValue, 10) &&
            state.marginInPercentageValue < 0
        ) {
            message = 'simulation.snackbar.invalid_price_and_error_margin';
            valid = false;
        } else if (
            parseInt(state.priceForExternalEnergyValue, 10) <=
            parseInt(state.priceForSelfCapacityValue, 10)
        ) {
            message = 'simulation.snackbar.invalid_price';
            valid = false;
        } else if (state.marginInPercentageValue < 0) {
            message = 'simulation.snackbar.error_margin';
            valid = false;
        } else if (state.assetIdChargingPointValue.length === 0) {
            message = 'simulation.snackbar.error_chargingPointId';
            valid = false;
        } else if (
            state.batteryCapacityKWhValue < 0 ||
            state.batteryCapacityKWhValue.length === 0
        ) {
            message = 'simulation.snackbar.error_batteryCapacity';
            valid = false;
        } else if (
            state.batteryMaxChargingPowerKWValue < 0 ||
            state.batteryMaxChargingPowerKWValue.length === 0
        ) {
            message = 'simulation.snackbar.error_batteryMaxCharging';
            valid = false;
        } else if (
            state.currentChargeInPercentValue < 0 ||
            state.currentChargeInPercentValue.length === 0
        ) {
            message = 'simulation.snackbar.error_currentCharge';
            valid = false;
        } else if (
            state.requestedChargeInPercentValue < 0 ||
            state.requestedChargeInPercentValue.length === 0
        ) {
            message = 'simulation.snackbar.error_requestedCharge';
            valid = false;
        }

        if (!valid) {
            setState({
                ...state,
                snackbar: {
                    ...state.snackbar,
                    error: true,
                    messageId: message,
                },
            });
        }
        return valid;
    };

    const onButtonClicked = () => {
        if (!validateInputs()) {
            return;
        }
        const params = createParams();
        props.onChargingPlansRequested(params);
        props.getAssetIdChargingPoint(state.assetIdChargingPointValue);
    };

    return (
        <div>
            {Object.entries(state.inputCategories).map(
                ([categoryId, categoryInfo]) => (
                    <Box key={categoryId} mb={2}>
                        <Typography variant="h2">
                            <FormattedMessage
                                id={`simulation.input.widgets.category.${categoryId}.title`}
                            />
                        </Typography>
                        <br />
                        <Grid
                            container
                            spacing={3}
                            alignItems="stretch"
                            component="div"
                        >
                            {Object.entries(
                                categoryInfo,
                            ).map(([_key, inputInfo]) =>
                                generateInputWidget(inputInfo),
                            )}
                        </Grid>
                    </Box>
                ),
            )}
            <Box mb={2}>
                <Grid container component="div">
                    <Button
                        onClick={onButtonClicked}
                        color="primary"
                        variant="contained"
                        data-testid="test-calculateChargingPlan"
                    >
                        {intl.formatMessage({
                            id: 'simulation.input.button.title',
                        })}
                    </Button>
                </Grid>
            </Box>
            <Snackbar
                open={state.snackbar.error}
                onClose={handleClose}
                variant="error"
                messageId={state.snackbar.messageId}
            />
        </div>
    );
};

Inputs.propTypes = {
    onChargingPlansRequested: propTypes.func.isRequired,
    getAssetIdChargingPoint: propTypes.func.isRequired,
};

export default Inputs;
