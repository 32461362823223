import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    stepBottom: {
        backgroundColor: theme.palette.white,
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}));

export default styles;
