import React from 'react';
import propTypes from 'prop-types';
import { REACT_APP_COMPANY_TITLE } from '../../../../resources/env';

function Logo(props) {
    return (
        <img
            src={props.logoUrl}
            title={REACT_APP_COMPANY_TITLE}
            alt={REACT_APP_COMPANY_TITLE}
            className={props.className}
        />
    );
}

Logo.propTypes = {
    logoUrl: propTypes.string,
    className: propTypes.string,
};

export default Logo;
