import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import {FormattedMessage} from "react-intl";
import moment from "moment";
import { REACT_APP_MOCKS } from '../../resources/env';
import styles from './styles';
import { getBrushSettings, getChartSettings } from './settings';
import * as ChartTypes from './chartTypes';
import DateFilter from './components/DateFilter';
import { Widget } from '..';
import { dimensions as widgetDimensions } from '../widget';

function Chart({
    plots,
    type = 'line',
    withGradient = true,
    additionalSettings = {},
    withBrushChart = false,
    brushChartSettings = {},
    unit,
    isDateFilter = false,
    onDateFilterChanged,
    size = 'huge',
    isColored = true,
    isLabeled = true,
    title,
    height = '500px',
    notMobile = false,
}) {
    const classes = styles();
    const dimensions = widgetDimensions[size];

    // date filter functionalities
    const [activeFilterOption, setActiveFilterOption] = useState("TODAY");
    const [date, setDate] = useState(null);
    const isFilterToday = activeFilterOption === "TODAY";

    const onDateFilterChange = activeOption => {
        setActiveFilterOption(activeOption);
        let dateParam;
        // when the active filter option is switched we need a default date for a new request
        // the default for every option is always in relation to the current date
        switch (activeOption) {
            // WEEK: start of current week + 1, because start of weekend is per default Sunday
            case "WEEK": dateParam = moment().startOf('isoWeek'); break
            // MONTH: start of current month
            case "MONTH": dateParam = moment().startOf("month"); break
            // TODAY and DAY: now
            default: dateParam = moment();
        }
        // the DateFilter needs the date in form of a new Date()
        setDate(new Date(dateParam.toISOString()));
        onDateFilterChanged(activeOption, dateParam);
    };

    const onDateSelect = dateParam => {
        setDate(dateParam);
        onDateFilterChanged(activeFilterOption, moment(dateParam));
    };

    // chart settings
    const chartId = brushChartSettings?.chart?.brush?.target; // set chart id to target of brush chart
    const theme = useTheme();
    const now = REACT_APP_MOCKS ? 1610106350240 : new Date().getTime();
    const chartSettings = getChartSettings(
        additionalSettings,
        chartId,
        theme,
        withGradient,
        now,
        plots,
        unit
    );
    const brushSettings = getBrushSettings(brushChartSettings, now, unit);
    const ChartType = ChartTypes[type];

    return (
        <>
            <Widget {...dimensions} title={title && <FormattedMessage id={title} />}
                centeredHeader
                titleVariant="h5">
                <div
                    className={clsx(
                        classes.outer,
                        withBrushChart && classes.withBrushChart,
                        isDateFilter && classes.paddingBottom,
                        !isFilterToday && classes.hideAnnotations,
                        type === 'coloredBar' && classes.coloredBar,
                        notMobile && classes.notMobile,
                    )}
                    style={{ height }}
                >
                    {isDateFilter && (
                        <DateFilter
                            onDateFilterChange={onDateFilterChange}
                            activeFilterOption={activeFilterOption}
                            onDateSelect={onDateSelect}
                            date={date}
                        />
                    )}
                    <ChartType
                        additionalSettings={additionalSettings}
                        settings={chartSettings}
                        plots={plots}
                        unit={unit}
                        withBrushChart={withBrushChart}
                        brushChartSettings={brushSettings}
                        isColored={isColored}
                        isLabeled={isLabeled}
                        height={height}
                    />
                </div>
            </Widget>
        </>
    );
}

Chart.propTypes = {
    isDateFilter: propTypes.bool,
    plots: propTypes.array.isRequired,
    type: propTypes.string,
    withGradient: propTypes.bool,
    additionalSettings: propTypes.object,
    withBrushChart: propTypes.bool,
    brushChartSettings: propTypes.object,
    unit: propTypes.string,
    onDateFilterChanged: propTypes.func,
    size: propTypes.string,
    title: propTypes.string,
    isColored: propTypes.bool,
    isLabeled: propTypes.bool,
    height: propTypes.string,
    xAxisPosition: propTypes.string,
    notMobile: propTypes.bool,
};

export default Chart;
