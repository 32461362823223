import React from 'react';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';

import styles from './styles';

const StepNavigation = ({ activeStep, handleBack, handleNext, steps }) => {
    const intl = useIntl();
    const classes = styles();

    const getStepAsString = () => {
        if (activeStep === 0) {
            return 'FirstStep';
        } if (activeStep === 1) {
            return 'SecondStep';
        } if (activeStep === 2) {
            return 'ThirdStep';
        } if (activeStep === 3) {
            return 'ForthStep';
        } if (activeStep > steps.length - 1) {
            return '';
        }
        return '';
    };

    return (
        <div className={classes.stepNavigation}>
            <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                data-testid={`test-preOf${  getStepAsString()}`}
            >
                {intl.formatMessage({
                    id: 'assetManagement.button.prevStep',
                })}
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                data-testid={`test-nextOf${  getStepAsString()}`}
            >
                {activeStep === steps.length - 1
                    ? intl.formatMessage({
                        id: 'assetManagement.button.addAsset',
                    })
                    : intl.formatMessage({
                        id: 'assetManagement.button.nextStep',
                    })}
            </Button>
        </div>
    );
};

StepNavigation.propTypes = {
    activeStep: propTypes.number,
    handleBack: propTypes.func,
    handleNext: propTypes.func,
    steps: propTypes.array,
};

export default StepNavigation;
