import chpImage from './images/chp.jpg';

export default {
    baseData: {
        location: [
            {
                title: 'asset.tab.baseData.street',
                property: 'street',
            },
            {
                title: 'asset.tab.baseData.postalCodeCity',
                property: 'postalCodeCity',
            },
            {
                title: 'asset.tab.baseData.installationPlace',
                property: 'installationPlace',
            },
            {
                title: 'asset.tab.baseData.longitude',
                property: 'longitude',
            },
            {
                title: 'asset.tab.baseData.latitude',
                property: 'latitude',
            },
        ],
        facilityInfo: [
            {
                property: 'efficiency',
                title: 'asset.tab.baseData.chp.efficiency',
                unit: '%',
            },
            {
                property: 'monitoringSystem',
                title: 'asset.tab.baseData.monitoring',
            },
            {
                property: 'installationDate',
                title: 'asset.tab.baseData.installationDate',
            },
        ],
    },
    overview: {
        media: {
            url: chpImage,
            title: 'Combined Heat And Power Plant',
        },
        info: [
            {
                property: 'deviceType',
                title: 'asset.tab.overview.type',
                valueId: 'assetManagement.type.chp',
            },
            {
                property: 'powerMaxKw',
                title: 'asset.tab.overview.chp.powerMaxKw',
                unit: 'kWp',
            },
            {
                property: 'heatingPowerMaxKw',
                title: 'asset.tab.overview.chp.heatingPowerMaxKw',
                unit: 'kWp',
            },
            {
                property: 'manufacturer',
                title: 'asset.tab.overview.manufacturer',
            },
            {
                property: 'manufacturerDescriptionType',
                title: 'asset.tab.overview.chp.model',
            },
        ],
    },
    performance: [{
        chartSettings: {
            yaxis: [
                {
                    type: 'numeric',
                    title: {
                        text: 'kW',
                    },
                    decimalsInFloat: 0,
                    forceNiceScale: true,
                },
                {
                    type: 'numeric',
                    decimalsInFloat: 0,
                    forceNiceScale: true,
                    opposite: true,
                    title: {
                        text: 'kW',
                    },
                },
            ],
        },
        withBrushChart: true,
        plots: [
            {
                id: 'chpPower',
                title: 'asset.tab.performanceData.produced_power.title',
                color: '#79E6F1',
                type: 'line',
            },
            {
                id: 'chpThermal',
                title: 'asset.tab.performanceData.thermal.title',
                color: '#F25C78',
                type: 'line',
            },
        ],
    },]
};
