import React from 'react';

const AppContext = React.createContext();

export const defaultContext = {
    asset: null,
    quarterSettings: null,
};

export default AppContext;
