import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';

const ChargingPointCoreData = ({ stepState }) => {
    const intl = useIntl();

    return (
        <>
            <Grid item md={6}>
                <TextField
                    id="name"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.name',
                    })}
                    defaultValue={stepState.name}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="latitude"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.latitude',
                    })}
                    defaultValue={stepState.latitude}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="longitude"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.longitude',
                    })}
                    defaultValue={stepState.longitude}
                    type="number"
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="manufacturer"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chargingPoint.manufacturer',
                    })}
                    defaultValue={stepState.manufacturer}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="manufacturerDescriptionType"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chargingPoint.manufacturerDescriptionType',
                    })}
                    defaultValue={stepState.manufacturerDescriptionType}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="currentType"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chargingPoint.currentType',
                    })}
                    defaultValue={stepState.currentType}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="phaseType"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chargingPoint.phaseType',
                    })}
                    defaultValue={stepState.phaseType}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="powerMaxKw"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chargingPoint.powerMaxKw',
                    })}
                    defaultValue={stepState.powerMaxKw}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="unitsCount"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chargingPoint.unitsCount',
                    })}
                    defaultValue={stepState.unitsCount}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="plugType"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chargingPoint.plugType_plugs',
                    })}
                    defaultValue={stepState.plugType}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="plugCount"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chargingPoint.plugType_count',
                    })}
                    defaultValue={stepState.plugCount}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="unitPowerMaxW"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chargingPoint.unitPowerMaxW',
                    })}
                    defaultValue={stepState.unitPowerMaxW}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="evseId"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.chargingPoint.evseID',
                    })}
                    defaultValue={stepState.evseId}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="installationDate"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.installationDate',
                    })}
                    defaultValue={stepState.installationDate}
                    type="datetime-local"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="street"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.street',
                    })}
                    defaultValue={stepState.street}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="postalCode"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.postalCode',
                    })}
                    defaultValue={stepState.postalCode}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="city"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.city',
                    })}
                    defaultValue={stepState.city}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="installationPlace"
                    label={intl.formatMessage({
                        id: 'asset.tab.baseData.installationPlace',
                    })}
                    defaultValue={stepState.installationPlace}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="accessibility"
                    label={intl.formatMessage({
                        id: 'asset.tab.overview.chargingPoint.accessibility',
                    })}
                    defaultValue={stepState.accessibility}
                />
            </Grid>
        </>
    )
};

ChargingPointCoreData.propTypes = {
    stepState: propTypes.object,
};

export default ChargingPointCoreData;
