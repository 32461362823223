import React from 'react';
import propTypes from 'prop-types';

import Skeleton from '@material-ui/lab/Skeleton';
import { Widget } from '../..';
import { dimensions as widgetDimensions } from '../../widget';

import styles from '../styles';

function ChartLoading() {
    const classes = styles();

    return (
        <Widget {...widgetDimensions.huge}>
            <div className={classes.outer}>
                <div className={classes.legend}>
                    <Skeleton width={'100%'} height={'100%'} />
                </div>
                <div className={classes.chart}>
                    <Skeleton width={'100%'} height={'100%'} variant="rect" />
                </div>
            </div>
        </Widget>
    );
}

ChartLoading.propTypes = {
    classes: propTypes.object,
};

export default ChartLoading;
