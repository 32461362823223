(() => {
    if (typeof window.CustomEvent === 'function') return false;

    function CustomEvent(event, params) {
        // eslint-disable-next-line no-param-reassign
        params = params || { bubbles: false, cancelable: false, detail: null };
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(
            event,
            params.bubbles,
            params.cancelable,
            params.detail,
        );
        return evt;
    }

    window.CustomEvent = CustomEvent;
    return null;
})();
