import React from 'react';

import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Widget } from '../..';
import { dimensions as widgetDimensions } from '../../widget';

function AirQualityBoxLoading() {
    return (
        <Widget title={<Skeleton width='100%' />} {...widgetDimensions.huge}>
            <Typography>{<Skeleton width='100%' />}</Typography>
            <Typography>{<Skeleton width='100%' />}</Typography>
        </Widget>
    );
}

export default AirQualityBoxLoading;
