import windturbineImg from './images/windturbine.jpg';

export default {
    baseData: {
        location: [
            {
                title: 'asset.tab.baseData.street',
                property: 'street',
            },
            {
                title: 'asset.tab.baseData.postalCodeCity',
                property: 'postalCodeCity',
            },
            {
                title: 'asset.tab.baseData.installationPlace',
                property: 'installationPlace',
            },
            {
                title: 'asset.tab.baseData.longitude',
                property: 'longitude',
            },
            {
                title: 'asset.tab.baseData.latitude',
                property: 'latitude',
            },
        ],
        facilityInfo: [
            {
                property: 'installationDate',
                title: 'asset.tab.baseData.installationDate',
            },
            {
                property: 'dataResolution',
                title: 'asset.tab.overview.windTurbine.dataResolution',
            },
            {
                property: 'energyCellLevel',
                title: 'asset.tab.overview.windTurbine.energyCellLevel',
            }
        ],
    },
    overview: {
        media: {
            url:
                windturbineImg,
            title: 'Wind turbine',
        },
        info: [
            {
                property: 'deviceType',
                title: 'asset.tab.overview.type',
                valueId: 'assetManagement.type.windTurbine',
            },
            {
                property: 'powerMaxKw',
                title: 'asset.tab.overview.pv.powerMaxKw',
                unit: 'kWp',
            }
        ],
    },
    performance: [
        {
            chartType: 'line',
            plotUnit: 'kW',
            withBrushChart: true,
            plots: [
                {
                    id: 'windTurbine',
                    title: 'asset.tab.performanceData.produced_power.title',
                    color: '#79E6F1',
                    type: 'line',
                },
            ],
        },
    ],
};
