import qs from 'qs';
import {
    REACT_APP_AUTHORIZE_URL,
    REACT_APP_CLIENT_ID,
} from '../../resources/env';

const buildURL = (url, params) => {
    if (params == null) return url;

    const serializedParams = qs.stringify(params);
    if (!serializedParams) return url;

    return `${url}${url.indexOf('?') < 0 ? '?' : '&'}${serializedParams}`;
};

export default buildURL(REACT_APP_AUTHORIZE_URL, {
    client_id: REACT_APP_CLIENT_ID,
    redirect_uri: window.location.href,
    response_type: 'token',
});
