import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    tab: {
        textTransform: 'none',
        '&:hover': {
            color: theme.palette.primary.light,
            opacity: 1,
        },
    },
}));

export default styles;
