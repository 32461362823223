export default {
    baseData: {
        location: [
            {
                title: 'asset.tab.baseData.street',
                property: 'street',
            },
            {
                title: 'asset.tab.baseData.postalCodeCity',
                property: 'postalCodeCity',
            },
            {
                title: 'asset.tab.baseData.installationPlace',
                property: 'installationPlace',
            },
            {
                title: 'asset.tab.baseData.longitude',
                property: 'longitude',
            },
            {
                title: 'asset.tab.baseData.latitude',
                property: 'latitude',
            },
        ],
        facilityInfo: [
            {
                property: 'networkSupply',
                title: 'asset.tab.baseData.pv.networkSupply',
                unit: 'kWh/a',
            },
            {
                property: 'utilization',
                title: 'asset.tab.baseData.pv.utilization',
                unit: '%',
            },
            {
                property: 'annualGain',
                title: 'asset.tab.baseData.pv.annualGain',
                unit: 'kWh/kWpeak/a',
            },
            {
                property: 'reducedEmissionCO2',
                title: 'asset.tab.baseData.pv.reducedEmissionCO2',
                unit: 'kg/a',
            },
            {
                property: 'powerInverterManufacturer',
                title: 'asset.tab.baseData.pv.powerInverterManufacturer',
            },
            {
                property: 'powerInverterType',
                title: 'asset.tab.baseData.pv.powerInverterType',
            },
            {
                property: 'monitoringSystem',
                title: 'asset.tab.baseData.monitoring',
            },
            {
                property: 'installationDate',
                title: 'asset.tab.baseData.installationDate',
            },
        ],
    },
    overview: {
        media: {
            url:
                'https://www.solarserver.de/wp-content/uploads/Grammer_solar_Rudolf_Ettl_Ostwest_495px_01.jpg',
            title: 'Photovoltaic system',
        },
        info: [
            {
                property: 'deviceType',
                title: 'asset.tab.overview.type',
                valueId: 'assetManagement.type.photovoltaik',
            },
            {
                property: 'powerMaxKw',
                title: 'asset.tab.overview.pv.powerMaxKw',
                unit: 'kWp',
            },
            {
                property: 'manufacturer',
                title: 'asset.tab.overview.manufacturer',
            },
            {
                property: 'unitModel',
                title: 'asset.tab.overview.pv.unitModel',
            },
            {
                property: 'solarCellType',
                title: 'asset.tab.overview.pv.solarCellType',
            },
            {
                property: 'unitPowerMaxW',
                title: 'asset.tab.overview.pv.unitPowerMaxW',
                unit: 'Wp (peak)',
            },
            {
                property: 'unitsCount',
                title: 'asset.tab.overview.pv.unitsCount',
            },
            {
                property: 'systemTotalArea',
                title: 'asset.tab.overview.pv.systemTotalArea',
                unit: 'm²',
            },
        ],
    },
    performance: [
        {
            chartType: 'line',
            plotUnit: 'kW',
            withBrushChart: true,
            plots: [
                {
                    id: 'pv',
                    title: 'asset.tab.performanceData.produced_power.title',
                    color: '#79E6F1',
                    type: 'line',
                },
            ],
        },
    ],
};
