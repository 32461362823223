import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import bffService from '../../../../services/bff';
import WhitelabelChart from '../../../chart/WhitelabelChart';
import ChartLoading from '../../../chart/components/ChartLoading';
import ChartError from '../../../chart/components/ChartError';
import AppContext from '../../../../appContext';

const TabPerformanceData = ({ performance }) => {
    const context = useContext(AppContext)[0];
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [chartError, setChartError] = useState(false);

    useEffect(() => {
        const fetchAssetSeries = async () => {
            setLoading(true);
            const { response, error } = await bffService.getAssetSeriesData(
                context.asset.id,
                context.asset.deviceType,
            );

            if (error) {
                setLoading(false);
                setChartError(true);
                return;
            }
            setChartData(response);
            setLoading(false);
        };

        fetchAssetSeries();
    }, [context.asset.deviceType, context.asset.id]);

    const getChart = () => {
        return performance.map((config, index) => (
            <WhitelabelChart
                data={chartData}
                plots={config.plots}
                additionalSettings={config.additionalSettings || {}}
                withBrushChart={config.chartType !== 'heatmap' && config.withBrushChart}
                type={config.chartType}
                key={index}
                unit={config.plotUnit}
                withGradient={false}
            />
        ));
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {chartData && performance.length >= 1 && getChart()}
                {loading && <ChartLoading />}
                {chartError && <ChartError />}
                {!loading && !chartError && performance.length === 0 && (
                    <FormattedMessage id="assetManagement.tab.performanceData_noData" />
                )}
            </Grid>
        </Grid>
    );
};

TabPerformanceData.propTypes = {
    performance: propTypes.array,
};

export default TabPerformanceData;
