export const drawerWidth = 240;
export const drawerClosedWidth = 56;

export default theme => ({
    root: {
        display: 'flex',
        maxWidth: '100vw',
    },
    content: {
        flexGrow: 1,
        padding: `0 ${theme.spacing(3)}px`,
        width: 'calc(100vw - 240px)',
        minHeight: '100vh',
    },
    contentShift: {
        width: `calc(100vw - ${drawerWidth + theme.spacing(6)}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
});
