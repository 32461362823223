import React from 'react';
import propTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { dimensions as widgetDimensions } from '../widget';
import Widget from '../widget/Widget';
import Scale from './components/scale/Scale';
import styles from './styles';

const ScaleBox = ({
    title,
    value,
    unit,
    size = 'small',
    scaleBoxType,
    hasScale = true,
}) => {
    const classes = styles();
    const widgetSize = widgetDimensions[size];

    return (
        <Widget
            title={<FormattedMessage id={title} />}
            {...widgetSize}
            titleVariant="h5"
            centeredHeader
        >
            {hasScale && <Scale
                scaleBoxType={scaleBoxType}
                value={value}
            />}
            <div className={classes.valueBox}>
                <Typography className={classes.value} variant="h3">{value}</Typography>
                <Typography>{unit}</Typography>
            </div>
        </Widget>
    );
};

ScaleBox.propTypes = {
    title: propTypes.string,
    value: propTypes.oneOfType([propTypes.number, propTypes.string]),
    unit: propTypes.string,
    size: propTypes.string,
    scaleBoxType: propTypes.string,
    hasScale: propTypes.bool,
};

export default ScaleBox;
