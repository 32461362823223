import React from 'react';
import propTypes from 'prop-types';
import Login from './Login';

import loginUrl from './loginUrl';

const LoginContainer = ({ onLogin, children }) => (
    <Login loginUrl={loginUrl} onLogin={onLogin}>
        {children}
    </Login>
);

LoginContainer.propTypes = {
    children: propTypes.oneOfType([propTypes.array, propTypes.element]),
    onLogin: propTypes.func,
};

export default LoginContainer;
