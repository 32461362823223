import React, { useState, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';

import { Layout, Login, Breadcrumbs } from './components';
import { createTheme } from './theme';
import { renderComponents } from './helper';
import * as staticPages from './pages';
import bffService from './services/bff';
import AppContext, { defaultContext } from './appContext';

function App() {
    const intl = useIntl();
    const [context, setContext] = useState(defaultContext);
    const [role, setRole] = useState(null);
    const [content, setContent] = useState(null);
    const [theme, setTheme] = useState(null);
    const [error, setError] = useState(null);
    const AssetDetailPage = staticPages.Asset;

    useEffect(() => {
        const getQuarterSettings = async () => {
            const {
                error: configError,
                response,
            } = await bffService.getContentConfig();
            const {
                error: quarterSettingsError,
                response: quarterSettingsResponse,
            } = await bffService.getQuarterSettings();

            if (configError) {
                setError(configError);
            } else if (quarterSettingsError) {
                setError(quarterSettingsError);
            } else {
                setContent(response);
                setContext(prevContext => ({
                    ...prevContext,
                    quarterSettings: quarterSettingsResponse,
                }));
                setTheme({
                    logo: quarterSettingsResponse.logo,
                    themeConfig: createTheme(quarterSettingsResponse),
                });
            }
        };

        getQuarterSettings();

        const handleSaveThemeEvent = () => {
            getQuarterSettings();
        };

        window.addEventListener('save-theme', handleSaveThemeEvent);

        return function cleanupListener() {
            window.removeEventListener('save-theme', handleSaveThemeEvent);
        };
    }, []);

    const setRoutesOnLogin = roleParam => {
        setRole(roleParam);
    };

    const getPagesByRole = () => {
        if (!content.pages || content.pages.length === 0) {
            return [];
        }

        return content.pages.filter(page => page.role === role);
    };

    return (
        <BrowserRouter basename={window.localStorage.getItem('basename')}>
            <Login onLogin={setRoutesOnLogin}>
                {theme && content && (
                    <MuiThemeProvider theme={theme.themeConfig}>
                        <AppContext.Provider value={[context, setContext]}>
                            {getPagesByRole().length >= 1 && (
                                <Layout
                                    role={role}
                                    pages={getPagesByRole()}
                                    logoUrl={theme.logo}
                                >
                                    <Breadcrumbs pages={getPagesByRole()} />
                                    <Switch>
                                        {getPagesByRole().map(route => {
                                            // as these pages are not implemented via whitelabeling, render them the usual way
                                            if (route.isStatic) {
                                                const StaticPage =
                                                    staticPages[route.pageName];

                                                return (
                                                    <Route
                                                        exact
                                                        path={route.path}
                                                        key={route.pageName}
                                                        render={() => (
                                                            <StaticPage
                                                                {...route.attributes}
                                                            />
                                                        )}
                                                    />
                                                );
                                            }
                                            return (
                                                <Route
                                                    key={route.pageName}
                                                    path={route.path}
                                                    exact
                                                    render={() =>
                                                        renderComponents(
                                                            route.components,
                                                        )
                                                    }
                                                />
                                            );
                                        })}
                                        <Route
                                            key="assetDetail"
                                            path="/assets/:assetId"
                                            component={AssetDetailPage}
                                        />
                                        <Redirect to="/dashboard" />
                                    </Switch>
                                </Layout>
                            )}
                        </AppContext.Provider>
                    </MuiThemeProvider>
                )}
                {error &&
                    intl.formatMessage({
                        id: 'qmPortal.mainPage.error',
                    })}
            </Login>
        </BrowserRouter>
    );
}

export default App;
