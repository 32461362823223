import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    requestButton: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
}));

export default styles;
