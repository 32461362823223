import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    mainHeadline: {
        marginBottom: theme.spacing(6),
    },
    progress: {
        marginBottom: theme.spacing(2),
        height: '20px',
    },
}));
