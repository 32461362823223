import { TokenService } from '@com2m/rest-library';
import { REACT_APP_MOCKS, REACT_APP_LOGOUT_URL } from '../../resources/env';
import loginUrl from '../../components/login/loginUrl';

const com2mService = {
    isQuarterManager: () => {
        if (REACT_APP_MOCKS) return true;
        return TokenService.getInstance().hasAnyRole(
            'URBANENERGY_QUARTER_MANAGER_ADMIN',
        );
    },

    isLoggedIn: () => {
        if (REACT_APP_MOCKS) return true;
        return TokenService.getInstance().hasValidAccessToken();
    },

    getAccessToken: () => TokenService.getInstance().getAccessToken(),

    logOut: () => {
        TokenService.getInstance().removeAccessToken();
        const httpOptions = {
            headers: new Headers({
                'Content-Type': 'text/plain',
            }),
            method: 'GET',
            credentials: 'include',
        };
        fetch(REACT_APP_LOGOUT_URL, httpOptions)
            .then(function(response) {
                window.location.assign(loginUrl);
                return response;
            })
            .catch(function(error) {
                console.log('Error bei logout ', error);
            });
    },
};

export default com2mService;
