import React from 'react';
import { Grid } from '@material-ui/core';
import UrbanComponentContainer from '../../container/UrbanComponentContainer';

function renderComponents(components) {
    return React.createElement(
        Grid,
        {
            spacing: 3,
            container: true,
            alignItems: 'stretch',
            component: 'div',
        },
        components.map((def, index) => {
            return (
                <UrbanComponentContainer
                    componentDefinition={def}
                    key={index}
                    index={index}
                />
            );
        }),
    );
}

export default renderComponents;
