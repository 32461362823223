/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Slider, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { BatteryChargingFull, BatteryCharging20 } from '@material-ui/icons';
import { pickBy as _pickBy, isPlainObject as _isPlainObject } from 'lodash';

import styles from './styles';

/**
 * @todo put label of current ChargingProgress above the slider
 */
const ChargingProgress = ({ currentChargingProcess, classes }) => {
    const [currentBatteryCapacity, setCurrentBatteryCapacity] = useState(0);
    const {
        currentChargeLevel: startingChargeLevel,
        desiredChargeLevel,
        chargingPlan: { chargeLoadPlan },
    } = currentChargingProcess;

    useEffect(() => {
        setCurrentBatteryCapacity(getCurrentBatteryCapacity());
        setInterval(() => {
            setCurrentBatteryCapacity(getCurrentBatteryCapacity());
        }, 60000);
    }, []);

    const getCurrentBatteryCapacity = () => {
        if (!_isPlainObject(chargeLoadPlan)) {
            return 0;
        }

        const chargedLoadPlanUntilNow = getChargeLoadPlanUntilNow(
            chargeLoadPlan,
        );
        const chargedSumUntilNow = Object.keys(chargedLoadPlanUntilNow).length
            ? getChargedSum(chargedLoadPlanUntilNow)
            : 0;
        const chargeLoadPlanSum = getChargedSum(chargeLoadPlan);
        const DIFFERENCE_CHARGE_UNTIL_NOW_AND_CHARGE_COMPLETE_IN_PERCENTAGE =
            (chargedSumUntilNow / chargeLoadPlanSum) * 100;
        const DIFFERENCE_CURRENT_LEVEL_AND_DESIRED_LEVEL_IN_PERCENTAGE =
            parseInt(desiredChargeLevel, 10) -
            parseInt(startingChargeLevel, 10);

        return (
            (DIFFERENCE_CHARGE_UNTIL_NOW_AND_CHARGE_COMPLETE_IN_PERCENTAGE /
                100) *
                (DIFFERENCE_CURRENT_LEVEL_AND_DESIRED_LEVEL_IN_PERCENTAGE /
                    100) *
                100 +
            parseInt(startingChargeLevel, 10)
        ).toFixed(2);
    };

    const getChargeLoadPlanUntilNow = chargePlan => {
        const now = new Date().getTime();
        return _pickBy(chargePlan, (_keyValue, key) => {
            const date = new Date(key).getTime();

            return now - date >= 0;
        });
    };

    const getChargedSum = chargedLoadPlanUntilNow => {
        let chargedSum = 0;

        Object.keys(chargedLoadPlanUntilNow).forEach(item => {
            chargedSum += chargedLoadPlanUntilNow[item];
        });

        return chargedSum;
    };

    const capacityValueMarks = [
        {
            value: 0,
            label: '0 %',
        },
        {
            value: currentBatteryCapacity,
            label: `${currentBatteryCapacity} %`,
        },
        {
            value: 100,
            label: '100 %',
        },
    ];

    return (
        <Grid className={classes.root} container spacing={2}>
            <Grid item>
                <BatteryCharging20 fontSize="large" />
            </Grid>
            <Grid item xs>
                <Slider
                    className={classes.slider}
                    marks={capacityValueMarks}
                    value={currentBatteryCapacity}
                    aria-labelledby="continuous-slider"
                    min={0}
                    max={100}
                />
            </Grid>
            <Grid item>
                <BatteryChargingFull fontSize="large" />
            </Grid>
        </Grid>
    );
};

ChargingProgress.propTypes = {
    currentChargingProcess: propTypes.object.isRequired,
    classes: propTypes.object,
};

export default withStyles(styles)(ChargingProgress);
