export default unit => ({
    dataLabels: {
        enabled: false,
    },
    stroke: {
        width: 3,
    },
    grid: {
        xaxis: {
            lines: {
                show: true,
            },
        },
        yaxis: {
            lines: {
                show: true,
            },
        },
    },
    xaxis: {
        type: 'datetime',
        labels: {
            datetimeUTC: false,
        },
    },
    yaxis: {
        type: 'numeric',
        title: {
            text: unit,
        },
        decimalsInFloat: 0,
        forceNiceScale: true,
        min: 0,
    },
    tooltip: {
        x: {
            format: 'HH:mm',
        },
    },
});
