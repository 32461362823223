import React from 'react';
import { ExitToAppOutlined } from '@material-ui/icons';
import { Button, Typography, withStyles } from '@material-ui/core';
import propTypes from 'prop-types';

import styles from './styles';

function LogoutButton({ classes, ...props }) {
    return (
        <Button {...props}>
            <ExitToAppOutlined className={classes.icon} />
            <Typography>Logout</Typography>
        </Button>
    );
}

LogoutButton.propTypes = {
    classes: propTypes.object,
};

export default withStyles(styles)(LogoutButton);
