import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    outer: {
        height: '500px',
        position: 'relative',
        paddingBottom: 0,
        '& *': {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
    },
    notMobile: {
        minWidth: 1160,
    },
    withBrushChart: {
        height: '700px !important',
    },
    paddingBottom: {
        paddingBottom: 48,
    },
    brushOuter: {
        marginTop: '16px',
        height: 130,
    },
    errorMessage: {
        textAlign: 'center',
        marginTop: '20px',
    },
    dateFilter: {
        display: 'flex',
        paddingLeft: 20,
    },
    dateFilterItem: {
        display: 'flex',
        marginRight: '16px',
    },
    dateFilterButton: {
        padding: 4,
        border: `1px solid ${theme.palette.text.primary}`,
        borderRadius: 3,
        backgroundColor: '#ffffff',
        minWidth: 65,
        transition: 'color 0.2s, background-color 0.2s',
    },
    dateFilterButtonActive: {
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff',
    },
    datePicker: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '& input': {
            border: 'none',
            width: 130,
        },
        '& .react-datepicker__input-container': {
            height: '100%',
            position: 'relative',
            '&::after': {
                content: '""',
                bottom: -4,
                left: 0,
                height: 1,
                width: '100%',
                backgroundColor: theme.palette.text.primary,
                position: 'absolute',
            },
        },
        '& .react-datepicker__month-text--keyboard-selected': {
            color: '#000',
            backgroundColor: '#ffffff',
            '&:hover': {
                backgroundColor: '#f0f0f0',
            },
        },
        '& .react-datepicker__day--selected, .react-datepicker__month--selected': {
            color: '#ffffff',
            backgroundColor: theme.palette.text.primary,
            borderRadius: 3,
            '&:hover': {
                color: '#ffffff',
                backgroundColor: `${theme.palette.text.primary}!important`,
                borderRadius: 3,
            },
        },
        '& .calendar-weeks .react-datepicker__week': {
            '& .react-datepicker__day--selected': {
                color: '#000',
                backgroundColor: '#ffffff',
            },
            '& .react-datepicker__week-number': {
                color: theme.palette.text.primary,
                fontWeight: 'bold',
            },
            '&:hover': {
                '& .react-datepicker__day': {
                    color: '#ffffff',
                    backgroundColor: theme.palette.text.primary,
                    borderRadius: 3,
                },
                '& .react-datepicker__day--disabled': {
                    color: '#ccc',
                    backgroundColor: '#ffffff',
                },
            },
        },
    },
    calendarIcon: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
    },
    hideAnnotations: {
        '& .apexcharts-xaxis-annotations': {
            display: 'none',
        },
    },
    coloredBar: {
        '& .apexcharts-yaxis-annotations line': {
            transform: 'scaleX(1.2)',
        },
        '& .hide-labels': {
            display: 'none',
        },
    },
    coloredYAxis: {
        height: '100%',
        '& .apexcharts-canvas': {
            backgroundImage: 'linear-gradient(' +
                '#EC4452 13.2%, #F0655B 13.2%, ' +
                '#F0655B 26.4%, #F2A772 26.4%, ' +
                '#F2A772 39.5%, #FCEC6A 39.5%, ' +
                '#FCEC6A 52.6%, #A7F890 52.6%, ' +
                '#A7F890 65.6%, #50FFD0 65.6%, ' +
                '#50FFD0 79%, #FFF 79%, #FFF 100%)',
            backgroundPosition: '0 35px !important',
        },
    },
    legend: {
        '& .legend-1, .legend-2, .legend-0, .legend-3': {
            width: 60,
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        '& .legend-0': {
            border: `2px solid ${theme.palette.wistaBlue}`
        },
        '& .legend-1': {
            border: `2px dashed ${theme.palette.wistaBlue}`
        },
        '& .legend-2': {
            border: `2px dotted ${theme.palette.wistaBlue}`
        },
        '& .legend-3': {
            height: 4,
            backgroundImage: 'linear-gradient(to right,' +
                '#32A6CB 20%, #FFF 20%, ' +
                '#FFF 40%, #32A6CB 40%, ' +
                '#32A6CB 60%, #FFF 60%, ' +
                '#FFF 80%, #32A6CB 80%, ' +
                '#32A6CB 100%)',
        },
    },
}));

export default styles;
