import React, { useState, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';

import AddAsset from './components/AddAsset/AddAsset';
import AssetTypesList from './components/AssetTypesList/AssetTypesList';
import TableLoading from '../../components/table/components/TableLoading';
import TableError from '../../components/table/components/TableError';

import { Snackbar } from '../../components';
import bffService from '../../services/bff';
import useContext from '../../hooks/useContext';

const Assets = () => {
    const setContext = useContext()[1];
    const [assets, setAssets] = useState([]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        messageId: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchAssets = async () => {
            setLoading(true);
            const { response, err } = await bffService.getAssets();
            if (err) {
                setSnackbar({
                    open: true,
                    messageId: 'assetManagement.snackbar.error',
                });
                setLoading(false);
                setError(true);
            } else {
                setAssets(response);
                setLoading(false);
            }
        };

        // reset asset context everytime we enter the asset overview page
        setContext(prevContext => ({ ...prevContext, asset: null }));

        fetchAssets();
    }, [setContext]);

    const handleSnackbarClose = () => {
        setSnackbar({
            ...snackbar,
            open: false,
        });
    };

    return (
        <>
            {assets.length > 0 && <AssetTypesList assets={assets} />}
            {loading && <TableLoading />}
            {error && <TableError />}
            {!loading && !error && assets.length === 0 && <FormattedMessage id="assetManagement.table.noAssets" />}
            <AddAsset />
            <Snackbar
                open={snackbar.open}
                onClose={handleSnackbarClose}
                messageId={snackbar.messageId}
                variant="error"
            />
        </>
    );
}

export default Assets;
