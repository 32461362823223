import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles, withTheme, CssBaseline, Box } from '@material-ui/core';
import { roles } from '../../services/com2m/serviceConstants';
import Header from './header';
import Sidebar from './sidebar';

import styles from './styles';

const Layout = ({ classes, theme, pages, children, logoUrl, role }) => {
    const [state, setState] = useState({
        isMobile: false,
        sidebarOpened: true,
        sidebarOpenedMobile: false,
    });
    const isQuarterManagerPortal = role === roles.quarterManager;

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isMobile]);

    const handleWindowResize = () => {
        const { isMobile } = state;
        const windowWidth = window.innerWidth;
        const breakpointWidth = theme.breakpoints.values.md;
        const isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && !isMobile) {
            setState({ ...state, isMobile: true });
        } else if (!isSmallScreen && isMobile) {
            setState({ ...state, isMobile: false });
        }
    };

    const onSidebarToggle = () => {
        setState(prevState => ({
            ...prevState,
            sidebarOpened: !prevState.sidebarOpened,
        }));
    };

    const onSidebarToggleMobile = () => {
        setState(prevState => ({
            ...prevState,
            sidebarOpenedMobile: !prevState.sidebarOpenedMobile,
        }));
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Header
                isQuarterManagerPortal={isQuarterManagerPortal}
                isMobile={state.isMobile}
                toggleSidebarMobile={onSidebarToggleMobile}
                logoUrl={logoUrl}
            />
            <Sidebar
                isMobile={state.isMobile}
                isSidebarOpened={state.sidebarOpened}
                isSidebarOpenedMobile={state.sidebarOpenedMobile}
                toggleSidebar={onSidebarToggle}
                toggleSidebarMobile={onSidebarToggleMobile}
                pages={pages}
            />
            <div
                className={clsx(classes.content, {
                    [classes.contentShift]: state.sidebarOpened,
                })}
            >
                <Box pt={10}>{children}</Box>
            </div>
        </div>
    );
};

Layout.propTypes = {
    classes: propTypes.object,
    theme: propTypes.object,
    pages: propTypes.array,
    children: propTypes.oneOfType([propTypes.array, propTypes.element]),
    logoUrl: propTypes.string,
    role: propTypes.string,
};

export default withStyles(styles)(withTheme(Layout));
