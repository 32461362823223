import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { CardHeader } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { FormattedMessage } from 'react-intl';
import PowerIcon from '@material-ui/icons/Power';

const ConnectivityBox = ({ status, title, timestamp }) => {
    const availableColors = [
        {
            status: 'UNKNOWN',
            color: '#ED7014',
        },
        {
            status: 'ONLINE',
            color: '#339933',
        },
        {
            status: 'OFFLINE',
            color: '#FF0000',
        },
    ];

    const getColorForStatusId = statusId => {
        const color = availableColors.find(
            availableIcon => availableIcon.status === statusId,
        );
        if (!color) {
            return '#000000';
        }
        return color.color;
    };

    const mapStatusToTranslationKey = deviceStatus => {
        let translationKey;
        switch (deviceStatus) {
            case 'ONLINE':
                translationKey = 'assets.status.online';
                break;
            case 'OFFLINE':
                translationKey = 'assets.status.offline';
                break;
            case 'UNKNOWN':
            default:
                translationKey = 'assets.status.unknown';
                break;
        }

        return translationKey;
    }

    const valueTimestamp = moment(timestamp, ['YYYY-MM-DDTHH:mm:ss.SSSZ', 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ'], true).isValid() ? moment(timestamp).format('DD.MM.YYYY HH:mm:ss') : '';

    const subHeader = (
        <React.Fragment>
            <FormattedMessage id={title} />
            < br />
            <span style={{'fontSize': '12px'}}>{valueTimestamp}</span>
        </React.Fragment>
    );

    return (
        <CardHeader
            avatar={
                <Avatar
                    aria-label="avatar"
                    variant="rounded"
                    style={{
                        backgroundColor: getColorForStatusId(status),
                    }}
                >
                    <PowerIcon />
                </Avatar>
            }
            title={<FormattedMessage id={mapStatusToTranslationKey(status)} />}
            subheader={subHeader}
        />
    );
};

ConnectivityBox.propTypes = {
    status: propTypes.string,
    title: propTypes.string,
    timestamp: propTypes.string,
};

export default ConnectivityBox;
