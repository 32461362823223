import React from 'react';
import propTypes from 'prop-types';
import { CardHeader } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { FormattedMessage } from 'react-intl';
import EventIcon from '@material-ui/icons/Event';
import moment from 'moment';

const UpcomingEventBox = ({ date, eventName, severity }) => {
    const getIconColor = () => {
        switch (severity) {
            case 'INFO':
                return '#339933';
            case 'WARNING':
                return '#ED7014';
            case 'ERROR':
                return '#FF0000';
            case 'CRITICAL':
                return '#b20000';
            default:
                return '#8585ad';
        }
    };

    return (
        <CardHeader
            avatar={
                <Avatar
                    variant="rounded"
                    aria-label="avatar"
                    style={{
                        backgroundColor: getIconColor(),
                    }}
                >
                    <EventIcon />
                </Avatar>
            }
            title={date && `${moment(date).format('DD.MM.YYYY HH:mm')} Uhr`}
            subheader={date ? eventName : <FormattedMessage id={eventName} />}
        />
    );
};

UpcomingEventBox.propTypes = {
    date: propTypes.string,
    eventName: propTypes.string,
    severity: propTypes.string,
};

export default UpcomingEventBox;
