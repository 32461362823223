import React from 'react';
import propTypes from 'prop-types';

import KpiBox from '../KpiBox';

const KpiBoxError = ({ title, unit, showDate }) => {
    return <KpiBox title={title} value="" unit={unit} showDate={showDate} />;
};

KpiBoxError.propTypes = {
    title: propTypes.string.isRequired,
    data: propTypes.object,
    unit: propTypes.string.isRequired,
    showDate: propTypes.bool,
};

export default KpiBoxError;
