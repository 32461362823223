import React from 'react';
import propTypes from 'prop-types';

import AirQualityBox from '../AirQualityBox';

const AirQualityBoxError = ({ title }) => {
    return <AirQualityBox title={title} value={0} />;
};

AirQualityBoxError.propTypes = {
    title: propTypes.string.isRequired,
};

export default AirQualityBoxError;
