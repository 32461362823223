/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Box,
    TextField,
    InputAdornment,
    Grid,
    Button,
} from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';
import bffService from '../../../../services/bff';

import useStyles from './styles';

import Loading from '../../../../components/visuals/loading';
import { Snackbar } from '../../../../components';

const USER_PROFILE_BATTERY_CAPICITY = 60;
const USER_PROFILE_MAX_CHARGING_POWER_KW = 22.2;
const QUARTER_PRICE_FOR_SELF_CAPACITY = 10;
const QUARTER_PRICE_FOR_EXTERNAL_ENERGY = 20;
const QUARTER_MARGIN_IN_PERCENTAGE = 10;

const Controls = ({
    chargingStations,
    onChargePlansLoaded,
    removeChargingPlans,
}) => {
    const classes = useStyles();
    const intl = useIntl();
    const [chargingStation, setChargingStation] = useState('');
    const [currentChargeLevel, setCurrentChargeLevel] = useState(-1);
    const [desiredChargeLevel, setDesiredChargeLevel] = useState(-1);
    const [isDisabledRequestButton, setIsDisabledRequestButton] = useState(
        true,
    );
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const onChangeChargingStation = event => {
        setChargingStation(event.target.value);
    };
    const onCurrentChargeLevelChanged = event => {
        setCurrentChargeLevel(parseInt(event.target.value, 10));
    };
    const onDesiredChargeLevelChanged = event => {
        setDesiredChargeLevel(parseInt(event.target.value, 10));
    };

    useEffect(() => {
        validateRequestButton();
    }, [chargingStation, currentChargeLevel, desiredChargeLevel]);

    const validateRequestButton = () => {
        if (
            chargingStation &&
            currentChargeLevel >= 0 &&
            desiredChargeLevel > currentChargeLevel
        ) {
            setIsDisabledRequestButton(false);
        } else {
            setIsDisabledRequestButton(true);
        }
    };

    const onLoadChargingPlans = async () => {
        removeChargingPlans();
        setLoading(true);
        setError(false);

        const {
            error: serviceError,
            response: chargingPlans,
        } = await fetchChargingPlans({
            currentChargeInPercent: currentChargeLevel,
            requestedChargeInPercent: desiredChargeLevel,
            assetIdChargingPoint: chargingStation,
        });

        if (serviceError) {
            setLoading(false);
            setError(true);
        } else {
            setLoading(false);
            onChargePlansLoaded(
                chargingPlans,
                chargingStation,
                currentChargeLevel,
                desiredChargeLevel,
            );
        }
    };

    const fetchChargingPlans = async ({
        currentChargeInPercent,
        requestedChargeInPercent,
        assetIdChargingPoint,
    }) => {
        const params = buildUrl({
            currentChargeInPercent,
            requestedChargeInPercent,
            assetIdChargingPoint,
            batteryCapacityKWh: USER_PROFILE_BATTERY_CAPICITY,
            batteryMaxChargingPowerKW: USER_PROFILE_MAX_CHARGING_POWER_KW,
            priceForSelfCapacity: QUARTER_PRICE_FOR_SELF_CAPACITY,
            priceForExternalEnergy: QUARTER_PRICE_FOR_EXTERNAL_ENERGY,
            marginInPercentage: QUARTER_MARGIN_IN_PERCENTAGE,
        });

        return bffService.getChargingPlans(params);
    };

    function buildUrl(parameters) {
        let queryString = '';
        // eslint-disable-next-line guard-for-in
        for (const key in parameters) {
            const value = parameters[key];
            queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
                value,
            )}&`;
        }
        queryString = queryString.substring(0, queryString.length - 1);
        return queryString;
    }

    function handleClose() {
        setError(false);
    }

    return (
        <Box className={classes.formControlContainer}>
            <FormControl className={classes.formControl}>
                <InputLabel id="select-charging-station">
                    <FormattedMessage id="ueApp.mainPage.select.chargingStation" />
                </InputLabel>
                <Select
                    labelId="select-charging-station"
                    value={chargingStation}
                    onChange={onChangeChargingStation}
                >
                    {chargingStations.map(station => (
                        <MenuItem key={station.id} value={station.id}>
                            {station.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
                <TextField
                    id="current-charge-level"
                    label={intl.formatMessage({
                        id: 'ueApp.mainPage.currentChargeLevel',
                    })}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                    className={classes.textField}
                    helperText=""
                    onChange={onCurrentChargeLevelChanged}
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField
                    id="desired-charge-level"
                    label={intl.formatMessage({
                        id: 'ueApp.mainPage.desiredChargeLevel',
                    })}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                    className={classes.textField}
                    helperText=""
                    onChange={onDesiredChargeLevelChanged}
                />
            </FormControl>
            <div className={classes.requestButton}>
                <Button
                    onClick={onLoadChargingPlans}
                    disabled={isDisabledRequestButton}
                    color="primary"
                    variant="contained"
                >
                    {intl.formatMessage({
                        id: 'ueApp.mainPage.button.fetchChargingPlans',
                    })}
                </Button>
            </div>
            {loading && (
                <Grid container justify="center" component="div">
                    <Grid item>
                        <Loading />
                    </Grid>
                </Grid>
            )}
            {error && (
                <Snackbar
                    open={error}
                    onClose={handleClose}
                    variant="error"
                    messageId={'customer.snackbar.errorLoadChargingPlans'}
                />
            )}
        </Box>
    );
};

Controls.propTypes = {
    chargingStations: propTypes.array,
    chargingOptions: propTypes.array,
    onChargePlansLoaded: propTypes.func,
    removeChargingPlans: propTypes.func,
};

export default Controls;
